import { Card } from 'components/cards/basic';
import { Button, ButtonVariants } from 'components/button';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'services/analytics-service';

interface CardExclusiveProps {
  /**
   * Exclusive card title
   */
  title: string;
  /**
   * Exclusive card title color
   */
  titleColor: string;
  /**
   * image url for background image on desktop
   */
  backgroundImageDesktop: string;
  /**
   * image url for background image on mobile
   */
  backgroundImageMobile: string;
  /**
   * button link to session type page
   */
  buttonLink: string;
  /**
   * button text - optional
   */
  buttonText: string;
  /**
   * Button aria-label
   */
  buttonLabel: string;
  /**
   * Classes list
   */
  className?: string;
  /**
   * Name of episode
   */
  episodeName?: string;
}

/**
 * Primary UI component for user interaction
 */
export const CardExclusive = ({
  title,
  titleColor,
  backgroundImageDesktop,
  backgroundImageMobile,
  buttonLink,
  buttonText,
  buttonLabel,
  className,
  episodeName,
}: CardExclusiveProps) => {
  const { t } = useTranslation();

  const downloadPDF = () => {
    trackEvent({
      eventName: 'Playbook viewed',
      eventProperties: {
        playbook: title,
        episode: episodeName,
      },
    });

    window.open(buttonLink, '_blank');
  };

  return (
    <Card
      className={`${className} no-action exclusive-card p-0 rounded-4 border-none mobile:h-[90vh] mobile:rounded-none`}
    >
      <div className="relative h-full w-full">
        <div
          className={`tablet-and-up:flex hidden bg-img absolute z-0 left-0 top-0 h-full w-full bg-center bg-cover bg-no-repeat `}
          style={{ backgroundImage: `url(${backgroundImageDesktop})` }}
          data-testid="bg"
        ></div>
        <div
          className={`tablet-and-up:hidden flex bg-img absolute z-0 left-0 top-0 h-full w-full bg-center bg-cover bg-no-repeat `}
          style={{ backgroundImage: `url(${backgroundImageMobile})` }}
          data-testid="bg-mobile"
        ></div>
        <div className="relative z-1 box-border h-full w-full px-[80px] py-48 mobile:px-[26px] mobile:py-48 flex items-end">
          <div>
            <p
              className={`text-${titleColor} body-xxs leading-none tracking-[0.02rem] mb-[12px] mobile:mb-[32px]`}
              data-testid="exclusiveContent"
            >
              {t('videoPage.exclusive.exclusiveContent')}
            </p>
            <h1
              className={`text-${titleColor} font-normal heading-xl tracking-[0.01rem] w-[45%] mobile:w-full`}
              data-testid="title"
            >
              {t(title)}
            </h1>
            <Button
              variant={ButtonVariants.Primary}
              label={t(buttonLabel)}
              text={t(buttonText)}
              className="cursor-pointer mt-[52px] mobile:mt-[32px]"
              onClick={downloadPDF}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
