import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getLoginContentUrl, getPostAssessmentOverviewUrl } from 'services/service-urls';

export interface ColorGradient {
  key: string;
  light: string;
  dark: string;
}

export interface VoiceOfACoach {
  key: string;
  type: string;
  title: string;
  description: string;
}

export interface Cadence {
  key: string;
  description: string;
}

export interface CueContent {
  key: string;
  title: string;
  description: string;
  button_call_to_action: string;
}

export interface PostAssessmentOverviewContent {
  title: string;
  description: string;
  overall_score: number;
  voice_of_a_coach: VoiceOfACoach;
  color_gradient: ColorGradient;
}

export interface BreakdownIntroductionContent {
  title: string;
  description: string;
}

export interface PostAssessmentIntroductionContent {
  post_assessment_overview: PostAssessmentOverviewContent;
  breakdown_introduction: BreakdownIntroductionContent;
}

export interface AlluvialContent {
  key: string;
  title: string;
  description: string;
  loader_message: string;
  voice_of_a_coach: VoiceOfACoach;
  color_gradient: ColorGradient;
  hint: string;
}

export const getPostAssessmentIntroductionContent = async (
  themeKey: string,
  baseUrl: string = baseUrlConfig
): Promise<PostAssessmentIntroductionContent> => {
  const res = await axios.get(getPostAssessmentOverviewUrl(themeKey, baseUrl), getDefaultAxiosConfig());
  const overviewData = res.data['post_assessment_overview'];
  const breakdownData = res.data['breakdown_introduction'];

  const voiceOfACoach = {
    key: overviewData['voice_of_a_coach'].key,
    type: overviewData['voice_of_a_coach'].type,
    title: overviewData['voice_of_a_coach'].title,
    description: overviewData['voice_of_a_coach'].description,
  };

  const colorGradient = {
    key: overviewData['color_gradient'].key,
    light: overviewData['color_gradient'].light,
    dark: overviewData['color_gradient'].dark,
  };

  const overviewContent = {
    title: overviewData.title,
    description: overviewData.description,
    overall_score: overviewData.overall_score,
    voice_of_a_coach: voiceOfACoach,
    color_gradient: colorGradient,
  };

  const breakdownContent = {
    title: breakdownData.title,
    description: breakdownData.description,
  };

  return {
    post_assessment_overview: overviewContent,
    breakdown_introduction: breakdownContent,
  };
};

export interface LoginContent {
  welcome: string;
  loginComplete: string;
  agreeToTerms: string;
  ctaButtonLogin: string;
  ctaButtonNewUser: string;
  betterupLogo: string;
  microsoftLogo: string;
  invalidLinkMessage: string;
}

export const getLoginContent = async (baseUrl: string = baseUrlConfig): Promise<LoginContent> => {
  const res = await axios.get(getLoginContentUrl(baseUrl), getDefaultAxiosConfig());
  const content = res.data['content/login'];
  return {
    welcome: content.welcome,
    loginComplete: content.login_complete,
    agreeToTerms: content.agree_to_terms,
    ctaButtonLogin: content.cta_button_login,
    ctaButtonNewUser: content.cta_button_new_user,
    betterupLogo: content.betterup_logo_url,
    microsoftLogo: content.microsoft_logo_url,
    invalidLinkMessage: content.invalid_link_message,
  };
};
