import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import {
  baseUrlConfig,
  getCurrentUserUrl,
  getDeleteCurrentUserUrl,
  getUserByTokenUrl,
  getUpdateCurrentUserFlagsUrl,
} from 'services/service-urls';
import { Experiments } from 'services/ab-service';

export interface User {
  first_name: string;
  experiments: Experiments;
  assessment_status: string;
  assessment_statuses: Record<string, string>;
  id?: number;
  last_name?: string;
  token?: string;
  welcome_flow_complete: boolean;
}

export const getCurrentUser = async (baseUrl: string = baseUrlConfig): Promise<User> => {
  const res = await axios.get(getCurrentUserUrl(baseUrl), getDefaultAxiosConfig());
  return res.data.user;
};

export const getUserByToken = async (
  token: string,
  consented: boolean,
  baseUrl: string = baseUrlConfig
): Promise<User> => {
  const res = await axios.post(getUserByTokenUrl(baseUrl), { token, consented }, getDefaultAxiosConfig());
  return res.data.user;
};

export const deleteCurrentUser = async (baseUrl: string = baseUrlConfig) => {
  const res = await axios.delete(getDeleteCurrentUserUrl(baseUrl), getDefaultAxiosConfig());
  return res.data;
};

interface UpdateUserParams {
  welcome_flow_complete: boolean;
}

export const updateUserFlags = async (params: UpdateUserParams, baseUrl: string = baseUrlConfig) => {
  const res = await axios.patch(getUpdateCurrentUserFlagsUrl(baseUrl), params, getDefaultAxiosConfig());
  return res.data.user;
};
