import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import {
  baseUrlConfig,
  getAddToTeamsCalendarUrl,
  getCreateUserPracticeUrl,
  getUserPracticesUrl,
} from 'services/service-urls';
import { PracticeExerciseType } from 'utils/post-assessment-types';

export interface UserPractice {
  id: number;
  practice_key: string;
  routine_id: number;
  time: string;
  cadence: string;
}

interface CreateUserPracticeParams {
  assessment_id: number;
  practice_key: string;
  routine_id: number;
  time: string;
  cadence: string;
}

export const createUserPractice = async (
  params: CreateUserPracticeParams,
  baseUrl: string = baseUrlConfig
): Promise<UserPractice> => {
  const res = await axios.post(getCreateUserPracticeUrl(baseUrl), params, getDefaultAxiosConfig());
  return res.data.user_practice;
};

export const getUserPractices = async (
  themeKey: string,
  baseUrl: string = baseUrlConfig
): Promise<PracticeExerciseType[]> => {
  const res = await axios.get(getUserPracticesUrl(themeKey, baseUrl), getDefaultAxiosConfig());
  return res.data.practices;
};

interface AddToTeamsCalendarParams {
  user_practice_id: number;
}
export interface AddToTeamsCalendarResponse {
  code: number;
  weblink: string;
  teams_calendar_id: string;
}
export const addToTeamsCalendar = async (
  params: AddToTeamsCalendarParams,
  baseUrl: string = baseUrlConfig
): Promise<AddToTeamsCalendarResponse> => {
  const res = await axios.post(getAddToTeamsCalendarUrl(baseUrl), params, getDefaultAxiosConfig());
  return res.data.calendar_event;
};
