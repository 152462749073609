import format from 'date-fns/format';
import { formatInTimeZone } from 'date-fns-tz';

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const convertLocalTimeToUTC = (time: string): string => {
  // ensure time is in format "DD:DD" ("9:45" becomes "09:45")
  const paddedTime = time.padStart(5, '0');

  // use today's date to make sure we account for daylight time or standard time
  const formattedDate = format(new Date(), `yyyy-MM-dd ${paddedTime}:00`);

  // extract just the HH:mm from the UTC-formatted date
  const formattedTime = formatInTimeZone(formattedDate, 'Etc/UTC', 'HH:mm');

  return formattedTime;
};
