import { useEffect, useRef, useState } from 'react';
import { convertHexToRGBA } from 'utils/helpers';
import PostAssessmentGlows from '../glows';

interface BreakdownElementRingsProps {
  colorsLight: string[];
  colorsDark: string[];
  currentIndex: number;
}
interface AnimationObject {
  translate: string;
  rotate: string;
  scale: string;
  ringGlowsOpacity: number;
  duration: number;
  ease: string;
}
export default function BreakdownElementRings({ colorsLight, colorsDark, currentIndex }: BreakdownElementRingsProps) {
  const hasMounted = useRef(false);
  const [animation, setAnimation] = useState<AnimationObject>({
    translate: 'translate(40%, 15%)',
    rotate: '',
    scale: '.8',
    ringGlowsOpacity: 0,
    duration: 3200,
    ease: 'ease-in',
  });

  useEffect(() => {
    // there are 6 steps in the breakdown flow.  at each step, this component animates
    const transitions = [
      {
        translate: 'translate(40%, 15%)',
        rotate: '',
        scale: '.8',
        ringGlowsOpacity: 0.5,
        duration: !hasMounted.current ? 3200 : 500,
        ease: 'ease-out',
      },
      {
        translate: 'translate(50%, 4%)',
        rotate: 'rotate(-180deg)',
        scale: '1',
        ringGlowsOpacity: 0,
        duration: 500,
        ease: 'ease-in',
      },
      {
        translate: 'translate(50%, 4%)',
        rotate: 'rotate(-270deg)',
        scale: '1',
        ringGlowsOpacity: 0,
        duration: 500,
        ease: 'ease-in',
      },
      {
        translate: 'translate(50%, 4%)',
        rotate: 'rotate(-360deg)',
        scale: '1',
        ringGlowsOpacity: 0,
        duration: 500,
        ease: 'ease-in',
      },
      {
        translate: 'translate(50%, 4%)',
        rotate: 'rotate(-450deg)',
        scale: '1',
        ringGlowsOpacity: 0,
        duration: 500,
        ease: 'ease-in',
      },
      {
        translate: 'translate(35%, 0%)',
        rotate: 'rotate(-270deg)',
        scale: '.7',
        ringGlowsOpacity: 0,
        duration: 500,
        ease: 'ease-in',
      },
    ];

    setAnimation(transitions[currentIndex]);
    // i do not want to re-render based on hasMounted, that is why it is not in the dependencies
  }, [currentIndex]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  const { duration, ease, translate, rotate, scale, ringGlowsOpacity } = animation;

  const ringDiameter = 160;

  return (
    <div
      className="rings absolute top-0 left-0 w-full h-full"
      style={{
        zIndex: 0,
        transition: `transform ${duration}ms ${ease}`,
        transform: `${translate} scale(${scale})`,
      }}
    >
      <div
        className="rings-glow relative flex flex-row flex-wrap w-400"
        style={{
          transition: `opacity ${duration}ms ${ease}`,
          opacity: `${ringGlowsOpacity}`,
        }}
      >
        <PostAssessmentGlows colors={colorsLight} />
      </div>
      <div
        className="rings"
        style={{
          transition: `transform ${duration}ms ${ease}`,
          transform: `${rotate}`,
          transformOrigin: 'left',
        }}
      >
        <div
          className={`absolute rounded-full ${currentIndex === 1 ? 'z-1' : 'z-0'}`}
          style={{
            width: `${ringDiameter}px`,
            height: `${ringDiameter}px`,
            left: `-${ringDiameter / 2}px`,
            top: `-${ringDiameter}px`,
            border: `${currentIndex === 1 ? '0' : '2px'} solid ${convertHexToRGBA('#FFF', currentIndex > 0 ? 0.3 : 1)}`,
          }}
        >
          <div
            className="w-full h-full rounded-full"
            style={{
              transform: 'rotate(180deg)',
              background: `radial-gradient(103.93% 103.93% at 50% 92.86%, ${colorsLight[0]} 70.83%, ${colorsDark[0]} 100%)`,
              transition: `opacity ${duration}ms ${ease}`,
              opacity: `${currentIndex === 1 ? '1' : '0'}`,
              boxShadow: `0px -38px 54px 60px rgba(255, 255, 255, 0.05), 0px 40px 130px 140px ${convertHexToRGBA(
                colorsLight[0],
                0.3
              )}`,
            }}
          ></div>
        </div>
        <div
          className={`absolute left-0 rounded-full ${currentIndex === 2 ? 'z-1' : 'z-0'}`}
          style={{
            width: `${ringDiameter}px`,
            height: `${ringDiameter}px`,
            left: 0,
            top: `-${ringDiameter / 2}px`,
            border: `${currentIndex === 2 ? '0' : '2px'} solid ${convertHexToRGBA(
              '#FFF',
              currentIndex === 5 || currentIndex === 0 ? 1 : 0.3
            )}`,
          }}
        >
          <div
            className="w-full h-full rounded-full"
            style={{
              transform: 'rotate(-90deg)',
              background: `radial-gradient(103.93% 103.93% at 50% 92.86%, ${colorsLight[1]} 70.83%, ${colorsDark[1]} 100%)`,
              transition: `opacity ${duration}ms ${ease}`,
              opacity: `${currentIndex === 2 ? '1' : '0'}`,
              boxShadow: `0px -38px 54px 60px rgba(255, 255, 255, 0.05), 0px 40px 130px 140px ${convertHexToRGBA(
                colorsLight[1],
                0.3
              )}`,
            }}
          ></div>
        </div>
        <div
          className={`absolute top-0 rounded-full ${currentIndex === 3 ? 'z-1' : 'z-0'}`}
          style={{
            width: `${ringDiameter}px`,
            height: `${ringDiameter}px`,
            left: `-${ringDiameter / 2}px`,
            border: `${currentIndex === 3 ? '0' : '2px'} solid ${convertHexToRGBA('#FFF', currentIndex > 0 ? 0.3 : 1)}`,
          }}
        >
          <div
            className="w-full h-full rounded-full"
            style={{
              background: `radial-gradient(103.93% 103.93% at 50% 92.86%, ${colorsLight[2]} 70.83%, ${colorsDark[2]} 100%)`,
              transition: `opacity ${duration}ms ${ease}`,
              opacity: `${currentIndex === 3 ? '1' : '0'}`,
              boxShadow: `0px -38px 54px 60px rgba(255, 255, 255, 0.05), 0px 40px 130px 140px ${convertHexToRGBA(
                colorsLight[2],
                0.3
              )}`,
            }}
          ></div>
        </div>
        <div
          className={`absolute rounded-full ${currentIndex === 4 ? 'z-1' : 'z-0'}`}
          style={{
            width: `${ringDiameter}px`,
            height: `${ringDiameter}px`,
            left: `-${ringDiameter}px`,
            top: `-${ringDiameter / 2}px`,
            border: `${currentIndex === 4 ? '0' : '2px'} solid ${convertHexToRGBA('#FFF', currentIndex > 0 ? 0.3 : 1)}`,
          }}
        >
          <div
            className={`rounded-full`}
            style={{
              width: `${ringDiameter}px`,
              height: `${ringDiameter}px`,
              transform: 'rotate(90deg)',
              background: `radial-gradient(103.93% 103.93% at 50% 92.86%, ${colorsLight[3]} 70.83%, ${colorsDark[3]} 100%)`,
              transition: `opacity ${duration}ms ${ease}`,
              opacity: `${currentIndex === 4 ? '1' : '0'}`,
              boxShadow: `0px -38px 54px 60px rgba(255, 255, 255, 0.05), 0px 40px 130px 140px ${convertHexToRGBA(
                colorsLight[3],
                0.3
              )}`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
