import * as Sentry from '@sentry/react';
import { UserContext } from 'contexts/user';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getThemes, Theme } from 'services/themes-service';

interface VideoStatesType {
  practices: {
    isPause: boolean;
    isPlay: boolean;
  };
  hero: {
    isPause: boolean;
    isPlay: boolean;
  };
}

export interface ThemeContextProps {
  loading: boolean;
  themes: Theme[] | undefined;
  isVideoPlaying: boolean;
  setIsVideoPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  videoStates: VideoStatesType;
  setVideoStates: React.Dispatch<React.SetStateAction<VideoStatesType>>;
  currentTheme: Theme | undefined;
  changeCurrentTheme: (newTheme: Theme) => void;
  currentEpisode: number;
  setCurrentEpisode: React.Dispatch<React.SetStateAction<number>>;
}

export const ThemeContext = React.createContext<ThemeContextProps>({} as ThemeContextProps);

const videoPausedDefault = {
  practices: {
    isPause: false,
    isPlay: false,
  },
  hero: {
    isPause: false,
    isPlay: false,
  },
};

export function ThemeProvider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const { reloadUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentEpisode, setCurrentEpisode] = useState<number>(0);
  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const [themes, setThemes] = useState<Theme[]>();
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [videoStates, setVideoStates] = useState<VideoStatesType>(videoPausedDefault);

  const contentLoaded = useRef(false);

  const changeCurrentTheme = useCallback(
    (newTheme: Theme) => {
      setCurrentTheme(newTheme);
      setCurrentEpisode(0);
      reloadUser();
    },
    [reloadUser]
  );

  const loadThemes = useCallback(async () => {
    try {
      const themes = await getThemes();
      setThemes(themes);
      setCurrentTheme(themes[0]);
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await loadThemes();
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    if (!contentLoaded.current) {
      contentLoaded.current = true;
      load();
    }
  }, [loadThemes]);

  const contextValue = useMemo(
    () => ({
      loading,
      themes,
      isVideoPlaying,
      setIsVideoPlaying,
      videoStates,
      setVideoStates,
      currentTheme,
      changeCurrentTheme,
      currentEpisode,
      setCurrentEpisode,
    }),
    [loading, themes, isVideoPlaying, videoStates, currentTheme, changeCurrentTheme, currentEpisode]
  );

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
}
