import Vimeo, { EndEvent, PauseEvent, PlayEvent } from '@u-wave/react-vimeo';
import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { PracticeVideoType } from 'utils/post-assessment-types';
import { useContext } from 'react';
import { trackEvent } from 'services/analytics-service';
import { AssessmentInsightsProps } from 'utils/assessment';
import PostAssessmentGlows from 'components/sections/post-assessment/glows';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { ThemeContext } from 'contexts/theme';

interface VideoplayerProps {
  /**
   * when the video is done, this event is triggered to tell the parent component to transition to the next experience
   */
  onComplete?: () => void;
  /**
   * data for the video
   */
  video: PracticeVideoType;
  /**
   * callback function when clicking the close button
   */
  closeVideo: () => void;
  /**
   * name of the event to use for tracking
   */
  eventIdentifier: string;
  /**
   * map of ancillary data to be tracked
   */
  analyticsData: object;
  /**
   * @insights AssessmentInsightsProps object containing the alluvials and other insight data
   */
  insights: AssessmentInsightsProps | undefined;
  /**
   * string language of captions to enable by default on video player
   */
  textTrackLanguage?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Videoplayer = ({
  onComplete,
  video,
  closeVideo,
  eventIdentifier,
  analyticsData,
  insights,
  textTrackLanguage,
}: VideoplayerProps) => {
  const { t } = useTranslation();
  const { setIsVideoPlaying, videoStates, setVideoStates } = useContext(ThemeContext);
  const videoTrackingData = {
    ...analyticsData,
    videoKey: video.key,
    videoTitle: video.title,
    videoUrl: video.url,
    videoDuration: video.duration,
  };

  const onPlay = (e: PlayEvent) => {
    setVideoStates({
      practices: {
        isPlay: true,
        isPause: false,
      },
      hero: {
        isPlay: false,
        isPause: true,
      },
    });

    trackEvent({
      eventName: eventIdentifier + ' Started',
      eventProperties: {
        ...videoTrackingData,
        timestamp: e.seconds,
      },
    });
  };

  const onPause = (e: PauseEvent) => {
    setVideoStates({
      practices: {
        isPlay: false,
        isPause: true,
      },
      hero: {
        isPlay: false,
        isPause: true,
      },
    });

    // PauseEvent and EndEvent are triggered at the end of the video
    // Don't track a PauseEvent at the end.
    const isAtEndOfVideo = Math.floor(e.seconds) === Math.floor(e.duration);
    if (!isAtEndOfVideo) {
      trackEvent({
        eventName: eventIdentifier + ' Paused',
        eventProperties: {
          ...videoTrackingData,
          timestamp: e.seconds,
        },
      });
    }
  };

  const onEnd = (e: EndEvent) => {
    setIsVideoPlaying(false);

    trackEvent({
      eventName: eventIdentifier + ' Completed',
      eventProperties: {
        ...videoTrackingData,
        timestamp: e.seconds,
      },
    });

    onComplete && onComplete();
    closeVideo();
  };

  const onError = (err: Error) => {
    Sentry.captureException(err);
  };

  const onClickNext = () => {
    setIsVideoPlaying(false);

    onComplete && onComplete();
  };

  const onCloseVideo = () => {
    setIsVideoPlaying(false);

    trackEvent({
      eventName: eventIdentifier + ' Closed',
      eventProperties: videoTrackingData,
    });

    closeVideo();
  };

  return (
    <div className="absolute z-100 w-full h-full top-0 left-0 flex flex-row flex-col justify-center items-center content-center desktop:top-0 desktop:transform-none">
      <div className="mt-12 tablet-and-up:mt-0 tablet-and-up:absolute z-100 flex flex-row justify-between w-11/12 tablet-and-up:w-3/4 desktop-large:w-1/2 max-w-720">
        <button
          className={`flex justify-center items-center rounded-full w-48 h-48 bg-white/65 hover:bg-white/90 active:bg-white`}
          onClick={onCloseVideo}
          aria-label={`${t('assessment.postAssessment.navigation.backToYourPractices')}`}
        >
          <SvgIcon icon={Icons.CaretLeft} size={30} />
        </button>
        <button
          className={`flex justify-center items-center rounded-full w-48 h-48 bg-white/65 hover:bg-white/90 active:bg-white`}
          onClick={onComplete ? onClickNext : onCloseVideo}
          aria-label={`${t('assessment.postAssessment.navigation.toScheduleYourPractice')}`}
        >
          <SvgIcon icon={Icons.CaretRight} size={30} />
        </button>
      </div>
      <div className="mt-12 tablet-and-up:mt-0 absolute z-1 rounded-36 overflow-hidden scale-50 tablet-and-up:scale-75 desktop:w-[500px] desktop:h-880">
        <Vimeo
          id="practice-video"
          data-testid="practice-video"
          video={video.url}
          showPortrait={true}
          width={500}
          height={880}
          showTitle={false}
          autoplay={true}
          controls={true}
          onEnd={onEnd}
          onError={onError}
          onPlay={onPlay}
          onPause={onPause}
          paused={videoStates.practices.isPause}
          textTrack={textTrackLanguage}
        />
      </div>
      <div
        className="rings-glow relative top-0"
        style={{
          transform: `scale(1.5)`,
        }}
      >
        {insights && (
          <PostAssessmentGlows
            colors={[
              insights.dimensions[0].content.color_gradient.light,
              insights.dimensions[1].content.color_gradient.light,
              insights.dimensions[2].content.color_gradient.light,
              insights.dimensions[3].content.color_gradient.light,
            ]}
          />
        )}
      </div>
    </div>
  );
};
