import * as Sentry from '@sentry/react';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getWelcomeContent, WelcomeContent } from 'services/welcome-service';

export interface WelcomeContentContextProps {
  loading: boolean;
  welcomeContent: WelcomeContent | undefined;
}

export const WelcomeContentContext = React.createContext<WelcomeContentContextProps>({} as WelcomeContentContextProps);

export function WelcomeContentProvider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [welcomeContent, setWelcomeContent] = useState<WelcomeContent>();

  const contentLoaded = useRef(false);

  const loadWelcomeContent = useCallback(async () => {
    try {
      const welcome = await getWelcomeContent();
      setWelcomeContent(welcome);
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await loadWelcomeContent();
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    if (!contentLoaded.current) {
      contentLoaded.current = true;
      load();
    }
  }, [loadWelcomeContent]);

  const contextValue = useMemo(
    () => ({
      loading,
      welcomeContent,
    }),
    [loading, welcomeContent]
  );

  return <WelcomeContentContext.Provider value={contextValue}>{children}</WelcomeContentContext.Provider>;
}
