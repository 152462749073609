import * as Sentry from '@sentry/react';
import PostAssessmentCue from 'components/sections/post-assessment/cue';
import { JourneyContext } from 'contexts/journey';
import { useContext, useEffect, useState } from 'react';
import { RoutineCategory, getRoutines } from 'services/routines-service';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';

export default function PostAssessmentContainerCue() {
  const { navigate } = useCustomNavigation();
  const { insights, practiceExercise } = useContext(JourneyContext);
  const [routines, setRoutines] = useState<RoutineCategory[]>();

  useEffect(() => {
    getRoutines()
      .then(setRoutines)
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  return (
    <>
      {insights && routines && practiceExercise ? (
        <PostAssessmentCue
          routines={routines}
          insights={insights}
          practice={practiceExercise}
          onComplete={() => navigate('/practices/complete')}
        />
      ) : null}
    </>
  );
}
