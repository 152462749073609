import useElementSize from 'utils/hooks/useElementSize';

export enum TextPositions {
  left = 'text-left',
  center = 'text-center',
  right = 'text-right',
}

interface ScaleItemProps {
  /**
   * Scale item inner text
   */
  scaleItemText?: string;
  /**
   * Scale item text
   */
  text?: string;
  /**
   * Scale item text position
   */
  textPosition?: TextPositions;
  /**
   * Button label text
   */
  label?: string;
  /**
   * Inverted item text
   */
  inverted?: boolean;
  /**
   * Classes list
   */
  className?: string;
  /**
   * If the item is active, or not
   */
  active?: boolean;
  /**
   * Optional click handler
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const ScaleItem = ({
  scaleItemText,
  text,
  textPosition,
  label = '',
  inverted = false,
  className = '',
  active,
  onClick,
}: ScaleItemProps) => {
  const [ref, { isMobile }] = useElementSize();

  const headingStyle = inverted && !active ? 'text-inverted' : 'text-heading';
  const bodyStyle = inverted || !active ? 'text-inverted' : 'text-body';
  const tileStyle = inverted
    ? `${active ? 'bg-white' : 'bg-tile-inverted'} border-tile-inverted ${
        isMobile ? '' : 'hover:border-tile-inverted-hover'
      } focus-visible:shadow-button-focus-inverted active:bg-white`
    : `bg-tile border-tile ${isMobile ? '' : 'hover:border-tile-hover'} focus-visible:shadow-button-focus`;
  return (
    <div
      className={`flex flex-col items-center mx-18 mobile:mx-10 w-40 ${bodyStyle} ${className}`}
      data-testid="scaleItem"
      ref={ref}
    >
      <button
        className={`mb-16 rounded-10 w-56 h-56 mobile:w-48 mobile:h-48 border-1 ${tileStyle} hover:cursor-pointer`}
        type="button"
        aria-label={label}
        onClick={onClick}
      >
        {scaleItemText && <span className={`body-s ${headingStyle}`}>{scaleItemText}</span>}
      </button>
      {text && (
        <div className={`${textPosition === TextPositions.center ? 'w-100' : 'w-56'} mobile:w-48 h-56 ${textPosition}`}>
          <p className={`body-xxs ${bodyStyle}`}>{text}</p>
        </div>
      )}
    </div>
  );
};
