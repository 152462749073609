import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { PostAssessmentLoading } from 'components/sections/post-assessment/loading';
import PostAssessmentOverview from 'components/sections/post-assessment/overview';
import { PostAssessmentOverviewContent, getPostAssessmentIntroductionContent } from 'services/content-service';
import { UserContext } from 'contexts/user';
import { ThemeContext } from 'contexts/theme';

export default function PostAssessmentContainerOverview() {
  const { t } = useTranslation();

  const [postAssessmentOverviewContent, setPostAssessmentOverviewContent] = useState<PostAssessmentOverviewContent>();
  const [loaderTimedOut, setLoaderTimedOut] = useState<boolean>(false);

  const { user } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);

  const loaderDuration = currentTheme && user?.assessment_statuses[currentTheme.key] === 'completed' ? 3000 : 0;

  useEffect(() => {
    if (currentTheme) {
      getPostAssessmentIntroductionContent(currentTheme.key)
        .then((res) => {
          setPostAssessmentOverviewContent(res.post_assessment_overview);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, [currentTheme]);

  return (
    <div className="w-full h-full min-h-[inherit] bg-black" data-testid="teams-app-overview">
      {postAssessmentOverviewContent && loaderTimedOut ? (
        <PostAssessmentOverview inverted content={postAssessmentOverviewContent} />
      ) : (
        <PostAssessmentLoading
          inverted
          description={t('assessment.postAssessment.loader.afterAssessment')}
          duration={loaderDuration}
          onTimeout={() => setLoaderTimedOut(true)}
        />
      )}
    </div>
  );
}
