import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'contexts/theme';
import { EpisodeType } from 'services/themes-service';
import gradientBg from 'assets/images/gradient-home.jpg';
import { navigateToTab } from 'utils/teams';
import { Button } from 'components/button';
import { trackEvent } from 'services/analytics-service';
import { UserContext } from 'contexts/user';

function TeamsHomeContainer(): JSX.Element {
  const { t } = useTranslation();
  const { themes } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [orderedEpisodes, setOrderedEpisodes] = useState<EpisodeType[]>();
  const [overEpisode, setOverEpisode] = useState<EpisodeType>();
  const [firstEpisode, setFirstEpisode] = useState<EpisodeType>();

  const trackTabView = (episodes: EpisodeType[]) => {
    trackEvent({
      eventName: 'Tab Viewed',
      eventProperties: {
        tab_name: 'home',
        cards: episodes.map((episode) => episode.name),
      },
    });
  };

  useEffect(() => {
    if (!themes) return;

    const episodes = themes.flatMap((theme) => theme.episodes);
    const sortedEpisodes = episodes.sort((a, b) => {
      const publishDateA = +new Date(a.publish_date);
      const publishDateB = +new Date(b.publish_date);
      return publishDateB - publishDateA;
    });

    const [firstEpisode, ...remainingEpisodes] = sortedEpisodes;
    setFirstEpisode(firstEpisode);
    setOrderedEpisodes(remainingEpisodes);
    trackTabView(sortedEpisodes);
  }, [themes]);

  const episodeSelect = (episode: EpisodeType) => {
    navigateToTab('journeys', episode.name);
  };

  const episodeOver = (episode: EpisodeType | undefined) => {
    setOverEpisode(episode);
  };

  const episodeCard = (episode: EpisodeType, index: number) => {
    const assessmentStatus = user?.assessment_statuses[episode.theme_title.toLowerCase()];
    const buttonLabel =
      assessmentStatus === 'unstarted' ? 'homePage.episodeButtonUnstarted' : 'homePage.episodeButtonStarted';

    return (
      <div
        key={`episode-${index}`}
        className="rounded overflow-hidden cursor-pointer mx-8 grow mobile:w-full mobile:mb-24 h-[512px] relative border-transparent border-4 hover:border-white"
        onClick={() => episodeSelect(episode)}
        onMouseOver={() => episodeOver(episode)}
        onMouseOut={() => episodeOver(undefined)}
      >
        <div className="absolute z-100 w-full text-center bottom-12">
          <h2 className="card-heading text-white">{episode.theme_title}</h2>
          <h2 className="card-heading font-normal text-white mb-28">{episode.name}</h2>
          <Button
            label={`${t(buttonLabel)}`}
            text={t(buttonLabel)}
            onClick={() => episodeSelect(episode)}
            className="mb-24 !py-18 !px-36 hover:cursor-pointer"
          />
        </div>
        <div
          className={`flex bg-img h-[512px] min-w-full bg-center bg-cover bg-no-repeat transition-transform ${
            overEpisode === episode ? 'scale-105' : 'scale-100'
          }`}
          style={{ backgroundImage: `url(${episode.video.card_image_url})` }}
          data-testid="episode-button-bg"
        ></div>
      </div>
    );
  };

  return (
    <div className="bg-black" data-testid="teams-home-container">
      {orderedEpisodes && (
        <main
          className="z-1 flex flex-col justify-start items-center relative w-full" // min-h-896 justify-center
          data-testid="teams-app-home"
        >
          <h1 className="heading-xxl text-center font-soehne-breit font-semibold text-white mb-36 mt-48 mobile:mt-36">
            {t('landingPage.heading')}
          </h1>
          <div className="flex flex-row w-11/12 mobile:flex-col justify-center items-center px-36 mb-[38px]">
            {firstEpisode && episodeCard(firstEpisode, 0)}
          </div>

          <div className="flex flex-row w-11/12 mobile:flex-col justify-center items-center px-36 mb-[100px]">
            {orderedEpisodes.map((episode, index) => {
              return episodeCard(episode, index);
            })}
          </div>
        </main>
      )}
      <img src={gradientBg} alt="" className="top-0 left-0 w-screen h-screen fixed z-0" />
    </div>
  );
}

export default TeamsHomeContainer;
