import { useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import * as msal from '@azure/msal-browser';

export default function OAuthEnd() {
  useEffect(() => {
    microsoftTeams.app.initialize().then(() => {
      microsoftTeams.app.getContext().then(async (context) => {
        const msalConfig: msal.Configuration = {
          auth: {
            clientId: process.env.REACT_APP_MS_APP_ID as string,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            authority: `https://login.microsoftonline.com/${context.user!.tenant!.id}`,
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: 'sessionStorage',
          },
        };

        const msalInstance = new msal.PublicClientApplication(msalConfig);

        await msalInstance.initialize();
        msalInstance
          .handleRedirectPromise()
          .then((tokenResponse) => {
            if (tokenResponse !== null) {
              microsoftTeams.authentication.notifySuccess('success');
            } else {
              microsoftTeams.authentication.notifyFailure('failure');
            }
          })
          .catch((error) => {
            microsoftTeams.authentication.notifyFailure(JSON.stringify(error));
          });
      });
    });
  }, []);

  return <></>;
}
