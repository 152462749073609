interface TypeStat {
  stat: string;
  unit: string;
  description: string;
}
interface TypeThemeStat {
  title: string;
  stats: Array<TypeStat>;
}

export const STATS_CONTENT: Record<string, TypeThemeStat> = {
  'well-being': {
    title: 'videoPage.statistics.well-being.heading',
    stats: [
      { stat: '18', unit: '%', description: 'videoPage.statistics.well-being.productivity' },
      { stat: '26', unit: '%', description: 'videoPage.statistics.well-being.performance' },
      { stat: '52', unit: '%', description: 'videoPage.statistics.well-being.burnout' },
    ],
  },
  leadership: {
    title: 'videoPage.statistics.leadership.heading',
    stats: [
      { stat: '26', unit: '%', description: 'videoPage.statistics.leadership.innovation' },
      { stat: '19', unit: '%', description: 'videoPage.statistics.leadership.culture' },
      { stat: '10', unit: '%', description: 'videoPage.statistics.leadership.performance' },
    ],
  },
  productivity: {
    title: 'videoPage.statistics.productivity.heading',
    stats: [
      { stat: '16', unit: '%', description: 'videoPage.statistics.productivity.goals' },
      { stat: '1.3', unit: 'X', description: 'videoPage.statistics.productivity.sales' },
      { stat: '2.4', unit: 'X', description: 'videoPage.statistics.productivity.promotion' },
    ],
  },
  connection: {
    title: 'videoPage.statistics.connection.heading',
    stats: [
      { stat: '34', unit: '%', description: 'videoPage.statistics.connection.goals' },
      { stat: '36', unit: '%', description: 'videoPage.statistics.connection.wellbeing' },
      { stat: '92', unit: '%', description: 'videoPage.statistics.connection.growth' },
    ],
  },
};
