import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { useTranslation } from 'react-i18next';
import { AssessmentInsightsProps } from 'utils/assessment';
import FocusSelectButton from './FocusSelectButton';
import { useContext } from 'react';
import { JourneyContext } from 'contexts/journey';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';

interface BreakdownElementFocusSelectProps {
  /**
   * @insights AssessmentInsightsProps object containing the alluvials and other insight data
   */
  insights: AssessmentInsightsProps;
  onPrevious: () => void;
}

export default function BreakdownElementFocusSelect({ insights, onPrevious }: BreakdownElementFocusSelectProps) {
  const { t } = useTranslation();
  const { saveFocusArea } = useContext(JourneyContext);
  const { navigate } = useCustomNavigation();

  const onFocusSelect = async (focusAreaId: string) => {
    await saveFocusArea(focusAreaId);

    navigate('/practices/recommend');
  };

  return (
    <div className={`absolute top-0 flex w-full flex-col mt-[270px] w-11/12 mx-auto`}>
      <div className="flex flex-col items-center m-auto w-11/12 desktop:w-2/3 desktop:mt-[200]">
        <h2 className="pa-heading-xl text-white text-center mb-36">{`${t(
          'assessment.postAssessment.breakdown.selection.title'
        )}`}</h2>
        {insights.dimensions.map((alluvial, index) => {
          return (
            <FocusSelectButton
              key={alluvial.key}
              title={alluvial.content.title}
              showVoc={index === 0}
              onClick={() => onFocusSelect(alluvial.key)}
            />
          );
        })}
        <div className="flex flex-row justify-between items-center mt-36">
          <button
            onClick={onPrevious}
            className="flex justify-center cursor-pointer items-center min-w-72 min-h-72 rounded-full mr-48 bg-white/65 hover:bg-white/90 active:bg-white"
            aria-label={`${t('buttons.previous')}`}
          >
            <SvgIcon icon={Icons.CaretLeft} size={28} className="text-midnight" />
          </button>
          <span className="pa-body text-white mr-72 opacity-50">{`${t(
            'assessment.postAssessment.breakdown.selection.previous'
          )}`}</span>
        </div>
      </div>
    </div>
  );
}
