import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getRoutinesUrl } from 'services/service-urls';

export interface Routine {
  id: number;
  title: string;
  time: string;
}

export interface RoutineCategory {
  id: number;
  title: string;
  options: Routine[];
}

export const getRoutines = async (baseUrl: string = baseUrlConfig): Promise<RoutineCategory[]> => {
  const res = await axios.get(getRoutinesUrl(baseUrl), getDefaultAxiosConfig());
  return res.data.routine_categories;
};
