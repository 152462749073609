import { deleteCurrentUser } from 'services/user-service';
import { trackEvent } from 'services/analytics-service';
import * as Sentry from '@sentry/react';

export const onSignout = () => {
  trackEvent({ eventName: 'Sign out' });

  deleteCurrentUser().catch((err) => {
    Sentry.captureException(err);
  });

  const cookie =
    'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' + process.env.REACT_APP_COOKIE_DOMAIN + ';';
  document.cookie = cookie;
  window.location.href = '/';
};
