import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { Carousel } from 'components/carousel';
import { useTranslation } from 'react-i18next';
import { PracticeExerciseType, PracticeType } from 'utils/post-assessment-types';
import { Gradient } from 'components/gradient';
import { getUserPractices } from 'services/user-practices-service';
import { getPracticesRecommended } from 'services/practice-service';
import { CSSTransition } from 'react-transition-group';
import { PostAssessmentLoading } from 'components/sections/post-assessment/loading';
import { CarouselFilters } from 'components/carousel-filters';
import { VARIANTS } from 'components/practice/practice-summary-card';
import { JourneyContext } from 'contexts/journey';
import { ThemeContext } from 'contexts/theme';
import { UserContext } from 'contexts/user';

export default function PostAssessmentComplete() {
  const { t } = useTranslation();
  const [recommendedPractices, setRecommendedPractices] = useState<PracticeExerciseType[]>();
  const [userPractices, setUserPractices] = useState<PracticeExerciseType[]>();
  const [alluvialKey, setAlluvialKey] = useState<string>();
  const [step, setStep] = useState<string>('yourPractices');
  const [alluvialPractices, setAlluvialPractices] = useState<PracticeType[]>();

  const { currentTheme } = useContext(ThemeContext);
  const { loading, insights, allPractices, loadPractices } = useContext(JourneyContext);
  const { user } = useContext(UserContext);
  const [loaderTimedOut, setLoaderTimedOut] = useState<boolean>(true);

  useEffect(() => {
    if (!loading && !allPractices) {
      loadPractices();
    }
  }, [loading, allPractices, loadPractices]);

  useEffect(() => {
    const assessmentCompleted =
      currentTheme &&
      user &&
      user.assessment_statuses[currentTheme?.key] !== 'started' &&
      user.assessment_statuses[currentTheme?.key] !== 'unstarted';
    if (currentTheme && assessmentCompleted && !userPractices) {
      getUserPractices(currentTheme.key)
        .then(setUserPractices)
        .catch((err) => {
          Sentry.captureException(err);
        });

      // todo we could get rid of this request and perform practices filtering on the frontend
      getPracticesRecommended(currentTheme.key)
        .then(setRecommendedPractices)
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, [currentTheme, user, userPractices]);

  useEffect(() => {
    if (recommendedPractices && insights) {
      const alluvialPractices: PracticeType[] = insights.dimensions.map((dimension) => {
        return {
          alluvial_key: dimension.key,
          practices: recommendedPractices?.filter((practice) => {
            const pKey = practice.key.substring(0, practice.key.length - 2).toLowerCase();
            return pKey === dimension.key;
          }),
        };
      });

      setAlluvialPractices(alluvialPractices);
    }
  }, [recommendedPractices, insights]);

  const gotoExplore = (alluvial?: string) => {
    alluvial && setAlluvialKey(alluvial);
    setStep('forYou');
  };

  return (
    <>
      <CSSTransition
        timeout={500}
        classNames="fade"
        in={!!(loaderTimedOut && recommendedPractices && userPractices)}
        mountOnEnter
        unmountOnExit
      >
        <>
          {!!(loaderTimedOut && recommendedPractices && userPractices) && (
            <div className="h-full tablet-and-up:min-h-896">
              <div
                className="absolute top-0 left-0 flex z-1 flex-col w-full h-full overflow-x-hidden"
                data-testid="practicesContainer"
              >
                <div className="flex flex-row p-32 desktop:pl-90 desktop:mb-12 mt-60">
                  <button
                    className={`hidden tablet-and-up:flex bg-transparent text-left pa-heading-l text-white cursor-pointer pb-20 tablet-and-up:mr-48 ${
                      step === 'yourPractices' ? 'border-b-[8px]' : 'border-b-[8px] border-transparent'
                    }`}
                    onClick={() => setStep('yourPractices')}
                    aria-label={`${t('assessment.postAssessment.onComplete.yourPractices')}`}
                    data-testid="yourPracticesButton"
                  >
                    {t('assessment.postAssessment.onComplete.yourPractices')}
                  </button>
                  <button
                    className={`hidden tablet-and-up:flex bg-transparent text-left pa-heading-l text-white cursor-pointer tablet-and-up:mr-24 min-w-148 desktop:mr-80 pb-20 ${
                      step === 'forYou' ? 'border-b-[8px]' : 'border-b-[8px] border-transparent'
                    }`}
                    onClick={() => setStep('forYou')}
                    aria-label={`${t('assessment.postAssessment.onComplete.exploreMore')}`}
                    data-testid="exploreButton"
                  >
                    {t('assessment.postAssessment.onComplete.exploreMore')}
                  </button>
                  <button
                    className={`relative tablet-and-up:hidden bg-transparent text-left body-l text-white cursor-pointer pb-20 mr-24 ${
                      step === 'yourPractices' ? 'border-b-[4px]' : 'border-b-[4px] border-transparent'
                    }`}
                    onClick={() => setStep('yourPractices')}
                    aria-label={`${t('assessment.postAssessment.onComplete.yourPractices')}`}
                  >
                    {t('assessment.postAssessment.onComplete.yourPractices')}
                  </button>
                  <button
                    className={`relative tablet-and-up:hidden bg-transparent text-left body-l text-white cursor-pointer min-w-148 tablet-and-up:mr-24 desktop:mr-80 pb-20 ${
                      step === 'forYou' ? 'border-b-[4px]' : 'border-b-[4px] border-transparent'
                    }`}
                    onClick={() => setStep('forYou')}
                    aria-label={`${t('assessment.postAssessment.onComplete.exploreMore')}`}
                  >
                    {t('assessment.postAssessment.onComplete.exploreMore')}
                  </button>
                </div>
                {step === 'forYou' && (
                  <>
                    {insights && (
                      <CarouselFilters
                        selectedAlluvial={alluvialKey || insights.dimensions[0].key}
                        alluvials={insights.dimensions}
                        practices={alluvialPractices}
                      />
                    )}
                  </>
                )}
                {step === 'yourPractices' && (
                  <div className="mt-64">
                    <Carousel
                      inverted={true}
                      practices={userPractices}
                      revealVideoCb={() => {}}
                      insights={insights}
                      showHints={true}
                      gotoExplore={gotoExplore}
                      variant={VARIANTS.yourPractices}
                    />
                  </div>
                )}
              </div>
              <Gradient />
            </div>
          )}
        </>
      </CSSTransition>
      <CSSTransition
        timeout={500}
        classNames="fade"
        in={!(loaderTimedOut && recommendedPractices && userPractices)}
        mountOnEnter
        unmountOnExit
      >
        <>
          {!(loaderTimedOut && recommendedPractices && userPractices) && (
            <PostAssessmentLoading inverted onTimeout={() => setLoaderTimedOut(true)} />
          )}
        </>
      </CSSTransition>
    </>
  );
}
