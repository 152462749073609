import { Button } from 'components/button';
import { ColorGradient, PostAssessmentOverviewContent } from 'services/content-service';
import { convertHexToRGBA } from 'utils/helpers';
import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import useElementSize from 'utils/hooks/useElementSize';
import { useTranslation } from 'react-i18next';
import { InvertedProps } from 'utils/components';
import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';

interface PostAssessmentOverviewProps extends InvertedProps {
  /**
   * Content for use in the Post Assessment Overview component
   */
  content: PostAssessmentOverviewContent;
}

interface GraphProps {
  score: number;
  colorGradient: ColorGradient;
  isVisible: boolean;
  inverted?: boolean;
}

export default function PostAssessmentOverview({ inverted, content }: PostAssessmentOverviewProps) {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [alreadyTransitioned, setAlreadyTransitioned] = useState<boolean>(false);

  const onClick = () => {
    navigate('/assessments/breakdown');
  };

  return (
    <div className={`relative top-0 w-full h-full flex`}>
      <div
        className="text-center pb-24 tablet-and-up:pb-0 w-11/12 desktop:w-2/3 max-w-736 mx-auto min-h-744 overflow-x-hidden"
        data-testid="post-assessment-overview"
      >
        <VisibilitySensor
          onChange={(isVisible: boolean) => {
            setIsVisible(isVisible);
            if (isVisible && !alreadyTransitioned) {
              setAlreadyTransitioned(true);
            }
          }}
        >
          <Graph
            isVisible={isVisible || alreadyTransitioned}
            score={content.overall_score}
            colorGradient={content.color_gradient}
            inverted={inverted}
          />
        </VisibilitySensor>
        <h2 className={`pa-heading-xl ${inverted ? 'text-white' : 'text-black'} mb-36 w-full m-auto`}>
          {content.title}
        </h2>
        <div className="flex flex-row items-center justify-center">
          <div className="w-280 h-1 bg-voiceOfCoachDark"></div>
          <SvgIcon icon={Icons.Electric} size={90} />
          <div className="w-280 h-1 bg-voiceOfCoachDark"></div>
        </div>
        <p className="pa-body-coach-tip-l text-voiceOfCoachDark">{content.voice_of_a_coach.title}</p>
        <p className={`pa-body-coach-tip-l ${inverted ? 'text-voiceOfCoachLight' : 'text-black'} mb-24 w-2/3 m-auto`}>
          {content.voice_of_a_coach.description}
        </p>
        <p className={`pa-body ${inverted ? 'text-white' : 'text-black'} mb-24 m-auto`}>{content.description}</p>
        <Button onClick={onClick} label={t('buttons.continue')} text={`${t('buttons.continue')}`} />
      </div>
    </div>
  );
}

function Graph({ score, colorGradient, isVisible, inverted = false }: GraphProps) {
  const { t } = useTranslation();
  const [ref, { width }] = useElementSize();
  const position = (width * score) / 100;

  return (
    <div
      ref={ref}
      className="flex w-full desktop:w-[433px] m-auto relative justify-center mt-100 mb-64 scale-75 desktop:scale-100"
    >
      <div
        className={`flex flex-col items-center absolute z-4 top-[-40px]`}
        style={
          isVisible
            ? {
                transition: `left 250ms ease-in 200ms`,
                left: position,
              }
            : {
                left: 0,
              }
        }
      >
        <span
          className={`body-l bg-voiceOfCoachDark text-center p-8 px-12 rounded-10 ${
            inverted ? 'text-white' : 'text-black'
          }`}
        >
          {t('assessment.postAssessment.breakdown.you')}
        </span>
        <div className="bg-voiceOfCoachDark text-center w-4 h-32 align-center h-[142px]" />
      </div>
      <div className="w-[433px] h-[132px] relative text-center z-3">
        <svg viewBox="0 0 433 132" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            id="bell-curve"
            style={{ stroke: '#fff', strokeWidth: 2 }}
            d="M0 131.569C90 131.569 99.3103 2 215.69 2C332.069 2 336.724 131.569 432.931 131.569"
          />
        </svg>
      </div>
      <div
        className="flex flex-col items-center absolute left-32 top-0 z-2 bg-black h-[133px] w-full"
        style={
          isVisible
            ? {
                transition: `left 250ms ease-in 200ms`,
                left: position + 26,
              }
            : {
                left: 0,
              }
        }
      />
      <div
        className="absolute top-0 w-[433px] h-[132px] text-center"
        style={{
          background: `linear-gradient(190.57deg, rgba(0, 0, 0, 0) 50.44%, #000000 83.8%), linear-gradient(263.87deg, ${convertHexToRGBA(
            colorGradient.dark,
            0.43
          )} 9.04%, ${convertHexToRGBA(colorGradient.light, 0.43)} 85.11%)`,
        }}
      >
        <svg viewBox="0 0 433 132" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            id="bell-curve-mask"
            style={{ fill: '#000' }}
            d="M433 0H0V130.569C41.6367 130.569 65.7847 102.555 91.8715 72.2926C93.6359 70.2456 95.4093 68.1884 97.1975 66.1289C125.346 33.7098 157.028 1 215.69 1C274.359 1 304.879 33.7204 332.635 66.1341C333.93 67.6467 335.219 69.1581 336.504 70.6653L336.515 70.6787C362.824 101.529 387.588 130.569 432.931 130.569V132H433V0Z"
          />
        </svg>
      </div>
    </div>
  );
}
