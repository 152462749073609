import * as Sentry from '@sentry/react';
import { useContext, useEffect, useState } from 'react';
import { Routine } from 'services/routines-service';
import { addToTeamsCalendar, createUserPractice, UserPractice } from 'services/user-practices-service';
import CueIntro from 'components/sections/post-assessment/cue/intro';
import CueSelection from 'components/sections/post-assessment/cue/selection';
import CueConfirmation from 'components/sections/post-assessment/cue/confirmation';
import { PracticeSummaryCard } from 'components/practice/practice-summary-card';
import { convertLocalTimeToUTC } from 'utils/helpers';
import { RoutineCategory } from 'services/routines-service';
import { InvertedProps } from 'utils/components';
import { PracticeExerciseType } from 'utils/post-assessment-types';
import { CSSTransition } from 'react-transition-group';
import { AssessmentInsightsProps } from 'utils/assessment';
import { trackEvent } from 'services/analytics-service';
import PostAssessmentGlows from 'components/sections/post-assessment/glows';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';
import { UserContext } from 'contexts/user';

export interface CueProps extends InvertedProps {
  /**
   * @param routines array of routine category tabs (with routine options)
   */
  routines: RoutineCategory[];
  /**
   * @param practice PracticeExerciseType practice exercise
   */
  practice: PracticeExerciseType;
  /**
   * Handler for completing cue flow
   */
  onComplete: () => void;
  /**
   * @insights AssessmentInsightsProps object containing the alluvials and other insight data
   */
  insights: AssessmentInsightsProps;
}

export default function PostAssessmentCue({ practice, routines, onComplete, insights }: CueProps) {
  const { inTeams } = useContext(UserContext);

  const { navigate } = useCustomNavigation();
  const assessmentId = insights.id;
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>('intro');
  const [practiceLabel, setPracticeLabel] = useState<string>(practice.cadence.description);
  const [selectedRoutineCategory, setSelectedRoutineCategory] = useState<RoutineCategory>();
  const [selectedRoutine, setSelectedRoutine] = useState<Routine>();
  const [userPractice, setUserPractice] = useState<UserPractice>();
  const { reloadUser, updateNeedsExpandedPermissions } = useContext(UserContext);

  useEffect(() => {
    if (selectedRoutine) {
      const utcTime = convertLocalTimeToUTC(selectedRoutine.time);
      createUserPractice({
        assessment_id: assessmentId,
        practice_key: practice.key,
        routine_id: selectedRoutine.id,
        time: utcTime,
        cadence: practice.cadence.key,
      })
        .then((createUserPracticeResponse: UserPractice) => {
          setCurrentStep('confirmation');
          setUserPractice(createUserPracticeResponse);
          trackEvent({
            eventName: 'User practice created',
            eventProperties: {
              practiceTitle: practice.title,
              practiceKey: practice.key,
              practiceCadence: practice.cadence.description,
              routineTitle: selectedRoutine.title,
              routineTime: selectedRoutine.time,
              routineId: selectedRoutine.id,
              routineCategoryTitle: selectedRoutineCategory?.title,
              routineCategoryId: selectedRoutineCategory?.id,
            },
          });
          reloadUser();
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, [practice, selectedRoutineCategory, selectedRoutine, assessmentId, reloadUser]);

  useEffect(() => {
    setShowAnimation(true);
  }, []);

  const showSelection = () => {
    setCurrentStep('selection');
    setShowAnimation(false);

    trackEvent({
      eventName: 'User practice initiated',
      eventProperties: {
        practiceTitle: practice.title,
        practiceKey: practice.key,
        practiceCadence: practice.cadence.description,
      },
    });
  };

  const categorySelected = (category: RoutineCategory) => {
    setSelectedRoutineCategory(category);
    setPracticeLabel(category.title);
  };

  const routineSelected = (routine: Routine) => {
    setSelectedRoutine(routine);
    setPracticeLabel(`${practiceLabel}<br />${routine.title}`);
  };
  const onAddToTeamsCalendarClick = () => {
    addToTeamsCalendar({
      user_practice_id: userPractice?.id || -1,
    })
      .then((addToTeamsCalendarResponse) => {
        navigate('/assessments');
      })
      .catch((err) => {
        if (err.response.data.error === 'expanded_consent_required') {
          updateNeedsExpandedPermissions(true);
        } else {
          Sentry.captureException(err);
        }
      });
  };

  const padding = inTeams ? 'pt-[10%]' : 'pt-72';
  return (
    <div className="overflow-hidden flex items-center min-h-[inherit] w-full bg-black">
      <div
        className={`flex flex-col desktop:flex-row justify-center items-center w-full max-w-1440 h-full mx-auto ${padding}`}
        data-testid="post-assessment-cue"
      >
        <CSSTransition classNames="cue-practice" timeout={4000} in={showAnimation} mountOnEnter>
          <div className="relative">
            <div className="grow desktop:max-w-288 desktop:mr-144 relative z-1">
              <PracticeSummaryCard
                inverted={true}
                label={practiceLabel}
                practiceExercise={practice}
                active={false}
                showCheck={true}
              />
            </div>
            <div className="rings-glow z-0 absolute top-[250px] opacity-80 left-120 flex flex-row flex-wrap w-400">
              <PostAssessmentGlows
                colors={[
                  insights.dimensions[0].content.color_gradient.light,
                  insights.dimensions[1].content.color_gradient.light,
                  insights.dimensions[2].content.color_gradient.light,
                  insights.dimensions[3].content.color_gradient.light,
                ]}
              />
            </div>
          </div>
        </CSSTransition>

        <div className="relative flex w-full h-full items-center max-w-520 min-h-480 mb-12 mt-24 desktop:mt-0">
          <CSSTransition
            classNames="cue-delay-fade"
            timeout={showAnimation ? 4000 : 600}
            in={showAnimation}
            mountOnEnter
            unmountOnExit
          >
            <CueIntro onClick={showSelection} onCancel={onComplete} content={practice.cue} />
          </CSSTransition>

          <CSSTransition classNames="fade" timeout={600} in={currentStep === 'selection'} mountOnEnter unmountOnExit>
            <CueSelection routines={routines} onCategorySelect={categorySelected} onRoutineSelect={routineSelected} />
          </CSSTransition>

          <CSSTransition classNames="fade" timeout={600} in={currentStep === 'confirmation'} mountOnEnter unmountOnExit>
            <CueConfirmation onClick={onComplete} onAddToCalendarClick={onAddToTeamsCalendarClick} />
          </CSSTransition>
        </div>
      </div>
    </div>
  );
}
