import { ProgressBarProps } from 'utils/components';

/**
 * Primary UI component for user interaction
 */
export const ProgressBar = ({
  currentStep = 0,
  maxSteps = 100,
  backgroundColor = 'bg-gray-aa',
  currentStepBackgroundColor = 'bg-blue',
  className = '',
}: ProgressBarProps) => {
  const calculatedPercentage = (currentStep / maxSteps) * 100;
  return (
    <div className={`${backgroundColor} h-24 w-full rounded-100 ${className}`} data-testid="progress-bar">
      <div
        className={`${currentStepBackgroundColor} h-full rounded-100`}
        style={{ width: `${calculatedPercentage}%` }}
        data-testid="progress-bar-value"
      />
    </div>
  );
};
