import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginForm from 'containers/login-page/email/login-form';
import LoginComplete from 'containers/login-page/email/login-complete';
import { LoginContent } from 'services/content-service';
import { useTranslation } from 'react-i18next';

export interface LoginBaseProps {
  content: LoginContent;
}

export interface EmailLoginProps extends LoginBaseProps {
  isLinkInvalid: boolean;
}

export default function EmailLogin({ content, isLinkInvalid }: EmailLoginProps): JSX.Element | null {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onComplete = () => {
    navigate('/loginComplete');
  };

  return (
    <div
      className={
        'flex flex-col bg-white justify-center self-center px-24 py-48 text-center w-2/3 min-h-500 min-w-[440px] max-w-[840px] max-h-[618px] rounded'
      }
    >
      <div className="flex flex-row justify-center items-center content-center">
        <img src={content.betterupLogo} height="31px" alt={t('betterupLogoAlt')} />
      </div>
      <Routes>
        <Route
          path="/"
          element={<LoginForm content={content} onComplete={onComplete} isLinkInvalid={isLinkInvalid} />}
        />
        <Route path="/loginComplete" element={<LoginComplete content={content} />} />
      </Routes>
    </div>
  );
}
