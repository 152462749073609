import MariaFallbackImage from 'assets/images/backgrounds/maria-video-fallback.jpeg';
import Cookies from 'universal-cookie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_HEADERS: any = { Accept: 'application/json' };
export const DEFAULT_AXIOS_OPTIONS = { withCredentials: process.env.REACT_APP_WITH_CREDENTIALS === 'true' };

export const getDefaultAxiosConfig = () => {
  const headers = DEFAULT_HEADERS;
  const cookies = new Cookies();

  let bearerToken = cookies.get('token'); // browser
  bearerToken ||= window.sessionStorage.getItem('token'); // Teams app
  if (bearerToken) {
    headers['Authorization'] = `Bearer ${bearerToken}`;
  }

  if (window.sessionStorage.getItem('authenticityToken')) {
    headers['X-CSRF-TOKEN'] = window.sessionStorage.getItem('authenticityToken');
  }

  return { headers, ...DEFAULT_AXIOS_OPTIONS };
};

export const DEFAULT_VIDEO = {
  id: 1,
  title: 'What is BetterUp?',
  description: 'Lorem Ipsum',
  image_url: MariaFallbackImage,
  vimeo_id: '387457350',
};

export const exampleAssessmentOptions = [
  {
    subtitle: 'subtitle 1',
    label: 'label 1',
    value: 'value 1',
    position: 1,
  },
  {
    subtitle: 'subtitle 2',
    label: 'label 2',
    value: 'value 2',
    position: 2,
  },
  {
    subtitle: 'subtitle 3',
    label: 'label 3',
    value: 'value 3',
    position: 3,
  },
  {
    subtitle: 'subtitle 4',
    label: 'label 4',
    value: 'value 4',
    position: 4,
  },
  {
    subtitle: 'subtitle 5',
    label: 'label 5',
    value: 'value 5',
    position: 5,
  },
];

export const DEFAULT_ASSESSMENT = {
  id: 3,
  title: 'Assessment Title',
  subtitle: 'Assessment Sub-Title',
  description: 'This is the description.',
  links: {},
  questions: [
    {
      assessment_configuration_id: 1,
      created_at: '2022-09-13T20:54:12.626Z',
      id: 1,
      question_key: 'hello_world_question',
      updated_at: '2022-09-13T20:54:12.626Z',
      prompt: 'Hello World',
      options: exampleAssessmentOptions,
    },
  ],
  user_id: 1,
  responses: {},
};

export const DEFAULT_UPDATED_ASSESSMENT_RESPONSE = {
  [DEFAULT_ASSESSMENT.questions[0].question_key]: '1',
};

export const DEFAULT_SUBMIT_ASSESSMENT_RESPONSE = {
  submitted_at: new Date().toISOString(),
  ...DEFAULT_UPDATED_ASSESSMENT_RESPONSE,
};
