import { ScaleItem, TextPositions } from 'components/assessment/question-types/input-scale/item';

export interface ScaleOption {
  /**
   * Optional subtitle for option (e.g., 'Strongly Agree')
   */
  subtitle?: string;
  /**
   * Label for option
   */
  label: string;
  /**
   * Value for option
   */
  value: string;
  /**
   * Used for sorting options
   */
  position: number;
}
interface ScaleGroupProps {
  /**
   * Inverted item text
   */
  inverted?: boolean;
  /**
   * Classes list
   */
  className?: string;
  /**
   * Question identifier string
   */
  questionKey: string;
  /**
   * Options list for scale group
   */
  options: ScaleOption[];
  /**
   *
   */
  response: number | string;
  /**
   * Callback when value changes within group
   */
  onChange: (questionKey: string, value: string) => void;
}

const getTextPosition = (index: number, optionsLength: number) => {
  if (index === 0) {
    return TextPositions.left;
  }
  if (index + 1 === optionsLength) {
    return TextPositions.right;
  }
  return TextPositions.center;
};

/**
 * Primary UI component for user interaction
 */
export const ScaleGroup = ({ inverted, className = '', options, questionKey, response, onChange }: ScaleGroupProps) => {
  return (
    <div className={`flex justify-center flex-wrap ${className}`}>
      {options.map((option: ScaleOption, index) => {
        return (
          <ScaleItem
            active={option.value === response}
            key={`${option.label}-${index}`}
            text={option.subtitle}
            scaleItemText={option.label}
            textPosition={getTextPosition(index, options.length)}
            label={option.label}
            inverted={inverted}
            onClick={() => onChange(questionKey, option.value)}
          />
        );
      })}
    </div>
  );
};
