import axios from 'axios';
import platform from 'platform';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getAnalyticsTrackUrl } from 'services/service-urls';
import { isTeams } from 'utils/teams';

interface AnalyticsEventProps {
  /**
   * The name of the event
   */
  eventName: string;
  /**
   * Object containing key/values for properties
   */
  eventProperties?: object;
}

export async function trackEvent(
  { eventName, eventProperties = {} }: AnalyticsEventProps,
  baseUrl: string = baseUrlConfig
): Promise<void> {
  if (await analyticsEnabled()) {
    const defaultProperties = await getDefaultProperties();
    const properties = {
      ...defaultProperties,
      ...eventProperties,
    };

    return axios.post(getAnalyticsTrackUrl(baseUrl), { event: eventName, properties }, getDefaultAxiosConfig());
  }
}

async function getDefaultProperties(): Promise<object> {
  const runningInTeams = await isTeams();

  return {
    url: window.location.pathname,
    browser: platform.name,
    platform: platform.os?.toString(),
    app_platform: runningInTeams ? 'msft-teams' : 'web',
    local_time: new Date().toLocaleTimeString(),
  };
}

async function analyticsEnabled(): Promise<boolean> {
  const runningInTeams = await isTeams();
  if (runningInTeams) {
    return true;
  }

  try {
    const gdprApplies = window._iub.cs.api.gdprApplies();
    const consentGiven = window._iub.cs.api.isConsentGiven();

    if (gdprApplies) {
      return consentGiven;
    } else {
      return true;
    }
  } catch {
    return false;
  }
}
