import { Button, ButtonVariants } from 'components/button';
import { Icons } from 'assets/icons';
import { LogoImageComponents } from 'assets/images/logos';
import { SvgIcon } from 'components/svg-icon';
import { useTranslation, Trans } from 'react-i18next';

export interface SsoLoginProps {
  authenticityToken?: string;
}

export default function SsoLogin({ authenticityToken }: SsoLoginProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <form
      className="flex items-center align-middle h-full w-full"
      method="post"
      action={`${process.env.REACT_APP_API_URL}/users/auth/microsoft_office365`}
    >
      {authenticityToken && (
        <input type="hidden" name="authenticity_token" value={authenticityToken} data-testid="authenticity-token" />
      )}
      <div className="grid grid-cols-6 grow tablet-and-up:px-24 mobile:p-8 mobile:content-center tablet:content-center">
        <div className="col-span-6 desktop:col-span-3 flex flex-col max-w-524 mobile:mb-28 tablet:mb-28">
          <h1 className="heading-xl text-white mb-12 desktop:mb-28 text-[3rem]">
            <Trans i18nKey="loginPage.welcome" />
          </h1>
          <p className="body-l text-white mb-12 desktop:mb-28">{t('loginPage.subtitle')}</p>
          <button
            aria-label={t('loginPage.loginButtonA11y')}
            className="bg-white rounded-4 flex items-center align-middle justify-center p-12 mobile:p-8 hover:cursor-pointer focus-visible:shadow-sm"
            type="submit"
          >
            <img
              src={LogoImageComponents.MicrosoftSmallLogo}
              height="31px"
              alt={t('betterupLogoAlt')}
              className="mr-40 mobile:mr-12"
            />
            <p className="body-l text-midnight">{t('loginPage.loginButton')}</p>
          </button>
        </div>
        <div className="col-span-6 desktop:col-span-3 flex w-full mobile:justify-center tablet:justify-center desktop:items-center desktop:align-middle desktop:ml-100 mobile:mt-44 rounded-full">
          <Button
            variant={ButtonVariants.IconInverted}
            testId="play-btn"
            label={t('loginPage.loginButtonA11y')}
            className="w-112 h-112 flex items-center justify-center hover:cursor-pointer focus-visible:shadow-button-focus-inverted"
            type="submit"
          >
            <SvgIcon icon={Icons.PlayIcon} size={69} />
          </Button>
        </div>
      </div>
    </form>
  );
}
