import PostAssessmentBreakdown from 'components/sections/post-assessment/breakdown';
import { JourneyContext } from 'contexts/journey';
import { ThemeContext } from 'contexts/theme';
import { useContext, useEffect, useState } from 'react';
import { BreakdownIntroductionContent, getPostAssessmentIntroductionContent } from 'services/content-service';

export default function PostAssessmentContainerBreakdown() {
  const [breakdownIntroductionContent, setBreakdownIntroductionContent] = useState<BreakdownIntroductionContent>();

  const { insights } = useContext(JourneyContext);
  const { currentTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (currentTheme) {
      getPostAssessmentIntroductionContent(currentTheme.key).then((res) => {
        setBreakdownIntroductionContent(res.breakdown_introduction);
      });
    }
  }, [currentTheme]);

  return (
    <main className="w-full h-full overflow-hidden relative" data-testid="post-assessment-breakdown">
      {insights && breakdownIntroductionContent && (
        <PostAssessmentBreakdown insights={insights} breakdownIntroductionContent={breakdownIntroductionContent} />
      )}
    </main>
  );
}
