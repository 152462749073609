import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { LoginBaseProps } from 'containers/login-page/email/index';
import { createNewUser, createUserSignIn, LoginStatus } from 'services/login-service';
import * as Sentry from '@sentry/react';
import { isEmailValid } from 'utils/helpers/isEmailValid';

export interface LoginFormProps extends LoginBaseProps {
  onComplete: () => void;
  isLinkInvalid: boolean;
}

export default function LoginForm({ content, onComplete, isLinkInvalid }: LoginFormProps): JSX.Element | null {
  const { t } = useTranslation();
  const [isLoginFlow, setIsLoginFlow] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>(isLinkInvalid ? content.invalidLinkMessage : '');

  const handleTermsCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const onContinue = () => {
    const params = { user: { email: email } };
    createUserSignIn(params)
      .then((status: LoginStatus) => {
        if (status.code === 200) {
          // navigate to complete
          onComplete();
        } else if (status.code === 404) {
          // navigate to new user
          setIsLoginFlow(false);
          setServerError('');
        } else {
          Sentry.captureMessage('createUserSignIn: ' + status.code + ' : ' + status.message);
          setServerError(status.message + ': ' + status.errors[0][1]);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const onNewUser = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = {
      user: { email: email, first_name: firstName, accepted_terms_at: new Date().toISOString(), time_zone: timezone },
    };
    createNewUser(params)
      .then((status: LoginStatus) => {
        if (status.code === 200) {
          onComplete();
        } else {
          Sentry.captureMessage('createNewUser: ' + status.code + ' : ' + status.message);
          setServerError(status.message + ': ' + status.errors[0][1]);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const disableSignUp = isEmailValid(email) && !!firstName && termsAccepted;

  return (
    <div className="w-2/3 mx-auto" data-testid={'login-form'}>
      <div className="pa-heading my-16 m-auto" dangerouslySetInnerHTML={{ __html: content.welcome }} />
      <div className="flex items-center justify-center flex-col">
        {serverError && (
          <div className="text-rubine" data-testid={'server-errorMessage'}>
            {serverError}
          </div>
        )}
        <div className="flex flex-col items-center my-24 w-full max-w-[433px]">
          <div className="w-full flex flex-col text-left">
            <label htmlFor="email" className="required pa-body-label-active">
              {t('loginPage.email.label')}
            </label>
            {isLoginFlow ? (
              <>
                <input
                  className="bg-[#F9F9F9] my-8 rounded-4 min-w-[250px] p-12 border-1"
                  style={{
                    backgroundColor: 'rgba(#000000,0.2)',
                  }}
                  autoComplete="email"
                  type="email"
                  defaultValue=""
                  name="email"
                  id="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('loginPage.email.placeholder')}
                />
                <Button
                  disabled={!isEmailValid(email)}
                  testId="callToActionBtn"
                  label={content.ctaButtonLogin}
                  text={content.ctaButtonLogin}
                  onClick={onContinue}
                  className="w-full my-24"
                />
              </>
            ) : (
              <>
                <span
                  className="bg-[#F9F9F9] text-gray-80 text-[13px] my-8 rounded-4 min-w-[250px] p-12 border-1 cursor-not-allowed"
                  style={{
                    backgroundColor: 'rgba(#000000,0.2)',
                    fontFamily: 'Arial',
                  }}
                >
                  {email}
                </span>
                <div className="w-full flex flex-col text-left">
                  <label className="required pa-body-label-active my-8">{t('loginPage.firstName.label')}</label>
                  <input
                    className="bg-[#F9F9F9] rounded-4 min-w-[250px] p-12 border-1"
                    style={{
                      backgroundColor: 'rgba(#000000,0.2)',
                    }}
                    type="text"
                    defaultValue=""
                    name="firstName"
                    id="firstName"
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder={t('loginPage.firstName.placeholder')}
                  />
                  <div className="flex flex-row items-start my-12">
                    <input
                      className="border-gray-80"
                      name="terms"
                      id="terms"
                      type="checkbox"
                      checked={termsAccepted}
                      onChange={handleTermsCheckbox}
                    />
                    <label
                      htmlFor="terms"
                      className="body-xxs text-gray-80 ml-4"
                      dangerouslySetInnerHTML={{ __html: content.agreeToTerms }}
                    />
                  </div>
                </div>
                <Button
                  disabled={!disableSignUp}
                  testId="callToActionBtn"
                  label={content.ctaButtonNewUser}
                  text={content.ctaButtonNewUser}
                  onClick={onNewUser}
                  className="w-full my-24"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
