import { useTranslation } from 'react-i18next';
import { CueContent } from 'services/content-service';
import FocusSelectButton from '../../breakdown/FocusSelectButton';
import { trackEvent } from 'services/analytics-service';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';

interface CueIntroProps {
  onClick: () => void;
  onCancel: () => void;
  content: CueContent;
}
export default function CueIntro({ onClick, onCancel, content }: CueIntroProps) {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigation();

  const gotoNext = () => {
    trackEvent({
      eventName: 'Button clicked',
      eventProperties: {
        page: 'post-assessment-cue-intro',
        button: 'Make this a habit',
      },
    });
    onClick();
  };

  const gotoExplore = () => {
    trackEvent({
      eventName: 'Button clicked',
      eventProperties: {
        page: 'post-assessment-cue-intro',
        button: 'Explore more practices',
      },
    });
    navigate('/practices/recommend');
  };

  const gotoCancel = () => {
    trackEvent({
      eventName: 'Button clicked',
      eventProperties: {
        page: 'post-assessment-cue-intro',
        button: "I'm done for today",
      },
    });
    onCancel();
  };

  return (
    <div className="absolute text-white" data-testid="cue-intro">
      <h1 className="pa-heading-xl mb-16 mx-12 tablet-and-up:mx-0">{content.title}</h1>
      <p className="pa-body mb-24 mx-12 tablet-and-up:mx-0">{content.description}</p>
      <div className="flex flex-col content-center items-center">
        <FocusSelectButton
          title={t('assessment.postAssessment.cue.intro.button1')}
          onClick={gotoNext}
          showVoc={true}
          subtitle={t('assessment.postAssessment.cue.intro.button1Subtitle')}
        />
        <FocusSelectButton title={t('assessment.postAssessment.cue.intro.button2')} onClick={gotoExplore} />
        <FocusSelectButton title={t('assessment.postAssessment.cue.intro.button3')} onClick={gotoCancel} />
      </div>
    </div>
  );
}
