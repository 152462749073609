import { Card } from 'components/cards/basic';
import { Navigation } from 'components/assessment/cards/navigation';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { useContext } from 'react';
import { ThemeContext } from 'contexts/theme';

interface InterstitialCardProps {
  /**
   * Inverted colors
   */
  inverted?: boolean;
  /**
   * Question heading
   */
  heading: string;
  /**
   * Question body
   */
  body?: string;
  /**
   * Current step number
   */
  currentStep: number;
  /**
   * Total steps number
   */
  totalSteps: number;
  /**
   * Question identifier string
   */
  questionKey: string;
  /**
   * Can the question be skipped
   */
  is_skippable: boolean;
  /**
   * Classes list
   */
  className?: string;
  /**
   * Callback when the button is clicked
   */
  onChange: (questionKey: string, value: string) => void;
  /**
   * Optional back button click handler
   */
  onBackButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional forward button click handler
   */
  onForwardButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const InterstitialCard = ({
  inverted,
  heading,
  body,
  currentStep,
  totalSteps,
  questionKey,
  is_skippable,
  onChange,
  className = '',
  onBackButtonClick,
  onForwardButtonClick,
}: InterstitialCardProps) => {
  const { t } = useTranslation();
  const textStyles = inverted ? 'text-inverted' : '';
  const { currentTheme } = useContext(ThemeContext);
  return (
    <Card
      testId="interstitial-card"
      className={`${inverted ? 'bg-midnight' : 'bg-white'} p-0 no-action h-full min-h-[inherit] ${className}`}
    >
      <div className="flex flex-col justify-between grow">
        <div className="flex flex-col justify-center items-center align-middle text-center my-auto mx-auto p-32 mobile:p-12 tablet:p-18 w-1/2">
          {heading && <h1 className={`${textStyles} heading-l mb-16`}>{heading}</h1>}
          {body && <h2 className={`${textStyles} body-l mb-16`}>{body}</h2>}
          <Button
            label={`${t('buttons.continue')}`}
            text={`${t('buttons.continue')}`}
            onClick={() => onChange(questionKey, '')}
            className="flex-none mt-12 desktop:mt-24 max-w-224 hover:cursor-pointer"
          />
        </div>
        <Navigation
          inverted={inverted}
          title={`${t('assessment.navbarTitle', { theme: currentTheme?.title })}`}
          currentStep={currentStep}
          totalSteps={totalSteps}
          is_skippable={is_skippable}
          onBackButtonClick={onBackButtonClick}
          onForwardButtonClick={onForwardButtonClick}
        />
      </div>
    </Card>
  );
};
