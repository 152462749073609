import { MouseEventHandler } from 'react';

interface CardProps {
  /**
   * Optional background image
   */
  backgroundImageUrl?: string;
  /**
   * Optional children
   */
  children?: JSX.Element;
  /**
   * Classes list
   */
  className?: string;
  /**
   * test id
   */
  testId?: string;
  /**
   * Optional click handler
   */
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  /**
   * Optional mouse enter handler
   */
  onMouseEnter?: MouseEventHandler<HTMLDivElement> | undefined;
  /**
   * Optional mouse leave handler
   */
  onMouseLeave?: MouseEventHandler<HTMLDivElement> | undefined;
}

/**
 * Primary UI component for user interaction
 */
export const Card = ({
  backgroundImageUrl,
  children,
  className,
  testId,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: CardProps) => {
  const backgroundImageStyles = backgroundImageUrl
    ? {
        background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${backgroundImageUrl}) no-repeat center / cover`,
      }
    : undefined;

  return (
    <div
      className={`w-full ${className}`}
      style={backgroundImageStyles}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={testId ? testId : 'card'}
    >
      {children}
    </div>
  );
};
