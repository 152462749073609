import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['de', 'en', 'es', 'fr', 'it', 'ja', 'nl', 'pt', 'sv', 'zh'],
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'locale',
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
    },
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? true : false,
  });
