import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { useTranslation } from 'react-i18next';
import { convertHexToRGBA } from 'utils/helpers';
import { voiceOfCoachDark, voiceOfCoachLight } from 'utils/helpers/colors';
import useElementSize from 'utils/hooks/useElementSize';

export interface BarchartProps {
  /**
   * @param score number where the "You" should move to
   */
  score: number;
  /**
   * @param title string dynamic title from the backend
   */
  title: string;
  /**
   * @param description string dynamic description from the backend
   */
  description: string;
  /**
   * @param middleColor string color of the middle block
   */
  middleColor: string;
  /**
   * @param lastColor string color of the right block
   */
  lastColor: string;
  /**
   * @param drawerBgColor string color of the drawer bg
   */
  drawerBgColor: string;
  /**
   * @param drawerBorderColor string the border color of the drawer
   */
  drawerBorderColor: string;
  /**
   * @param currentIndex number which index the user is on
   */
  currentIndex: number;
}

/**
 * Primary UI component for user interaction
 */
export const Barchart = ({
  score,
  title,
  description,
  middleColor,
  lastColor,
  drawerBgColor,
  drawerBorderColor,
  currentIndex,
}: BarchartProps) => {
  const { t } = useTranslation();
  const [ref, { width, isMobile }] = useElementSize();
  const position = (width - 6) * (score / 100);
  const barOneActive = score <= 33;
  const barTwoActive = score > 33 && score <= 66;
  const barThreeActive = score > 66 && score <= 100;
  const barOneBgColor = barOneActive ? voiceOfCoachLight : convertHexToRGBA('#ffffff', 0.2);
  const barTwoBgColor = barTwoActive ? middleColor : convertHexToRGBA('#ffffff', 0.2);
  const barThreeBgColor = barThreeActive ? lastColor : convertHexToRGBA('#ffffff', 0.2);
  return (
    <div className="p-8 tablet-and-up:p-0">
      <div className={`flex flex-col w-full tablet-and-up:w-3/4 desktop:w-1/2 h-full m-auto relative`} ref={ref}>
        <div
          className="flex flex-col items-center absolute left-32"
          style={{
            transition: `left 250ms ease-in 200ms`,
            left: position,
            top: -40,
          }}
        >
          <div className="flex flex-col items-center ml-[-24px]">
            <span
              style={{ backgroundColor: voiceOfCoachDark }}
              className="text-center p-8 px-12 rounded body-l text-white"
            >
              {t('assessment.postAssessment.breakdown.you')}
            </span>
            <div style={{ backgroundColor: voiceOfCoachDark }} className="text-center w-4 h-32 align-center" />
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div
            className="mr-2 w-1/3 h-[22px]"
            style={{
              backgroundColor: barOneBgColor,
              boxShadow: `${barOneActive ? `0px 0px 5px 0 ${convertHexToRGBA(barOneBgColor, 0.2)}` : ''}`,
            }}
          ></div>
          <div
            className="mx-2 w-1/3 h-[22px]"
            style={{
              backgroundColor: barTwoBgColor,
              boxShadow: `${barTwoActive ? `0px 0px 5px 0 ${convertHexToRGBA(barTwoBgColor, 0.2)}` : ''}`,
            }}
          ></div>
          <div
            className="ml-2 w-1/3 h-[22px]"
            style={{
              backgroundColor: barThreeBgColor,
              boxShadow: `${barThreeActive ? `0px 0px 5px 0 ${convertHexToRGBA(barThreeBgColor, 0.2)}` : ''}`,
            }}
          ></div>
        </div>
        <div
          className="flex flex-col border-b-1 border-l-1 border-r-1 justify-between px-12 tablet-and-up:px-24 pb-12 tablet-and-up:pb-24 rounded-b-24"
          style={{
            borderColor: `${drawerBorderColor}`,
            background: `linear-gradient(0deg, ${drawerBgColor} 0%, rgba(0, 0, 0, 0.22) 100%)`,
          }}
        >
          <div className="flex flex-row w-full pt-12">
            <span className={`text-center text-white pa-body-label-${barOneActive ? 'active' : 'inactive'} mr-2 w-1/3`}>
              {t('assessment.postAssessment.breakdown.development')}
            </span>
            <span className={`text-center text-white pa-body-label-${barTwoActive ? 'active' : 'inactive'} mx-2 w-1/3`}>
              {t('assessment.postAssessment.breakdown.potential')}
            </span>
            <span
              className={`text-center text-white pa-body-label-${barThreeActive ? 'active' : 'inactive'} mx-2 w-1/3`}
            >
              {t('assessment.postAssessment.breakdown.exceptional')}
            </span>
          </div>
          <div className="flex flex-row pt-12 items-center">
            {currentIndex === 1 && <SvgIcon icon={Icons.Electric} size={isMobile ? 60 : 100} />}
            {currentIndex > 1 && <SvgIcon icon={Icons.ElectricWhite} size={100} className="opacity-[.2]" />}
            <div className="flex flex-col w-full">
              <span className="pa-body-coach-tip-s text-white bold">{title}</span>
              <span className="pa-body-s text-white mt-8">{description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
