import { useContext, useEffect, useRef } from 'react';
import { UserContext } from 'contexts/user';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AssessmentIntro } from 'components/assessment-intro';
import VideosContainer from '../videos';
import { LoadingSpinner } from 'components/loaders/LoadingSpinner';
import PostAssessmentComplete from 'containers/complete';
import PostAssessmentFocusAreaSelection from 'components/sections/post-assessment/focus-areas-selection';
import PostAssessmentContainerBreakdown from 'containers/breakdown';
import { JourneyContext } from 'contexts/journey';
import PostAssessmentRecommend from 'containers/recommend';
import PostAssessmentContainerCue from 'containers/cue';
import PostAssessmentContainerOverview from '../overview';
import PostAssessmentNavigation from 'components/sections/post-assessment/navigation';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';
import { PracticesIntro } from 'components/practices-intro';
import AssessmentContainer from 'containers/assessment';
import { ThemeContext } from 'contexts/theme';

function AppRouter(): JSX.Element {
  const { navigate } = useCustomNavigation();
  const location = useLocation();
  const { user, inTeams } = useContext(UserContext);
  const { navState, setNavState } = useContext(JourneyContext);
  const { currentTheme } = useContext(ThemeContext);

  const routedOnLoadRef = useRef<boolean>(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    // we only want to re-route them on initial load
    if (routedOnLoadRef.current) {
      return;
    }

    if (!currentTheme) {
      return;
    }

    const assessmentStatus = user.assessment_statuses[currentTheme.key];
    if (assessmentStatus === 'unstarted' || assessmentStatus === 'started') {
      navigate('/assessments/intro');
      setNavState({
        isBtnVisible: false,
        isOpen: false,
      });
    } else if (assessmentStatus === 'completed') {
      setNavState({
        isBtnVisible: true,
        isOpen: false,
      });
      navigate('/assessments/overview');
    } else if (assessmentStatus === 'focus_area_selected') {
      setNavState({
        isBtnVisible: true,
        isOpen: false,
      });
      navigate('/practices/recommend');
    } else if (assessmentStatus === 'user_practice_created') {
      setNavState({
        isBtnVisible: true,
        isOpen: false,
      });
      navigate('/practices/complete');
    }
    routedOnLoadRef.current = true;
  }, [user, navigate, location, inTeams, setNavState, navState, currentTheme]);

  return (
    <>
      <PostAssessmentNavigation />
      <Routes>
        <Route path="videos" element={<VideosContainer />} />
        <Route path="assessments">
          <Route index element={<AssessmentIntro />} />
          <Route path="intro" element={<AssessmentIntro />} />
          <Route path="questions" element={<AssessmentContainer />} />
          <Route path="overview" element={<PostAssessmentContainerOverview />} />
          <Route path="breakdown" element={<PostAssessmentContainerBreakdown />} />
          <Route path="focus-area-selection" element={<PostAssessmentFocusAreaSelection />} />
        </Route>
        <Route path="practices">
          <Route index element={<PracticesIntro />} />
          <Route path="recommend" element={<PostAssessmentRecommend />} />
          <Route path="cue" element={<PostAssessmentContainerCue />} />
          <Route path="complete" element={<PostAssessmentComplete />} />
        </Route>
      </Routes>
    </>
  );
}

function AppRouterContainer(): JSX.Element {
  const { loading: userLoading } = useContext(UserContext);
  const { loading: journeyLoading } = useContext(JourneyContext);
  const { currentTheme } = useContext(ThemeContext);

  return userLoading || journeyLoading ? <LoadingSpinner /> : <AppRouter key={currentTheme?.key} />;
}

export default AppRouterContainer;
