import { ThemeContext } from 'contexts/theme';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { STATS_CONTENT } from 'services/static-content/stats';

export const StatsSection = () => {
  const { t } = useTranslation();
  const { currentTheme } = useContext(ThemeContext);

  if (!currentTheme) {
    return <></>;
  }

  const title = STATS_CONTENT[currentTheme.key].title;
  const stats = STATS_CONTENT[currentTheme.key].stats;

  return (
    <>
      {currentTheme && title && stats && (
        <section
          className="w-full flex flex-row items-center p-24 border-y-1 mobile:flex-col bg-white"
          data-testid="stats-section"
        >
          <div className="title pr-[24px] mobile:pr-0 mobile:pb-[24px]">
            <p
              className="font-soehne heading-xs w-[max-content] mobile:w-auto"
              dangerouslySetInnerHTML={{ __html: `${t(title)}` }}
            />
          </div>
          <div className="stats w-full flex flex-row items-center justify-center gap-y-[16px] mobile:items-start">
            {stats.map((stat, i) => {
              return (
                <div
                  className={`stat flex flex-row items-center gap-[8px] px-[24px] ${
                    i === 0 ? 'mobile:pl-0' : 'border-l-1'
                  } mobile:flex-col mobile:items-start mobile:px-[12px] ${i + 1 === stats.length ? 'mobile:pr-0' : ''}`}
                  key={`stat-${i + 1}-${stat.description}`}
                  data-testid="stat"
                >
                  <p
                    className={`font-soehne heading-xl text-[56px] flex flex-row ${
                      stat.unit === '%' ? 'items-start' : 'items-end'
                    } mobile:heading-l`}
                  >
                    {stat.stat}
                    {stat.unit && <span className="unit heading-s leading-none ml-4">{stat.unit}</span>}
                  </p>
                  <p className="body-xxs">{t(`${stat.description}`)}</p>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};
