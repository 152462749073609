import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getWelcomeContentUrl } from 'services/service-urls';

export interface WelcomeStep {
  type: string;
  media_url: string;
  media_type: string;
  headline: string | undefined;
  body: string | undefined;
  order: number;
  poster_url: string;
}

export interface WelcomeContent {
  heading: string;
  message: string;
  start_heading: string;
  start_message: string;
  steps: Array<WelcomeStep>;
}

export const getWelcomeContent = async (baseUrl: string = baseUrlConfig): Promise<WelcomeContent> => {
  const res = await axios.get(getWelcomeContentUrl(baseUrl), getDefaultAxiosConfig());
  const unorderedSteps = res.data.welcome_content.steps;
  const orderedSteps = unorderedSteps.sort((a: WelcomeStep, b: WelcomeStep) => a.order - b.order);
  res.data.welcome_content.steps = orderedSteps;

  return res.data.welcome_content;
};
