import { JourneyContext } from 'contexts/journey';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from 'services/analytics-service';

const useCustomNavigation = () => {
  const navigate = useNavigate();
  const { navigationItems } = useContext(JourneyContext);

  const trackScreenView = (pageName: string) => {
    const navigationItem = navigationItems.find((item) => item.url === pageName);
    trackEvent({
      eventName: 'Site navigated',
      eventProperties: {
        component: 'post-assessment-navigation-menu',
        element: navigationItem?.name,
        path: pageName,
      },
    });
  };

  const navigator = (screenName: string, params?: object) => {
    trackScreenView(screenName);
    navigate(screenName, params);
  };

  return {
    navigate: navigator,
  };
};

export default useCustomNavigation;
