import { Icons } from 'assets/icons';
import { Button, ButtonVariants } from 'components/button';
import { ProgressBar } from 'components/progress-bar';
import { useTranslation } from 'react-i18next';
import { ProgressDots } from 'components/progress-dots';

export enum NavigationStyle {
  BAR = 'BAR',
  DOTS = 'DOTS',
}

interface NavigationProps {
  /**
   * Inverted colors
   */
  inverted?: boolean;
  /**
   * Title
   */
  title?: string;
  /**
   * Current step
   */
  currentStep: number;
  /**
   * Total steps
   */
  totalSteps: number;
  /**
   * Navigation style [bar or dots]
   */
  NavigationStyleType?: NavigationStyle;
  /**
   * user's selected index
   */
  response?: number | string;
  /**
   * Can the question be skipped
   */
  is_skippable: boolean;
  /**
   * Optional back button click handler
   */
  onBackButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional forward button click handler
   */
  onForwardButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const Navigation = ({
  inverted,
  title,
  currentStep,
  totalSteps,
  NavigationStyleType = NavigationStyle.BAR,
  response,
  is_skippable,
  onBackButtonClick,
  onForwardButtonClick,
}: NavigationProps) => {
  const { t } = useTranslation();
  const backButtonDisabled = currentStep === 0;
  const forwardButtonDisabled = !response && !is_skippable;
  const invertedText = inverted ? 'text-inverted' : '';
  const invertedIcon = inverted ? 'stroke-white' : '';
  const invertedProgressBarBackground = inverted ? 'bg-gray-80' : 'bg-white';
  const invertedProgressBarValueBackground = inverted ? 'bg-white' : 'bg-midnight';
  return (
    <div className={`flex items-center align-middle justify-between border-t-1 border-gray-80`}>
      <div className="flex flex-col m-18 gap-4 w-1/2 mobile:w-full mobile:mr-24 desktop:w-1/3">
        <div className="flex items-baseline gap-8">
          {title && <p className={`heading-xs ${invertedText}`}>{title}</p>}
          <p className={`body-xs ${invertedText}`}>{`${currentStep + 1}/${totalSteps}`}</p>
        </div>
        {NavigationStyleType === NavigationStyle.BAR ? (
          <ProgressBar
            backgroundColor={invertedProgressBarBackground}
            currentStepBackgroundColor={invertedProgressBarValueBackground}
            currentStep={currentStep}
            maxSteps={totalSteps}
            className="h-8"
          />
        ) : (
          <ProgressDots
            backgroundColor={invertedProgressBarBackground}
            currentStepBackgroundColor={invertedProgressBarValueBackground}
            currentStep={currentStep}
            maxSteps={totalSteps}
            className="h-8 mt-4"
          />
        )}
      </div>
      <div className="flex gap-32 m-18">
        {onBackButtonClick ? (
          <Button
            variant={ButtonVariants.Icon}
            label={
              backButtonDisabled ? t('assessment.navigation.backButtonDisabled') : t('assessment.navigation.backButton')
            }
            icon={Icons.CaretLeft}
            iconSize={18}
            iconColor={invertedIcon}
            className="h-24 w-24 hover:cursor-pointer disabled:cursor-auto disabled:opacity-50 stroke-white"
            disabled={backButtonDisabled}
            onClick={onBackButtonClick}
          />
        ) : (
          <></>
        )}
        {onForwardButtonClick ? (
          <Button
            variant={ButtonVariants.Icon}
            label={
              forwardButtonDisabled
                ? t('assessment.navigation.forwardButtonDisabled')
                : t('assessment.navigation.forwardButton')
            }
            icon={Icons.CaretRight}
            iconSize={18}
            iconColor={invertedIcon}
            className="h-24 w-24 hover:cursor-pointer disabled:cursor-auto disabled:opacity-50"
            disabled={forwardButtonDisabled}
            onClick={onForwardButtonClick}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
