import * as Sentry from '@sentry/react';
import { useState, useEffect, useContext, useCallback } from 'react';
import {
  AssessmentProps,
  AssessmentResponses,
  getCurrentAssessment,
  updateAssessmentResponse,
} from 'services/assessment-service';
import Question from 'containers/assessment/question';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'services/analytics-service';
import { useNavigate } from 'react-router-dom';
import { JourneyContext } from 'contexts/journey';
import { UserContext } from 'contexts/user';
import { ThemeContext } from 'contexts/theme';

export default function AssessmentContainer() {
  const navigate = useNavigate();
  const { loadInsights, loadPractices, setNavState } = useContext(JourneyContext);
  const { reloadUser } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const assessmentConfig = currentTheme?.assessment_configuration;
  const { t } = useTranslation();
  const [assessment, setAssessment] = useState<AssessmentProps>();
  const [responses, setResponses] = useState<AssessmentResponses>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const videoPlayingContext = useContext(ThemeContext);
  const onChange = (questionKey: string, questionValue: string) => {
    if (assessment && assessmentConfig) {
      const lastQuestionIndex = assessmentConfig.questions.length - 1;
      const isEndOfAssessment = currentQuestionIndex === lastQuestionIndex || assessment.submitted_at === '';

      updateAssessmentResponse(assessment.id, questionKey, questionValue, isEndOfAssessment)
        .then((res) => {
          setResponses(res);
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          if (isEndOfAssessment) {
            loadInsights();
            loadPractices();
            reloadUser();
            setNavState({
              isBtnVisible: true,
              isOpen: false,
            });
            navigate('/assessments/overview');
            trackEvent({
              eventName: 'Assessment submitted',
              eventProperties: {
                questionKey: questionKey,
                assessmentId: assessment.id,
                isVideoPlaying: videoPlayingContext.isVideoPlaying,
              },
            });
          }
          trackEvent({
            eventName: 'Assessment item submitted',
            eventProperties: {
              questionKey: questionKey,
              assessmentId: assessment.id,
              isVideoPlaying: videoPlayingContext.isVideoPlaying,
            },
          });
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  };

  /*
   * Navigation back button click handler
   */
  const onBackButtonClick = () => {
    if (0 < currentQuestionIndex) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  /*
   * Navigation forward button click handler
   */
  const onForwardButtonClick = () => {
    if (assessmentConfig && currentQuestionIndex < assessmentConfig.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const currentQuestion = assessmentConfig?.questions[currentQuestionIndex] || {
    question_key: '',
    options: [],
    prompt: '',
    body: '',
    is_skippable: false,
  };
  const response = responses ? responses[currentQuestion.question_key] : '';
  const formattedCurrentQuestion = {
    assessmentId: assessment ? assessment.id : 0,
    questionKey: currentQuestion.question_key,
    options: currentQuestion.options,
    heading: currentQuestion.prompt,
    is_skippable: currentQuestion.is_skippable,
    subheading: t('assessment.questionPrompt'),
    response,
  };

  const loadAssessment = useCallback(() => {
    if (currentTheme) {
      getCurrentAssessment(currentTheme.key)
        .then((res) => {
          setAssessment(res);
          setResponses(res.responses);
          const isComplete = res.submitted_at !== null && res.submitted_at !== '';
          if (!isComplete && assessmentConfig) {
            const questionCount = assessmentConfig.questions.length;
            const responseKeys = Object.keys(res.responses);
            const nextQuestionIndex = responseKeys.length;
            if (nextQuestionIndex !== 0 && nextQuestionIndex < questionCount) {
              setCurrentQuestionIndex(nextQuestionIndex);
            }
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, [assessmentConfig, currentTheme]);

  useEffect(() => {
    loadAssessment();
  }, [loadAssessment]);

  return (
    <div
      className={`flex relative justify-center items-center bg-midnight p-0 w-full h-full no-action border-0 rounded-none justify-center min-h-[inherit] max-h-896`}
    >
      {assessmentConfig && assessment && (
        <Question
          onChange={onChange}
          {...formattedCurrentQuestion}
          inverted={true}
          className={`flex`}
          currentStep={currentQuestionIndex}
          totalSteps={assessmentConfig.questions.length}
          onBackButtonClick={onBackButtonClick}
          onForwardButtonClick={onForwardButtonClick}
        />
      )}
    </div>
  );
}
