import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';

export enum ButtonVariants {
  Primary = 'button-primary',
  PrimaryInverted = 'button-primary-inverted',
  Secondary = 'button-secondary',
  SecondaryInverted = 'button-secondary-inverted',
  Tertiary = 'button-tertiary',
  TertiaryInverted = 'button-inverted-tertiary',
  Icon = 'button-icon',
  IconInverted = 'button-icon-inverted',
  Text = 'button-text',
  TextInverted = 'button-inverted-text',
}

interface ButtonProps {
  /**
   * Button variant type
   */
  variant?: ButtonVariants;
  /**
   * Button aria-label
   */
  label: string;
  /**
   * test id
   */
  testId?: string;
  /**
   * Button text
   */
  text?: string;
  /**
   * Classes list
   */
  className?: string;
  /**
   * Disabled state
   */
  disabled?: boolean;
  /**
   * Icon to display
   */
  icon?: Icons;
  /**
   * Size of icon
   */
  iconSize?: number;
  /**
   * Icon render on left of text
   */
  iconLeft?: boolean;
  /**
   * Icon color see tailwind docs https://tailwindcss.com/docs/fill e.g. stroke-white
   */
  iconColor?: string;
  /**
   * Button type
   */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  /**
   * Optional children
   */
  children?: JSX.Element;
  /**
   * Optional click handler
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional control the tab index of the button
   */
  tabIndex?: number;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  variant = ButtonVariants.Primary,
  label,
  testId,
  text,
  className = '',
  disabled = false,
  icon,
  iconSize = 16,
  iconLeft = false,
  iconColor,
  type = 'button',
  children,
  onClick,
  tabIndex,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`${disabled ? '' : 'cursor-pointer'} ${variant} ${className}`}
      disabled={disabled}
      aria-label={label}
      data-testid={testId ? testId : 'button'}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {icon ? (
        <span className={`flex items-center ${iconLeft ? 'flex-row-reverse' : ''}`}>
          {text}
          <SvgIcon
            icon={icon}
            size={iconSize}
            className={`${text ? (iconLeft ? 'mr-8' : 'ml-8') : 'm-0'} ${iconColor}`}
          />
        </span>
      ) : (
        <span>{text}</span>
      )}
      {children}
    </button>
  );
};
