import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { useTranslation } from 'react-i18next';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';
import { useContext } from 'react';
import { JourneyContext } from 'contexts/journey';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import useElementSize from 'utils/hooks/useElementSize';

export default function PostAssessmentNavigation() {
  const { navigate } = useCustomNavigation();
  const { pathname } = useLocation();
  const { navigationItems, navState, setNavState } = useContext(JourneyContext);
  const { t } = useTranslation();
  const [ref, { isMobile }] = useElementSize();

  const go = (page: string) => {
    navigate(page);
    setNavState({
      isBtnVisible: true,
      isOpen: false,
    });
  };

  const open = () => {
    setNavState({
      isBtnVisible: true,
      isOpen: true,
    });
  };

  const close = () => {
    setNavState({
      isBtnVisible: true,
      isOpen: false,
    });
  };

  return (
    <div data-testid="postAssessmentNavigation" ref={ref}>
      {navState.isBtnVisible && (
        <button
          className="bg-transparent p-0 absolute z-100 top-36 left-32 desktop-large:left-90 hover:cursor-pointer"
          onClick={open}
          aria-label={`${t('assessment.postAssessment.navigation.open')}`}
        >
          <SvgIcon icon={Icons.Hamburger} size={isMobile ? 24 : 30} className="text-white" />
        </button>
      )}
      <CSSTransition timeout={500} classNames="fade" in={navState.isOpen} mountOnEnter unmountOnExit>
        <div
          className="absolute top-0 left-0 z-100 z-0 w-full h-full"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.90)',
          }}
        >
          <div className="px-32 pt-32 desktop-large:pl-90">
            <button
              className="bg-transparent p-0 ml-[-3px]"
              onClick={close}
              tabIndex={0}
              autoFocus
              aria-label={`${t('assessment.postAssessment.navigation.close')}`}
            >
              <SvgIcon icon={Icons.X} size={30} className="text-white cursor-pointer" />
            </button>
            <div className="flex flex-col w-full">
              <h1
                className={`border-b-1 text-[24px] font-ivar font-normal tracking-[1px] border-white desktop:w-1/3 py-20 pa-heading-s text-white`}
              >
                {t('assessment.postAssessment.navigation.navigateYourJourney')}
              </h1>
              {navigationItems.map((section, index) => {
                return (
                  <button
                    key={`nav-button-${index}`}
                    onClick={() => go(section.url)}
                    className="bg-transparent p-0 m-0 mt-36 cursor-pointer"
                    aria-label={section.name}
                  >
                    <h1
                      className={`text-left font-normal cursor-pointer pb-20 text-white mobile:text-[34px] pa-body-xl ${
                        pathname === `/${section.url}` ? 'text-white' : 'text-gray'
                      }`}
                    >
                      {section.name}
                    </h1>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}
