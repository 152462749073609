import { Icons } from 'assets/icons';
import { PracticeSummaryCard, VARIANTS } from 'components/practice/practice-summary-card';
import { PracticeSummaryCardHint } from 'components/practice/practice-summary-card-hint';
import { SvgIcon } from 'components/svg-icon';
import { Videoplayer } from 'components/videoplayer';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AssessmentInsightsProps } from 'utils/assessment';
import { InvertedProps } from 'utils/components';
import { PracticeExerciseType } from 'utils/post-assessment-types';
import { JourneyContext } from 'contexts/journey';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';
import useElementSize from 'utils/hooks/useElementSize';
import { useSwipeable } from 'react-swipeable';

interface CarouselProps extends InvertedProps {
  /**
   * array of exercises
   */
  practices?: PracticeExerciseType[];
  /**
   * callback when video is opened
   */
  revealVideoCb: (isVideoOpen: boolean) => void;
  /**
   * @insights AssessmentInsightsProps object containing the alluvials and other insight data
   */
  insights: AssessmentInsightsProps | undefined;
  /**
   * whether to show the hints
   */
  showHints: boolean;
  variant?: VARIANTS;
  gotoExplore?: (pageName: string) => void;
}

interface OtherPracticesType {
  id: string;
  label: string;
  title: string;
}

/**
 * Primary UI component for user interaction
 */
export const Carousel = ({
  inverted,
  practices,
  revealVideoCb,
  insights,
  showHints,
  gotoExplore,
  variant,
}: CarouselProps) => {
  const { navigate } = useCustomNavigation();
  const { t, i18n } = useTranslation();
  let textTrackLanguage;
  if (i18n.resolvedLanguage !== 'en') {
    textTrackLanguage = i18n.resolvedLanguage;
  }

  const otherPracticesDefault = useMemo(
    () =>
      insights &&
      (insights.dimensions.map((dimension) => {
        return { id: dimension.key, label: dimension.content.title, title: dimension.content.hint };
      }) as OtherPracticesType[]),
    [insights]
  );

  const { practiceExercise, setPracticeExercise } = useContext(JourneyContext);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [otherPractices, setOtherPractices] = useState<OtherPracticesType[] | undefined>(otherPracticesDefault);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [ref, { isMobile }] = useElementSize();

  const numberOfHints = showHints && otherPractices ? otherPractices && otherPractices.length : 0;
  const totalSteps = (practices && practices.length + numberOfHints) || 0;

  const transitionDuration = 650;
  const practiceKeys =
    practices &&
    practices.map((x) => {
      return x.key;
    });

  const onNext = useCallback(() => {
    if (currentIndex < totalSteps - 1) {
      const newCurrentIndex = currentIndex + 1;
      setCurrentIndex(newCurrentIndex);
    }
  }, [currentIndex, totalSteps]);

  const onPrevious = useCallback(() => {
    if (0 < currentIndex) {
      const newCurrentIndex = currentIndex - 1;
      setCurrentIndex(newCurrentIndex);
    }
  }, [currentIndex]);

  const gotoItem = (newCurrentIndex: number) => {
    setCurrentIndex(newCurrentIndex);
  };

  const onVideoComplete = () => {
    navigate('/practices/cue');
  };

  const revealVideo = (practiceExercise: PracticeExerciseType) => {
    setPracticeExercise(practiceExercise);
    setShowVideo(true);
    revealVideoCb(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    revealVideoCb(false);
  };

  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onPrevious();
      } else if (event.key === 'ArrowRight') {
        onNext();
      }
    },
    [onPrevious, onNext]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvents);
    return () => document.removeEventListener('keydown', handleKeyboardEvents);
  }, [handleKeyboardEvents]);

  useEffect(() => {
    if (practices) {
      // check which focus areas the practices are missing, for those that are missing reveal hidden hint cards
      const practiceKeys = practices.map((practice, index) =>
        practice.key.substring(0, practice.key.length - 2).toLowerCase()
      );
      const filteredArray =
        otherPracticesDefault && otherPracticesDefault.filter((obj) => !practiceKeys.includes(obj.id));
      filteredArray && setOtherPractices(filteredArray);
    }
  }, [practices, otherPracticesDefault]);

  const handlers = useSwipeable({
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    onSwipedLeft: onNext, // After LEFT swipe  (SwipeEventData) => void
    onSwipedRight: onPrevious, // After RIGHT swipe (SwipeEventData) => void
  });

  const position = -((isMobile ? 188 : 288) + (isMobile ? 20 : 36)) * currentIndex;
  const hasPagination = false;

  return (
    <>
      <div className="flex flex-col ml-8 mb-28 desktop:mb-0 desktop:ml-0" ref={ref}>
        <div
          {...handlers}
          className="flex relative ml-20 desktop:ml-88"
          style={{
            transition: `left ${transitionDuration}ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
            left: position,
          }}
        >
          {practices &&
            practices.map((practiceExercise, index) => {
              return (
                <PracticeSummaryCard
                  variant={variant}
                  inverted={inverted}
                  key={`practice-summary-card-${index}`}
                  practiceExercise={practiceExercise}
                  active={index === currentIndex}
                  recommended={true}
                  onClickContinue={() => revealVideo(practiceExercise)}
                  onClick={() => gotoItem(index)}
                  closeVideo={!showVideo}
                />
              );
            })}
          {showHints &&
            otherPractices &&
            otherPractices.map((otherPractice, index) => {
              return (
                <PracticeSummaryCardHint
                  key={`practice-hint-${index}`}
                  id={otherPractice.id}
                  label={otherPractice.label}
                  title={otherPractice.title}
                  active={(practices ? practices.length : 0) + index === currentIndex}
                  onClick={() => gotoItem((practices ? practices.length : 0) + index)}
                  onClickButton={gotoExplore}
                />
              );
            })}
        </div>

        {hasPagination && (
          <div className="flex flex-row mt-24 ml-32 desktop:ml-90">
            <button
              aria-label={`${t('buttons.previous')}`}
              onClick={onPrevious}
              className="flex justify-center cursor-pointer items-center w-72 h-72 rounded-full bg-white mr-16 hover:bg-gray"
            >
              <SvgIcon icon={Icons.CaretLeft} size={28} className="text-midnight" />
            </button>
            <button
              aria-label={`${t('buttons.next')}`}
              onClick={onNext}
              className="flex justify-center cursor-pointer items-center w-72 h-72 rounded-full bg-white mr-16 hover:bg-gray"
            >
              <SvgIcon icon={Icons.CaretRight} size={28} className="text-midnight" />
            </button>
          </div>
        )}
      </div>
      {showVideo && practiceExercise && (
        <Videoplayer
          insights={insights}
          onComplete={showHints ? undefined : onVideoComplete}
          video={practiceExercise.video}
          closeVideo={closeVideo}
          textTrackLanguage={textTrackLanguage}
          eventIdentifier={'Practice Video'}
          analyticsData={{
            practiceKey: practiceExercise.key,
            practiceTitle: practiceExercise.title,
            practicePosition: currentIndex,
            practicesPresented: practiceKeys,
          }}
        />
      )}
    </>
  );
};
