import { useContext, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from './lottie-loader.json';
import { CSSTransition } from 'react-transition-group';
import { LoadingSpinner } from 'components/loaders/LoadingSpinner';
import { UserContext } from 'contexts/user';

interface PostAssessmentLoadingProps {
  inverted?: boolean;
  description?: string;
  duration?: number;
  onTimeout?: () => void;
}

// loader for the entire post assessment.  appears after the final question of the assessment is answered
export function PostAssessmentLoading({ inverted, description, duration = 0, onTimeout }: PostAssessmentLoadingProps) {
  const { inTeams } = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => {
      onTimeout && onTimeout();
    }, duration);
  }, [duration, onTimeout]);

  return (
    <CSSTransition timeout={500} classNames="fade" in={true} mountOnEnter unmountOnExit>
      <>
        {inTeams ? (
          <LoadingSpinner />
        ) : (
          <div
            className={`absolute top-0 z-[100] bg-black w-full h-full flex flex-col items-center justify-center`}
            data-testid="landing-loading"
          >
            <div
              className="absolute mt-[-200px] flex flex-col items-center"
              style={{
                background: `linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.22) 100%)`,
              }}
            >
              <Lottie animationData={animationData} className="mt-200 flex w-180 h-180" />
              {description && (
                <p className={`pa-body ${inverted ? 'text-white' : 'text-black'} text-center w-1/2 m-auto`}>
                  {description}
                </p>
              )}
            </div>
          </div>
        )}
      </>
    </CSSTransition>
  );
}
