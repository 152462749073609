import { AssessmentInsightsProps } from 'utils/assessment';
import { convertHexToRGBA } from 'utils/helpers';

interface BreakdownGlowsProps {
  insights: AssessmentInsightsProps;
  currentIndex: number;
}
export default function BreakdownGlows({ insights, currentIndex }: BreakdownGlowsProps) {
  const transitionTime = 500;
  return (
    <div className="glows z-0 absolute top-0 left-0 w-full h-full">
      {insights &&
        insights.dimensions.map((alluvial, index) => {
          return (
            <div
              key={`alluvial-glow-${alluvial.id}`}
              className="w-full h-full top-0 absolute"
              style={{
                transition: `opacity ${transitionTime}ms ease-in`,
                opacity: `${currentIndex === index + 1 ? '1' : '0'}`,
                transform: `scale(${(alluvial.score / 100) * 2.5} )`,
                top: (alluvial.score / 100) * -600,
                transformOrigin: 'top',
                background: `radial-gradient(58.32% 58.32% at 50% 41.68%,
                      ${convertHexToRGBA(alluvial.content.color_gradient.light, 0.35)} 0%,
                      ${convertHexToRGBA(alluvial.content.color_gradient.light, 0.35)} 50%,
                      ${convertHexToRGBA(alluvial.content.color_gradient.light, 0)} 85%)`,
              }}
            />
          );
        })}
      <div
        className="w-full h-full"
        style={{
          transition: `opacity ${transitionTime}ms ease-in`,
          opacity: `${currentIndex === 5 ? '.8' : '0'}`,
          position: 'absolute',
        }}
      >
        <div className="flex flex-row flex-wrap w-full h-full">
          <div
            className="absolute"
            style={{
              top: '-140%',
              left: '-140%',
              width: '240%',
              height: '240%',
              background: `radial-gradient(58.32% 58.32% at 50% 41.68%, 
                ${convertHexToRGBA(insights.dimensions[0].content.color_gradient.light, 0.35)} 0%,
                ${convertHexToRGBA(insights.dimensions[0].content.color_gradient.light, 0.35)} 50%,
                ${convertHexToRGBA(insights.dimensions[0].content.color_gradient.light, 0)} 85%)`,
            }}
          />
          <div
            className="absolute"
            style={{
              top: '-140%',
              right: '-140%',
              width: '240%',
              height: '240%',
              background: `radial-gradient(58.32% 58.32% at 50% 41.68%, 
                ${convertHexToRGBA(insights.dimensions[1].content.color_gradient.light, 0.35)} 0%,
                ${convertHexToRGBA(insights.dimensions[1].content.color_gradient.light, 0.35)} 50%,
                ${convertHexToRGBA(insights.dimensions[1].content.color_gradient.light, 0)} 85%)`,
            }}
          />
          <div
            className="absolute"
            style={{
              bottom: '-140%',
              left: '-140%',
              width: '240%',
              height: '240%',
              background: `radial-gradient(58.32% 58.32% at 50% 41.68%, 
                ${convertHexToRGBA(insights.dimensions[2].content.color_gradient.light, 0.35)} 0%,
                ${convertHexToRGBA(insights.dimensions[2].content.color_gradient.light, 0.35)} 50%,
                ${convertHexToRGBA(insights.dimensions[2].content.color_gradient.light, 0)} 85%)`,
            }}
          />
          <div
            className="absolute"
            style={{
              bottom: '-140%',
              right: '-140%',
              width: '240%',
              height: '240%',
              background: `radial-gradient(58.32% 58.32% at 50% 41.68%, 
                ${convertHexToRGBA(insights.dimensions[3].content.color_gradient.light, 0.35)} 0%,
                ${convertHexToRGBA(insights.dimensions[3].content.color_gradient.light, 0.35)} 50%,
                ${convertHexToRGBA(insights.dimensions[3].content.color_gradient.light, 0)} 85%)`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
