import { User } from 'services/user-service';
import axios from 'axios';
import { baseUrlConfig, getExposureEventTrackUrl } from 'services/service-urls';
import { getDefaultAxiosConfig } from 'services/service-defaults';
export interface Experiments {
  [key: string]: Experiment;
}

interface Experiment {
  value: string;
  payload?: object;
}

export const isExperimentEnabled = (user: User, experimentName: string): boolean => {
  if (user && user.experiments && user.experiments[experimentName]) {
    triggerExposureEvent(experimentName, user.experiments[experimentName].value);
    return user.experiments[experimentName].value === 'treatment';
  }
  return false;
};

export const triggerExposureEvent = async (flag: string, variant: string): Promise<void> => {
  await axios.post(
    getExposureEventTrackUrl(baseUrlConfig),
    {
      flag,
      variant,
    },
    getDefaultAxiosConfig()
  );
};
