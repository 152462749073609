import { PostAssessmentLoading } from 'components/sections/post-assessment/loading';
import { Gradient } from 'components/gradient';
import { CarouselFilters } from 'components/carousel-filters';
import { useContext } from 'react';
import { JourneyContext } from 'contexts/journey';

export default function PostAssessmentRecommend() {
  const { insights, allPractices, focusArea } = useContext(JourneyContext);

  return (
    <>
      {!insights && !allPractices && <PostAssessmentLoading inverted={true} description={''} duration={0} />}
      {insights && allPractices && (
        <div className="relative min-h-[inherit] h-full bg-black">
          <div
            className="flex flex-col relative z-1 w-full h-full min-h-896 mobile:min-h-1024 overflow-x-hidden"
            data-testid="recommendedPractices"
          >
            <div className="p-32 desktop:pl-90 mt-40 desktop:mt-0 desktop-large:mt-48">
              <h1 className={`pa-heading-xl text-white mb-12 desktop:mb-12`}>{allPractices.title}</h1>
              <h2 className={`pa-body text-white desktop:w-3/4`}>{allPractices.description}</h2>
            </div>
            <CarouselFilters
              alluvials={insights.dimensions}
              practices={allPractices.practices}
              selectedAlluvial={
                focusArea
                  ? focusArea.alluvial_key
                  : insights.last_focus_area
                  ? insights.last_focus_area
                  : allPractices.practices[0].alluvial_key
              }
            />
          </div>
          <Gradient />
        </div>
      )}
    </>
  );
}
