import axios from 'axios';
import { AssessmentConfiguration } from 'services/assessment-service';
import { Playbook } from 'services/playbook-service';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getThemesUrl } from 'services/service-urls';

export interface EpisodeType {
  name: string;
  publish_date: string;
  end_date: string;
  video: VideoType;
  playbook: Playbook;
  theme_title: string;
}

export interface VideoType {
  id: number;
  title: string;
  description: string;
  image_url: string;
  mobile_image_url: string;
  card_image_url: string;
  vimeo_id: string;
  preview_loop_url: string;
}

export interface Theme {
  id: number;
  title: string;
  key: string;
  current: boolean;
  start_date: string;
  end_date: string;
  assessment_configuration: AssessmentConfiguration;
  episodes: Array<EpisodeType>;
}

export const getThemes = async (baseUrl: string = baseUrlConfig): Promise<Theme[]> => {
  const res = await axios.get(getThemesUrl(baseUrl), getDefaultAxiosConfig());
  return res.data.themes;
};
