import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getMagicLinkUrl, getNewUserUrl } from 'services/service-urls';

export interface ParameterError {
  name: string;
  message: string;
}

export interface SignInParams {
  user: {
    email: string;
  };
}

export interface NewUserParams {
  user: {
    email: string;
    first_name: string;
    accepted_terms_at: string;
  };
}

export interface LoginStatus {
  code: number;
  message: string;
  errors: [string[]];
}

export const customValidateStatus = {
  validateStatus: (status: number) => {
    return (status >= 200 && status < 300) || status === 404 || status === 422;
  },
};

export const createUserSignIn = async (params: SignInParams, baseUrl: string = baseUrlConfig): Promise<LoginStatus> => {
  const res = await axios.post(getMagicLinkUrl(baseUrl), params, {
    ...customValidateStatus,
    ...getDefaultAxiosConfig(),
  });
  return res.data.status as LoginStatus;
};

export const createNewUser = async (params: NewUserParams, baseUrl: string = baseUrlConfig): Promise<LoginStatus> => {
  const res = await axios.post(getNewUserUrl(baseUrl), params, {
    ...customValidateStatus,
    ...getDefaultAxiosConfig(),
  });
  return res.data.status as LoginStatus;
};
