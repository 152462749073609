import * as THREE from 'three';
import React, { useRef } from 'react';
import useElementSize from 'utils/hooks/useElementSize';

type ShaderProps = {
  width: number;
  height: number;
};

const MyMesh = ({ width, height }: ShaderProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (canvasRef.current) {
      const MyShader = {
        uniforms: {
          iTime: { value: 0 },
          iResolution: { value: new THREE.Vector2() },
        },
        vertexShader: `
      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
        fragmentShader: `
        uniform vec2 iResolution;
        uniform float iTime;

        // Function to interpolate between two values using smoothstep function
        #define S(a,b,t) smoothstep(a,b,t)

        // Function to rotate a 2D point around origin by an angle
        mat2 Rot(float a)
        {
            float s = sin(a);
            float c = cos(a);
            return mat2(c, -s, s, c);
        }

        // Function to generate a random value based on a 2D point
        vec2 hash(vec2 p)
        {
            // Use dot product to calculate two values
            p = vec2(dot(p, vec2(2127.1, 81.17)), dot(p, vec2(0269.5, 283.37)));
            // Use sin and fract functions to generate a random value
            return fract(sin(p) * 43758.5453);
        }

        // Function to generate 2D Perlin noise based on a 2D point
        float noise(in vec2 p)
        {
            vec2 i = floor(p);
            vec2 f = fract(p);
        
            // Calculate smooth interpolation values for each of the four corners of a grid cell
            vec2 u = f * f * (3.0 - 2.0 * f);
        
            // Interpolate noise values based on the interpolation values and random values generated from each corner
            float n = mix(mix(dot(-1.0 + 2.0 * hash(i + vec2(0.0, 0.0)), f - vec2(0.0, 0.0)),
                              dot(-1.0 + 2.0 * hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0)), u.x),
                         mix(dot(-1.0 + 2.0 * hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0)),
                              dot(-1.0 + 2.0 * hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0)), u.x), u.y);
            
            // Return value between 0 and 1
            return 0.5 + 0.5 * n;
        }

        void main()
        {
            // Get the normalized coordinates of the current pixel
            vec2 uv = gl_FragCoord.xy / iResolution.xy;

            // Calculate the aspect ratio of the canvas
            float ratio = iResolution.x / iResolution.y;

            // Offset the UV coordinates to be centered at the origin
            vec2 tuv = uv;
            tuv -= 0.5;

            // Generate a rotation angle based on time and the product of the x and y coordinates of the current pixel using noise
            float degree = noise(vec2(iTime * 0.1, tuv.x * tuv.y));

            // Adjust y coordinate to match aspect ratio, rotate coordinates based on degree, and adjust y coordinate again
            tuv.y *= 1.0 / ratio;
            tuv *= Rot(radians((degree - 0.5) * 720.0 + 180.0));
            tuv.y *= ratio;

            // Apply a wave warp to the UV coordinates using sine functions
            // Define frequency, amplitude, and speed values
            float frequency = 5.0;
            float amplitude = 30.0;
            float speed = iTime * 2.0;
            // Add sin waves to x and
            tuv.x += sin(tuv.y * frequency + speed) / amplitude;
            tuv.y += sin(tuv.x * frequency * 1.5 + speed) / (amplitude * 0.5);

            // Define the colors to use in the final composition
            vec3 colorBlack = vec3(0.0, 0.0, 0.0);
            vec3 colorDeepBlue = vec3(0.192, 0.384, 0.933);
            vec3 colorBlue = vec3(0.350, .71, .953);
            
            vec3 layer1 = mix(colorBlack, colorDeepBlue, S(-.3, .2, (tuv*Rot(radians(-5.))).x));
            vec3 layer2 = mix(colorDeepBlue, colorBlue, S(-.3, .2, (tuv*Rot(radians(-5.))).x));
            
            vec3 finalComp = mix(layer1, layer2, S(.5, -.3, tuv.y));
            
            vec3 col = finalComp;
            
            gl_FragColor = vec4(col,1.0);
        }`,
      };

      const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true,
      });
      renderer.setSize(width, height);

      // Create a new scene
      const scene = new THREE.Scene();

      // Create a new camera
      const camera = new THREE.PerspectiveCamera(30, width / height, 0.1, 2000);
      camera.position.z = 1;
      scene.add(camera);

      // Create an ambient light
      const light = new THREE.AmbientLight(0x000000);
      scene.add(light);

      // Create the shader material
      const geometry = new THREE.PlaneGeometry(2, 2);
      const material = new THREE.ShaderMaterial({
        uniforms: MyShader.uniforms,
        vertexShader: MyShader.vertexShader,
        fragmentShader: MyShader.fragmentShader,
      });
      const mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      const animate = () => {
        requestAnimationFrame(animate);

        if (mesh.material.uniforms.iTime) mesh.material.uniforms.iTime.value += 0.01;
        if (mesh.material.uniforms.iResolution) mesh.material.uniforms.iResolution.value.set(width, height);

        renderer.render(scene, camera);
      };

      animate();

      return () => {
        geometry.dispose();
        material.dispose();
        scene.remove(mesh);
        scene.remove(camera);
        scene.remove(light);
      };
    }
  }, [width, height]);

  return <canvas ref={canvasRef} style={{ width, height }} />;
};

export const Gradient = () => {
  const [ref, { width, height }] = useElementSize();
  return (
    <div className="fixed z-0 left-0 top-0 w-full h-full min-h-896 opacity-40 bg-black" ref={ref}>
      <MyMesh width={width} height={height} />
    </div>
  );
};
