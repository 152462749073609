import { useEffect } from 'react';
import * as msal from '@azure/msal-browser';
import * as microsoftTeams from '@microsoft/teams-js';

interface OAuthStartProps {
  needsExpandedPermissions: boolean;
}

export default function OAuthStart({ needsExpandedPermissions }: OAuthStartProps) {
  useEffect(() => {
    microsoftTeams.app.initialize().then(() => {
      microsoftTeams.app.getContext().then(async (context) => {
        const msalConfig: msal.Configuration = {
          auth: {
            clientId: process.env.REACT_APP_MS_APP_ID as string,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            authority: `https://login.microsoftonline.com/${context.user!.tenant!.id}`,
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: 'sessionStorage',
          },
        };

        const msalInstance = new msal.PublicClientApplication(msalConfig);
        const scopes = needsExpandedPermissions
          ? ['Calendars.ReadWrite']
          : ['email', 'offline_access', 'openid', 'profile', 'User.Read'];

        const scopesRequest = {
          scopes: scopes,
          redirectUri: window.location.origin + `/auth-end`,
        };

        await msalInstance.initialize();
        await msalInstance.loginRedirect(scopesRequest);
      });
    });
  }, [needsExpandedPermissions]);

  return <></>;
}
