import Vimeo, { EndEvent, PauseEvent, PlayEvent } from '@u-wave/react-vimeo';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'components/button';
import { LogoImageComponents } from 'assets/images/logos';
import { Icons } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { isExperimentEnabled } from 'services/ab-service';
import { UserContext } from 'contexts/user';
import { trackEvent } from 'services/analytics-service';
import { SvgIcon } from 'components/svg-icon';
import { ThemeContext } from 'contexts/theme';
import useElementSize from 'utils/hooks/useElementSize';
import { EpisodeType } from 'services/themes-service';
/**
 * Primary UI component for user interaction
 */
interface HeroProps {
  episode: EpisodeType | undefined;
  isVideoHidden: boolean;
  setIsVideoHidden: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Hero = ({ episode, isVideoHidden, setIsVideoHidden }: HeroProps) => {
  const { t, i18n } = useTranslation();
  const [experimentEnabled, setExperimentEnabled] = useState<boolean>(false);
  const [videoAppearTimeout, setVideoAppearTimeout] = useState<boolean>(false);
  const [videoErrored, setVideoErrored] = useState<boolean>(false);
  const { user, inTeams } = useContext(UserContext);
  const { setIsVideoPlaying, videoStates, setVideoStates, currentTheme } = useContext(ThemeContext);

  const vimeoId = episode?.video.vimeo_id;
  const description = episode?.video.description;
  const title = episode?.video.title;
  const image_url = episode?.video.image_url;
  const mobile_image_url = episode?.video.mobile_image_url;
  const preview_loop_url = episode?.video.preview_loop_url;
  const [ref, { isMobile }] = useElementSize();
  const posterUrl = isMobile ? mobile_image_url : image_url;

  let textTrackLanguage;
  if (i18n.resolvedLanguage !== 'en') {
    textTrackLanguage = i18n.resolvedLanguage;
  }

  const onPlay = (e: PlayEvent) => {
    setVideoStates({
      practices: {
        isPlay: false,
        isPause: true,
      },
      hero: {
        isPlay: true,
        isPause: false,
      },
    });

    trackEvent({
      eventName: 'Video started',
      eventProperties: {
        videoId: vimeoId,
        timestamp: e.seconds,
      },
    });
  };

  const onError = () => {
    setVideoErrored(true);
  };

  const onPause = (e: PauseEvent) => {
    setVideoStates({
      practices: {
        isPlay: false,
        isPause: true,
      },
      hero: {
        isPlay: false,
        isPause: true,
      },
    });

    // PauseEvent and EndEvent are triggered at the end of the video
    // Don't track a PauseEvent at the end.
    const isAtEndOfVideo = Math.floor(e.seconds) === Math.floor(e.duration);
    if (!isAtEndOfVideo) {
      trackEvent({
        eventName: 'Video stopped',
        eventProperties: {
          videoId: vimeoId,
          timestamp: e.seconds,
        },
      });
    }
  };

  const onEnd = (e: EndEvent) => {
    setIsVideoPlaying(false);
    trackEvent({
      eventName: 'Video completed',
      eventProperties: {
        videoId: vimeoId,
        episodeName: episode?.video.title,
      },
    });
  };

  useEffect(() => {
    user && setExperimentEnabled(isExperimentEnabled(user, 'alternate-video-title'));
  }, [user]);

  useEffect(() => {
    if (!isVideoHidden) {
      setTimeout(() => {
        setVideoAppearTimeout(true);
      }, 500);
    }
  }, [isVideoHidden]);

  useEffect(() => {
    setIsVideoHidden(true);
  }, [currentTheme, setIsVideoHidden]);

  return (
    <div
      data-testid={'hero-section'}
      className={`flex w-full h-full bg-black relative flex w-full overflow-hidden ${isVideoHidden && 'min-h-grow-full'}
      `}
      ref={ref}
    >
      <div
        className={`flex flex-col w-full relative min-h-full justify-end self-end ${
          videoAppearTimeout ? '' : 'min-h-full'
        }`}
      >
        {isVideoHidden ? (
          <>
            <div className="relative justify-end items-end self-center tablet-and-up:justify-center tablet-and-up:self-auto tablet-and-up:items-start min-h-screen flex flex-col text-inverted px-20 py-28 z-2 tablet:pl-80 desktop:pl-120 max-w-640">
              <div>
                <div className="flex mb-24 items-center justify-center tablet-and-up:justify-start">
                  <img
                    src={LogoImageComponents.BetterUpLogo}
                    height="14px"
                    alt={t('loginPage.betterupLogoAndLinkAlt')}
                  />
                  {experimentEnabled ? (
                    <p className="body-xs ml-4">{t('videoPage.hero.pleaseShare')}</p>
                  ) : (
                    <p className="body-xs ml-4">{t('videoPage.hero.presents')}</p>
                  )}
                </div>
                <h1 className="text-[88px] font-ivar font-normal leading-none mb-20 mobile:text-[48px] text-center tablet-and-up:text-left">
                  {title}
                </h1>
                <p className="body-l text-[24px] leading-[1.4] mb-64 mobile:body-m mobile:pr-0 mobile:mb-24 text-center tablet-and-up:text-left">
                  {description}
                </p>
                <div className="flex items-center tablet-and-up:items-baseline flex-col tablet-and-up:flex-row">
                  <Button
                    label={`${t('videoPage.hero.buttonLabel')}`}
                    text={t('videoPage.hero.button')}
                    icon={Icons.PlayIcon}
                    iconSize={24}
                    onClick={() => setIsVideoHidden(false)}
                    className="mb-24 hover:cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="bg-midnight opacity-50 w-full h-full absolute z-1"></div>
            <video
              key={preview_loop_url}
              playsInline
              autoPlay
              muted
              loop
              poster={videoErrored ? posterUrl : undefined}
              className={`absolute object-cover w-full h-full bg-fixed ${inTeams && 'mobile:top-36'}`}
              data-testid="hero-section_video"
              onError={onError}
            >
              <source src={preview_loop_url} type="video/webm" />
              {t('videoPage.hero.videoNotSupported')}
            </video>
          </>
        ) : (
          <div className={`my-auto tablet-and-up:my-0 tablet-and-up:h-full w-full mx-auto`} data-testid="vimeo-hero">
            {vimeoId && (
              <Vimeo
                paused={videoStates.hero.isPause}
                autoplay={true}
                className="h-full w-full"
                onPlay={onPlay}
                onPause={onPause}
                onEnd={onEnd}
                video={vimeoId}
                textTrack={textTrackLanguage}
                responsive
              />
            )}
          </div>
        )}
        {!isVideoHidden && (
          <button
            className="absolute top-12 right-48 z-100 bg-transparent w-48 h-48 flex justify-center items-center cursor-pointer"
            onClick={() => setIsVideoHidden(true)}
          >
            <SvgIcon icon={Icons.X} size={28} className="text-white" />
          </button>
        )}
      </div>
    </div>
  );
};
