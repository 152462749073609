import { useState } from 'react';
import { LogoImageComponents } from 'assets/images/logos';
import { useTranslation } from 'react-i18next';
import { onSignout } from 'utils/session';
import { useContext } from 'react';
import { UserContext } from 'contexts/user';
import { ThemeContext } from 'contexts/theme';
import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { trackEvent } from 'services/analytics-service';
import { Theme } from 'services/themes-service';
import useElementSize from 'utils/hooks/useElementSize';

/**
 * Primary UI component for user interaction
 */
export const Navbar = () => {
  const [ref, { isMobile }] = useElementSize();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { themes, changeCurrentTheme, currentTheme } = useContext(ThemeContext);
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const openNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const themeNavigate = (theme: Theme, context: string) => {
    trackEvent({
      eventName: 'Site navigated',
      eventProperties: {
        component: 'theme-navigation',
        element: theme.title,
        context: context,
      },
    });

    setMobileNavOpen(false);
    changeCurrentTheme(theme);
  };

  return (
    <header
      ref={ref}
      className={`w-full bg-black relative z-100 ${
        isMobile && mobileNavOpen ? 'absolute overflow-hidden h-full top-0 z-100' : ''
      }`}
    >
      <div className="flex w-full max-w-1440 mx-auto py-16 items-center align-middle justify-between">
        <div className="flex flex-row items-center align-middle pl-12 tablet-and-up:pl-24">
          <img src={LogoImageComponents.BetterUpLogo} height="14px" alt={t('navbar.betterupLogoAlt')} />
          <span className="w-1 h-20 bg-gray mx-16"></span>
          <img src={LogoImageComponents.MicrosoftLogo} height="18px" alt={t('navbar.microsoftAlt')} />
        </div>
        <ul className="hidden flex-row items-center align-middle m-0 p-0 list-none tablet-and-up:flex">
          {themes &&
            themes
              .filter((theme) => theme.episodes.length)
              .map((theme, i) => {
                return (
                  <li
                    className="px-8"
                    key={`header-nav-${i + 1}-${theme.title}`}
                    data-testid="theme"
                    onClick={() => themeNavigate(theme, 'header-nav')}
                  >
                    <span
                      className={`body-xs mx-10 body-caps border-b-2 pb-8 cursor-pointer hover:text-white desktop-large:mx-16 ${
                        currentTheme?.id === theme.id ? 'text-white' : 'text-gray-signout border-black'
                      }`}
                    >
                      {t(`${theme.title}`)}
                    </span>
                  </li>
                );
              })}
        </ul>
        <div className="flex items-center align-middle pr-12 tablet-and-up:pr-24">
          {user && <p className="body-s text-inverted">{t('navbar.name', { name: user.first_name })}</p>}
          <span
            className="body-s text-gray-signout pl-10 cursor-pointer hidden tablet-and-up:block"
            onClick={onSignout}
          >
            {t('navbar.signOut')}
          </span>
          <button className="bg-transparent ml-4 cursor-pointer tablet-and-up:hidden" onClick={openNav}>
            <div className="flex items-center justify-center h-20 w-20">
              !{isMobile && !mobileNavOpen && <SvgIcon icon={Icons.Hamburger} size={15} className="text-white" />}
              {isMobile && mobileNavOpen && <SvgIcon icon={Icons.X} size={20} className="text-white" />}
            </div>
          </button>
        </div>
      </div>
      {isMobile && mobileNavOpen ? (
        <div className="block h-full w-full bg-gray-menu text-right">
          <ul className="block m-0 p-0 list-none px-12 py-28 text-heading-m font-soehne tablet-and-up:pr-24">
            {themes &&
              themes.map((theme, i) => {
                return (
                  <li
                    className="pb-20"
                    key={`hamburger-nav-${i + 1}-${theme.title}`}
                    data-testid="theme"
                    onClick={() => themeNavigate(theme, 'hamburger-nav')}
                  >
                    <span
                      className={`cursor-pointer text-white border-b-2 uppercase ${
                        currentTheme?.id === theme.id ? 'text-white' : 'border-gray-menu'
                      }`}
                    >
                      {t(`${theme.title}`)}
                    </span>
                  </li>
                );
              })}
            <li>
              <span className="body-s text-gray-signout cursor-pointer font-normal" onClick={onSignout}>
                {t('navbar.signOut')}
              </span>
            </li>
          </ul>
        </div>
      ) : null}
    </header>
  );
};
