import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

export interface PaginationProps {
  /**
   * @param onNext function callback when clicking next
   */
  onNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * @param onPrevious function callback when clicking preiouvs
   */
  onPrevious: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * @param currentIndex number the index that is being viewed
   */
  currentIndex: number;
  /**
   * @param totalIndices number total number of indices
   */
  totalIndices: number;
}

/**
 * Primary UI component for user interaction
 */
export const Pagination = ({ onNext, onPrevious, currentIndex, totalIndices }: PaginationProps) => {
  const { t } = useTranslation();
  return (
    <div className={`flex flex-row justify-between mt-16 mb-24 mx-auto z-1`} data-testid="pagination">
      <div className="relative w-72 h-62 mr-36">
        <CSSTransition timeout={250} classNames="fade" in={currentIndex !== 1} mountOnEnter unmountOnExit>
          <button
            aria-label={t('buttons.previous')}
            onClick={onPrevious}
            className={`${
              currentIndex !== 1 ? 'visible' : 'visible'
            } flex justify-center cursor-pointer items-center w-72 h-72 rounded-full mr-36 bg-white/65 hover:bg-white/90 active:bg-white`}
          >
            <SvgIcon icon={Icons.CaretLeft} size={28} className="text-midnight" />
          </button>
        </CSSTransition>
      </div>
      <div className="items-center flex justify-around w-[70px]">
        <span className="pa-heading-stat text-white mx-4">{currentIndex}</span>
        <span className="pa-heading-stat text-white mx-4">{`${t('generic.of')}`}</span>
        <span className="pa-heading-stat text-white mx-4">{totalIndices}</span>
      </div>
      <button
        aria-label={t('buttons.continue')}
        onClick={onNext}
        className="flex justify-center cursor-pointer items-center w-72 h-72 rounded-full ml-36 bg-white/65 hover:bg-white/90 active:bg-white"
      >
        {' '}
        <SvgIcon icon={Icons.CaretRight} size={28} className="text-midnight" />
      </button>
    </div>
  );
};
