import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AssessmentInsightsProps } from 'utils/assessment';
import BreakdownAlluvials from './BreakdownAlluvials';
import BreakdownFocusSelect from './BreakdownFocusSelect';
import BreakdownGlows from './BreakdownGlows';
import BreakdownIntro from './BreakdownIntro';
import BreakdownRings from './BreakdownRings';
import { BreakdownIntroductionContent } from 'services/content-service';

interface PostAssessmentBreakdownProps {
  /**
   * @insights AssessmentInsightsProps object containing the alluvials and other insight data
   */
  insights: AssessmentInsightsProps;
  /**
   * Breakdown Introduction Content
   */
  breakdownIntroductionContent: BreakdownIntroductionContent;
}

export default function PostAssessmentBreakdown({
  insights,
  breakdownIntroductionContent,
}: PostAssessmentBreakdownProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const totalSteps = 6;
  const transitionTime = 600;

  const onNext = () => {
    if (currentIndex < totalSteps - 1) {
      const newCurrentIndex = currentIndex + 1;
      setCurrentIndex(newCurrentIndex);
    }
  };
  const onPrevious = () => {
    if (0 < currentIndex) {
      const newCurrentIndex = currentIndex - 1;
      setCurrentIndex(newCurrentIndex);
    }
  };

  return (
    <>
      <BreakdownRings
        colorsLight={[
          insights.dimensions[0].content.color_gradient.light,
          insights.dimensions[1].content.color_gradient.light,
          insights.dimensions[2].content.color_gradient.light,
          insights.dimensions[3].content.color_gradient.light,
        ]}
        colorsDark={[
          insights.dimensions[0].content.color_gradient.dark,
          insights.dimensions[1].content.color_gradient.dark,
          insights.dimensions[2].content.color_gradient.dark,
          insights.dimensions[3].content.color_gradient.dark,
        ]}
        currentIndex={currentIndex}
      />
      <BreakdownGlows insights={insights} currentIndex={currentIndex} />
      <div className="w-full h-896 z-1 overflow-hidden" data-testid="post-assessment-breakdown">
        <CSSTransition
          timeout={transitionTime}
          classNames="fade"
          in={0 === currentIndex}
          mountOnEnter
          unmountOnExit
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <BreakdownIntro onClick={onNext} breakdownIntroductionContent={breakdownIntroductionContent} />
        </CSSTransition>
        <CSSTransition
          timeout={transitionTime}
          classNames="fade"
          in={1 <= currentIndex && currentIndex < totalSteps - 1}
          mountOnEnter
          unmountOnExit
        >
          <BreakdownAlluvials insights={insights} onNext={onNext} onPrevious={onPrevious} currentIndex={currentIndex} />
        </CSSTransition>
        <CSSTransition
          timeout={transitionTime}
          classNames="fade"
          in={totalSteps - 1 === currentIndex}
          mountOnEnter
          unmountOnExit
        >
          <BreakdownFocusSelect insights={insights} onPrevious={onPrevious} />
        </CSSTransition>
      </div>
    </>
  );
}
