import { Hero } from 'components/hero';
import { ThemeContext } from 'contexts/theme';
import { UserContext } from 'contexts/user';
import { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { trackEvent } from 'services/analytics-service';
import { SvgIcon } from 'components/svg-icon';
import { Icons } from 'assets/icons';
import ThemeNav from 'components/theme-nav';

function HomeContainer(): JSX.Element {
  const { currentTheme, currentEpisode, setCurrentEpisode } = useContext(ThemeContext);
  const [isVideoHidden, setIsVideoHidden] = useState<boolean>(true);
  const [isOverLeft, setIsOverLeft] = useState<boolean>(false);
  const [isOverRight, setIsOverRight] = useState<boolean>(false);
  const { inTeams } = useContext(UserContext);

  const selectEpisode = (index: number, episodeTitle: string) => {
    setCurrentEpisode(index);
    trackEvent({
      eventName: 'Site navigated',
      eventProperties: {
        component: 'episode-navigation',
        element: episodeTitle,
      },
    });

    setCurrentEpisode(index);
  };

  const goNext = () => {
    if (currentTheme) {
      const newIndex = currentEpisode + 1;
      setIsOverRight(false);
      setCurrentEpisode(newIndex);
      selectEpisode(newIndex, currentTheme.episodes[newIndex].name);
    }
  };

  const goPrevious = () => {
    if (currentTheme) {
      const newIndex = currentEpisode - 1;
      setIsOverLeft(false);
      setCurrentEpisode(newIndex);
      selectEpisode(newIndex, currentTheme.episodes[newIndex].name);
    }
  };

  const overLeft = () => {
    setIsOverLeft(true);
  };
  const outLeft = () => {
    setIsOverLeft(false);
  };

  const overRight = () => {
    setIsOverRight(true);
  };

  const outRight = () => {
    setIsOverRight(false);
  };

  return (
    <div className={`${inTeams ? 'min-h-screen' : 'min-h-grow-full'} h-full relative`} data-testid="app-home">
      {isVideoHidden && inTeams && <ThemeNav />}
      {currentTheme ? (
        <>
          {isVideoHidden && currentEpisode !== 0 && (
            <div
              onClick={goPrevious}
              onMouseOver={overLeft}
              onMouseOut={outLeft}
              className={`flex cursor-pointer z-100 justify-center items-center h-full w-[100px] left-0 absolute bg-gradient-to-l from-transparent ${
                isOverLeft ? 'to-black/40' : 'bg-transparent opacity-40'
              }`}
            >
              <SvgIcon icon={Icons.CaretLeft} size={30} className="text-white" />
            </div>
          )}
          {isVideoHidden && currentEpisode < currentTheme.episodes.length - 1 && (
            <div
              onClick={goNext}
              onMouseOver={overRight}
              onMouseOut={outRight}
              className={`flex cursor-pointer z-100 justify-center items-center h-full w-[100px] right-0 absolute bg-gradient-to-r from-transparent ${
                isOverRight ? 'to-black/40' : 'bg-transparent opacity-40'
              }`}
            >
              <SvgIcon icon={Icons.CaretRight} size={30} className="text-white" />
            </div>
          )}
          {currentTheme.episodes.length > 1 && isVideoHidden && (
            <div className="flex justify-center">
              <div className="absolute flex flex-row bottom-72 ml-8 z-10">
                {currentTheme.episodes.map((episode, index) => {
                  return (
                    <div
                      onClick={() => selectEpisode(index, episode.name)}
                      key={`episode-button-selection-${index}`}
                      className={`cursor-pointer rounded w-8 h-8 mr-8 ${
                        index === currentEpisode ? 'bg-white' : 'bg-white/30'
                      }`}
                    ></div>
                  );
                })}
              </div>
            </div>
          )}
          {currentTheme.episodes.map((episode, index) => {
            return (
              <CSSTransition
                key={`episode-video-${index}`}
                timeout={250}
                classNames="fade"
                in={index === currentEpisode}
                mountOnEnter
                unmountOnExit
              >
                <div className="absolute z-1 top-0 left-0 w-full h-full w-full min-h-grow-full">
                  <Hero episode={episode} isVideoHidden={isVideoHidden} setIsVideoHidden={setIsVideoHidden} />
                </div>
              </CSSTransition>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HomeContainer;
