import { InvertedProps } from 'utils/components';
import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { PracticeExerciseType } from 'utils/post-assessment-types';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { voiceOfCoachDark } from 'utils/helpers/colors';
import Lottie from 'lottie-react';
import animationData from './lottie-checkmark.json';
import useElementSize from 'utils/hooks/useElementSize';

// to fix the tech debt between the following states"active, recommended, showCheck, closeVideo, I propose we use a single enum
// that is used to distinguish between the visual representations, similar to how we are using "currentStep" in Cue experience
export enum VARIANTS {
  yourPractices = 'yourPractices',
}

interface PracticeSummaryCardProps extends InvertedProps {
  /**
   * @param practiceExercise PracticeExerciseType
   */
  practiceExercise: PracticeExerciseType;
  /**
   * @param label string optional text to display instead of cadence
   */
  label?: string;
  /**
   * @param active boolean should the card be in the expanded state
   */
  active: boolean;
  /**
   * @param className string class to pass into component
   */
  className?: string;
  /**
   * @param recommended boolean representing whether or not this practice is recommended
   */
  recommended?: boolean;
  /**
   * @param showCheck (optional) boolean whether or not to show the fancy checkmark animation
   */
  showCheck?: boolean;
  /**
   * @param onClickContinue (optional) function triggered when the card's continue button is clicked
   */
  onClickContinue?: () => void;
  /**
   * @param onClick (optional) function triggered when the card is clicked so that the carousel can go to it
   */
  onClick?: () => void;
  /**
   * @param closeVideo (optional) boolean to hide or show the big black background of the video, based on the close button in the video component
   */
  closeVideo?: boolean;
  /**
   * @param variant (optional) VARIANTS string variations that render this component differently based on its context within the parent components
   */
  variant?: VARIANTS;
}

/**
 * Primary UI component for practice summary card
 */
export const PracticeSummaryCard = ({
  inverted,
  showCheck,
  recommended,
  active,
  className,
  onClick,
  onClickContinue,
  practiceExercise,
  label,
  closeVideo,
  variant,
}: PracticeSummaryCardProps) => {
  const { t } = useTranslation();
  const bgColor = inverted ? 'bg-white' : 'bg-black';
  const titleColor = inverted ? 'text-black' : 'text-gray-accent';
  const textClass = inverted ? 'practice-text-inverted' : 'practice-text';
  const [scale, setScale] = useState<number>(1);
  const [opacity, setOpacity] = useState<number>(1);
  const [showCheckAnimation, setShowCheckAnimation] = useState<boolean>(false);
  const [toggleDisableButton, setToggleDisableButton] = useState<number>(1);
  const [ref, { isMobile }] = useElementSize();

  const transitionDuration = 650;
  const textColor = inverted ? 'text-black' : 'text-white';

  useEffect(() => {
    if (showCheck) {
      setTimeout(() => setShowCheckAnimation(true), 1200);
      setTimeout(() => setShowCheckAnimation(false), 2800);
    }
  }, [showCheck]);

  useEffect(() => {
    if (closeVideo) {
      setScale(1);
      setTimeout(() => setOpacity(1), 250);
      setToggleDisableButton(0);
    }
  }, [closeVideo]);

  const clickButton = () => {
    setScale(5);
    setOpacity(0);
    setToggleDisableButton(-1);
    if (onClickContinue) setTimeout(onClickContinue, transitionDuration + 100);
  };

  const widthActive = isMobile && !showCheck ? 320 : 720;
  const widthInactive = isMobile && !showCheck ? 188 : 288;

  let buttonText = `${t(`buttons.tryIt`, { duration: practiceExercise.video.duration })}`;
  if (variant === 'yourPractices') {
    buttonText = `${t(`buttons.practiceAgain`, { duration: practiceExercise.video.duration })}`;
  }

  return (
    <div
      ref={ref}
      className={`${
        !showCheck && 'mr-20 desktop:mr-36'
      } cursor-pointer flex flex-col justify-center relative select-none overflow-hidden min-h-512 rounded-36 ${
        active ? 'z-[100]' : 'z-0'
      } ${className ? className : ''} ${recommended ? 'border-1' : ''} ${closeVideo ? bgColor : 'bg-black'} ${
        showCheck ? 'bg-white' : ''
      }`}
      style={{
        transition: `min-width ${transitionDuration}ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms, transform 1000ms ease-in 0ms, background-color 250ms ease-in 0ms`,
        minWidth: active ? widthActive : widthInactive,
        maxWidth: active ? widthActive : widthInactive,
        transform: `scale(${scale})`,
        borderColor: `${recommended ? voiceOfCoachDark : 'transparent'}`,
        transformOrigin: '50px',
      }}
      data-testid="practice-summary-card"
      onClick={onClick}
    >
      <div
        style={{
          transition: `opacity 250ms ease-in 0ms`,
          opacity,
        }}
      >
        {showCheck ? (
          <CSSTransition
            timeout={showCheckAnimation ? 100 : 600}
            classNames="check-delay-fade"
            in={showCheckAnimation}
            mountOnEnter
            unmountOnExit
          >
            <Lottie
              animationData={animationData}
              loop={false}
              style={{ width: 300, position: 'absolute', top: -100, left: -100 }}
              data-testid="lottie-checkmark"
            />
          </CSSTransition>
        ) : recommended ? (
          <div className="top-24 left-24 absolute flex flex-row">
            <CSSTransition timeout={transitionDuration} classNames="fade" in={true} mountOnEnter unmountOnExit>
              <SvgIcon icon={Icons.Electric} size={53} />
            </CSSTransition>
            <CSSTransition timeout={transitionDuration} classNames="fade" in={active} mountOnEnter unmountOnExit>
              <div className="flex flex-col justify-center">
                {practiceExercise.voice_of_a_coach.title && (
                  <span className={`pa-body-coach-tip-s text-voiceOfCoachLight bold`}>
                    {practiceExercise.voice_of_a_coach.title}
                  </span>
                )}
                <span className={`pa-body-s ${textColor} mt-4`}>{practiceExercise.voice_of_a_coach.description}</span>
              </div>
            </CSSTransition>
          </div>
        ) : null}
        <div className="px-32 absolute top-96 tablet-and-up:top-125">
          <p
            className={`sentence-case pa-heading-s mobile:text-[14px] ${titleColor} ${textClass} mb-12`}
            dangerouslySetInnerHTML={{ __html: label || practiceExercise.cadence.description }}
          />
          <p
            className={`pa-heading-m mobile:text-[22px] mobile:leading-6 text-gray-accent ${textClass} mb-12`}
            dangerouslySetInnerHTML={{ __html: practiceExercise.title }}
          />
          <CSSTransition timeout={transitionDuration} classNames="fade" in={active} mountOnEnter unmountOnExit>
            <p className={`pa-heading-s mobile:text-[16px] ${titleColor} mb-16`}>{practiceExercise.description}</p>
          </CSSTransition>
        </div>
        <CSSTransition timeout={transitionDuration} classNames="fade" in={active} mountOnEnter unmountOnExit>
          <div className="absolute bottom-24 left-24 flex flex-row items-center">
            <Button
              iconLeft={true}
              icon={Icons.PlayFullIcon}
              tabIndex={toggleDisableButton}
              label={buttonText}
              text={buttonText}
              onClick={clickButton}
              className="max-w-224 hover:cursor-pointer"
            />
            <p className={`pa-body-s text-gray-duration ml-8`}>
              {t('buttons.duration', { duration: practiceExercise.video.duration })}
            </p>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
