import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FocusSelectButtonProps {
  /**
   * @title string the text on the button
   */
  title: string;
  /**
   * @showVoc boolean whether the voice of a coach copy is displayed
   */
  showVoc?: boolean;
  /**
   * @onClick function on click of the focus select button
   */
  onClick: () => void;
  /**
   * @subtitle string the copy that goes below next to the lightning bolt
   */
  subtitle?: string;
}

export default function FocusSelectButton({ title, showVoc, onClick, subtitle }: FocusSelectButtonProps) {
  const { t } = useTranslation();
  const [isMouseOverState, setIsMouseOverState] = useState<string>(`background 0ms ease-in`);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onMouseOver = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsMouseOverState(`background 500ms ease-in`);
  };
  const onMouseOut = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsMouseOverState(`background 0ms ease-in`);
    }, 100);
  };

  return (
    <button
      aria-label={title}
      data-testid={'focus-selection-button'}
      className={`min-w-248 max-w-540 h-80 w-11/12 rounded-100 flex flex-col justify-center items-center border-1 mt-12 p-24 text-white cursor-pointer bg-white/10 hover:bg-white/25 ${
        showVoc ? 'border-voiceOfCoachDark' : 'border-white/25'
      } `}
      style={{
        transition: isMouseOverState,
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      <span className="pa-body">{title}</span>
      <>
        {showVoc && (
          <div className="flex flex-row items-center justify-center">
            <div
              style={{
                transform: 'scale(1.3)',
              }}
            >
              <SvgIcon icon={Icons.Electric} size={30} />
            </div>
            <span className="pa-body-s text-white">{`${
              subtitle ? subtitle : t('assessment.postAssessment.breakdown.selection.lowestScore')
            }`}</span>
          </div>
        )}
      </>
    </button>
  );
}
