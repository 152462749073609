import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'contexts/theme';
import { UserContext } from 'contexts/user';
import { Navbar } from 'components/navbar';
import { Footer } from 'components/footer';
import { StatsSection } from 'components/sections/stats';
import { ExclusiveSection } from 'components/sections/exclusive';
import AppRouterContainer from 'containers/app-router';
import HomeContainer from 'containers/home';
import { trackEvent } from 'services/analytics-service';

function VideoPlayPage(): JSX.Element {
  const { changeCurrentTheme, setCurrentEpisode, themes, currentTheme, currentEpisode } = useContext(ThemeContext);
  const { inTeams, teamsContext } = useContext(UserContext);

  const [tabViewed, setTabViewed] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (themes && inTeams && teamsContext) {
      const episode = currentTheme?.episodes[currentEpisode].name;

      if (!tabViewed) {
        trackEvent({
          eventName: 'Tab Viewed',
          eventProperties: {
            tab_name: 'journeys',
            themes: themes.map((theme) => theme.key),
            episodes: themes.flatMap((theme) => theme.episodes.map((episode) => episode.name)),
            current_theme: currentTheme?.key,
            current_episode: episode,
          },
        });
        setTabViewed(true);
      }

      if (!initialRender) {
        trackEvent({
          eventName: 'Episode Viewed',
          eventProperties: {
            current_episode: episode,
          },
        });
      }
      setInitialRender(false);
    }
  }, [themes, currentTheme, currentEpisode, inTeams, tabViewed, teamsContext, initialRender]);

  useEffect(() => {
    if (themes && teamsContext && teamsContext.page && teamsContext.page.subPageId) {
      const episodeName = teamsContext.page.subPageId;
      const theme = themes.find((theme) => theme.episodes.find((episode) => episode.name === episodeName));
      const episodeIndex = theme?.episodes.findIndex((episode) => episode.name === episodeName);

      if (theme && episodeIndex !== undefined) {
        changeCurrentTheme(theme);
        setCurrentEpisode(episodeIndex);
      }
    }
  }, [changeCurrentTheme, setCurrentEpisode, teamsContext, themes]);

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden select-none">
      {!inTeams && <Navbar />}

      <div className="flex flex-col">
        <HomeContainer />
        <div className="flex items-center relative overflow-hidden bg-black min-h-896 desktop:h-896">
          <AppRouterContainer />
        </div>
      </div>

      <div className="bg-white py-48 relative">
        <div className="max-w-1440 mx-auto overflow-hidden">
          <ExclusiveSection />
          <StatsSection />
        </div>
      </div>

      {!inTeams && <Footer />}
    </div>
  );
}

export default VideoPlayPage;
