import { useTranslation } from 'react-i18next';
import FocusSelectButton from '../breakdown/FocusSelectButton';
import BreakdownElementRings from '../breakdown/BreakdownRings';
import BreakdownGlows from '../breakdown/BreakdownGlows';
import { JourneyContext } from 'contexts/journey';
import { useContext, useState } from 'react';
import { PostAssessmentLoading } from '../loading';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';

export default function PostAssessmentFocusAreaSelection() {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigation();

  const { insights, saveFocusArea } = useContext(JourneyContext);
  const [loaderTimedOut, setLoaderTimedOut] = useState<boolean>(true);

  const onFocusSelect = async (focusAreaId: string) => {
    await saveFocusArea(focusAreaId);

    navigate('/practices/recommend');
  };

  return (
    <div className="h-screen bg-black">
      {!loaderTimedOut && !insights && (
        <PostAssessmentLoading
          inverted
          description={t('assessment.postAssessment.loader.afterAssessment')}
          duration={3000}
          onTimeout={() => setLoaderTimedOut(true)}
        />
      )}
      {insights && (
        <div>
          <BreakdownElementRings
            colorsLight={[
              insights.dimensions[0].content.color_gradient.light,
              insights.dimensions[1].content.color_gradient.light,
              insights.dimensions[2].content.color_gradient.light,
              insights.dimensions[3].content.color_gradient.light,
            ]}
            colorsDark={[
              insights.dimensions[0].content.color_gradient.dark,
              insights.dimensions[1].content.color_gradient.dark,
              insights.dimensions[2].content.color_gradient.dark,
              insights.dimensions[3].content.color_gradient.dark,
            ]}
            currentIndex={5}
          />
          <BreakdownGlows insights={insights} currentIndex={5} />
          <div
            className={`absolute top-0 left-0 flex w-full flex-col mt-[270px] w-11/12 mx-auto`}
            data-testid="post-assessment-focus-areas-selection"
          >
            <div className="flex flex-col items-center m-auto w-11/12 desktop:w-2/3 desktop:mt-[200]">
              <h2 className="pa-heading-xl text-white text-center mb-36">{`${t(
                'assessment.postAssessment.breakdown.selection.title'
              )}`}</h2>
              {insights.dimensions.map((alluvial, index) => {
                return (
                  <FocusSelectButton
                    key={alluvial.key}
                    title={alluvial.content.title}
                    showVoc={index === 0}
                    onClick={() => onFocusSelect(alluvial.key)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
