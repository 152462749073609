import { useEffect, useState, useContext } from 'react';
import VideoPlayPage from 'containers/video-play-page';
import LoginPage from 'containers/login-page';
import { getAuthenticityToken } from 'services/authenticity-token-service';
import { LoadingSpinner } from 'components/loaders/LoadingSpinner';
import { UserContext } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { CookieBanner } from 'components/cookie-banner';

function WebAppContainer(): JSX.Element {
  const { t } = useTranslation();
  const { loading, user } = useContext(UserContext);
  const [authenticityToken, setauthenticityToken] = useState<string>();

  document.title = t('title');

  useEffect(() => {
    getAuthenticityToken()
      .then((authenticityToken) => {
        setauthenticityToken(authenticityToken);
        window.sessionStorage.setItem('authenticityToken', authenticityToken);
      })
      .catch(() => {
        sessionStorage.removeItem('authenticityToken');
      });
  }, []);

  return (
    <main className="h-screen">
      {loading ? (
        <LoadingSpinner />
      ) : user && Object.keys(user).length ? (
        <>
          <VideoPlayPage />
          <CookieBanner />
        </>
      ) : (
        <>
          <LoginPage authenticityToken={authenticityToken} sso={false} />
          <CookieBanner />
        </>
      )}
    </main>
  );
}

export default WebAppContainer;
