import { Barchart } from 'components/barchart';
import { Pagination } from 'components/pagination';
import { AssessmentInsightsProps } from 'utils/assessment';
import { convertHexToRGBA } from 'utils/helpers';
import { voiceOfCoachDark } from 'utils/helpers/colors';

interface BreakdownElementAlluvialsProps {
  insights: AssessmentInsightsProps;
  onNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onPrevious: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  currentIndex: number;
}
export default function BreakdownElementAlluvials({
  insights,
  onNext,
  onPrevious,
  currentIndex,
}: BreakdownElementAlluvialsProps) {
  const transitionTime = 500;
  const alluvialIndex =
    0 < currentIndex && currentIndex < insights.dimensions.length ? currentIndex - 1 : insights.dimensions.length - 1;
  const alluvial = insights.dimensions[alluvialIndex];

  return (
    <div className={`flex w-full flex-col w-11/12 mx-auto absolute top-224 tablet-and-up:top-240`}>
      <header className="relative w-full min-h-100 desktop:w-2/3 m-auto">
        {insights.dimensions.map((alluvial, index) => {
          return (
            <h2
              key={`alluvial-header-${alluvial.id}`}
              className={`absolute w-full text-center pa-heading-xl text-white`}
              style={{
                zIndex: 0,
                transition: `opacity ${transitionTime}ms ease-in`,
                opacity: `${alluvialIndex === index ? 100 : 0}`,
              }}
            >
              {alluvial.content.title}
            </h2>
          );
        })}
      </header>
      <Barchart
        score={alluvial.score}
        middleColor={insights.dimensions[1].content.color_gradient.light}
        lastColor={insights.dimensions[3].content.color_gradient.light}
        currentIndex={currentIndex}
        drawerBgColor={currentIndex === 1 ? convertHexToRGBA(voiceOfCoachDark, 0.22) : convertHexToRGBA('#FFF', 0.07)}
        drawerBorderColor={currentIndex === 1 ? convertHexToRGBA(voiceOfCoachDark, 1) : convertHexToRGBA('#FFF', 0.5)}
        title={
          alluvial.content.voice_of_a_coach.title
            ? alluvial.content.voice_of_a_coach.title
            : 'It looks like you’re struggling here'
        }
        description={
          alluvial.content.voice_of_a_coach.description
            ? alluvial.content.voice_of_a_coach.description
            : 'This can sometimes look like stress getting the best of us. A few simple practices can take take this from an obstacle to an asset.'
        }
      />
      <div className="p-8 tablet-and-up:p-0">
        <div className="relative mt-12 desktop:mt-24 mb-36 desktop:mb-12 h-72 w-full tablet-and-up:w-3/4 desktop:w-1/2 m-auto min-h-120">
          {insights.dimensions.map((alluvial, index) => {
            return (
              <p
                key={`alluvial-description-${alluvial.id}`}
                className={`absolute w-full text-center pa-body text-white`}
                style={{
                  zIndex: 0,
                  transition: `opacity ${transitionTime}ms ease-in`,
                  opacity: `${alluvialIndex === index ? 100 : 0}`,
                }}
              >
                {alluvial.content.description}
              </p>
            );
          })}
        </div>
      </div>
      <Pagination
        onNext={onNext}
        onPrevious={onPrevious}
        currentIndex={currentIndex}
        totalIndices={insights.dimensions.length}
      />
    </div>
  );
}
