import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import {
  baseUrlConfig,
  getPracticesUrl,
  getPracticesByAlluvialKeyUrl,
  getPracticesRecommendedUrl,
} from 'services/service-urls';
import { PracticeExerciseType, PracticeType, RecommendedType } from 'utils/post-assessment-types';

export const getPracticesByAlluvialKey = async (
  key: string,
  baseUrl: string = baseUrlConfig
): Promise<PracticeType> => {
  const res = await axios.get(getPracticesByAlluvialKeyUrl(key, baseUrl), getDefaultAxiosConfig());
  return res.data;
};

export const getPractices = async (themeKey: string, baseUrl: string = baseUrlConfig): Promise<RecommendedType> => {
  const res = await axios.get(getPracticesUrl(themeKey, baseUrl), getDefaultAxiosConfig());
  return res.data;
};

export const getPracticesRecommended = async (
  themeKey: string,
  baseUrl: string = baseUrlConfig
): Promise<PracticeExerciseType[]> => {
  const res = await axios.get(getPracticesRecommendedUrl(themeKey, baseUrl), getDefaultAxiosConfig());
  return res.data.practices;
};
