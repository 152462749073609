import { InvertedProps } from 'utils/components';
import { CSSTransition } from 'react-transition-group';
import { Button, ButtonVariants } from 'components/button';
import { useTranslation } from 'react-i18next';
import useElementSize from 'utils/hooks/useElementSize';

interface PracticeSummaryCardHintProps extends InvertedProps {
  id: string;
  label: string;
  title: string;
  /**
   * @param active boolean should the card be in the expanded state
   */
  active: boolean;
  /**
   * @param onClick (optional) function triggered when the card is clicked so that the carousel can go to it
   */
  onClick?: () => void;
  /**
   * @param onClickButton (optional) function triggered when the button on the card is clicked
   */
  onClickButton?: (pageName: string) => void;
}

/**
 * Primary UI component for practice summary card
 */
export const PracticeSummaryCardHint = ({
  id,
  label,
  title,
  active,
  onClick,
  onClickButton,
}: PracticeSummaryCardHintProps) => {
  const { t } = useTranslation();
  const [ref, { isMobile }] = useElementSize();
  const transitionDuration = 650;

  const clickButton = () => {
    onClickButton && onClickButton(id);
  };

  const widthActive = isMobile ? 320 : 720;
  const widthInactive = isMobile ? 188 : 288;
  return (
    <div
      ref={ref}
      className={`mr-20 cursor-pointer desktop:mr-36 flex flex-col justify-center relative overflow-hidden min-h-512 max-h-512 rounded-36 border-1 border-white border-dashed`}
      style={{
        transition: `min-width ${transitionDuration}ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms, transform 1000ms ease-in 0ms`,
        minWidth: active ? widthActive : widthInactive,
        maxWidth: active ? widthActive : widthInactive,
      }}
      data-testid="practice-summary-card-hint"
      onClick={onClick}
    >
      <div
        style={{
          transition: `opacity 250ms ease-in 0ms`,
        }}
      >
        <div className="px-32 absolute top-125">
          <p className={`pa-heading-s text-white practice-text mb-12`}>{label}</p>
          <p className="pa-heading-m text-white practice-text mb-12">{title}</p>
        </div>
      </div>
      <CSSTransition timeout={transitionDuration} classNames="fade" in={active} mountOnEnter unmountOnExit>
        <div className="absolute bottom-24 left-24 flex flex-row items-center">
          <Button
            variant={ButtonVariants.Secondary}
            label={`${t('buttons.explorePractices')}`}
            text={`${t('buttons.explorePractices')}`}
            onClick={clickButton}
            className="max-w-224 hover:cursor-pointer"
          />
        </div>
      </CSSTransition>
    </div>
  );
};
