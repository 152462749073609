import { Hero } from 'components/hero';
import { ThemeContext } from 'contexts/theme';
import { UserContext } from 'contexts/user';
import { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { trackEvent } from 'services/analytics-service';

function VideosContainer(): JSX.Element {
  const { currentTheme, currentEpisode, setCurrentEpisode } = useContext(ThemeContext);
  const [isVideoHidden, setIsVideoHidden] = useState<boolean>(true);
  const { inTeams } = useContext(UserContext);

  const selectEpisode = (index: number, episodeTitle: string) => {
    trackEvent({
      eventName: 'Site navigated',
      eventProperties: {
        component: 'episode-navigation',
        element: episodeTitle,
      },
    });

    setCurrentEpisode(index);
  };

  return (
    <main className={`${inTeams ? 'min-h-screen' : 'min-h-grow-full'} relative`} data-testid={'teams-app-videos'}>
      {currentTheme ? (
        <>
          {currentTheme.episodes.length > 1 && isVideoHidden && (
            <div className="flex justify-center">
              <div className="absolute flex flex-row bottom-72 ml-8 z-10">
                {currentTheme.episodes.map((episode, index) => {
                  return (
                    <div
                      onClick={() => selectEpisode(index, episode.name)}
                      key={`episode-button-selection-${index}`}
                      className={`cursor-pointer rounded w-8 h-8 mr-8 ${
                        index === currentEpisode ? 'bg-white' : 'bg-white/30'
                      }`}
                    ></div>
                  );
                })}
              </div>
            </div>
          )}
          {currentTheme.episodes.map((episode, index) => {
            return (
              <CSSTransition
                key={`episode-video-${index}`}
                timeout={250}
                classNames="fade"
                in={index === currentEpisode}
                mountOnEnter
                unmountOnExit
              >
                <div className="absolute z-1 top-0 left-0 w-full h-full min-h-grow-full">
                  <Hero episode={episode} isVideoHidden={isVideoHidden} setIsVideoHidden={setIsVideoHidden} />
                </div>
              </CSSTransition>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </main>
  );
}
export default VideosContainer;
