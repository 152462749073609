import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/user';
import teamsAuthImg from 'assets/images/teams-auth.png';
import { trackEvent } from 'services/analytics-service';

function TeamsAuth(): JSX.Element {
  const { t } = useTranslation();
  const { teamsAuth } = useContext(UserContext);

  useEffect(() => {
    trackEvent({ eventName: 'Permissions flow started' });
  }, []);

  return (
    <div className="flex justify-center w-full h-screen" data-testid="teams-auth">
      <div className="flex flex-col justify-center items-center max-w-520">
        <img src={teamsAuthImg} alt="" width="200" className="mb-24" />

        <h1 className="heading-msteams mb-8">{t('teamsAuth.heading')}</h1>

        <p className="body-msteams mb-24">{t('teamsAuth.description')}</p>

        <button
          type="button"
          className="w-224 cursor-pointer text-white bg-[#6264a7] rounded-4 border-transparent"
          onClick={teamsAuth}
        >
          <span className="text-body-s leading-8">{t('teamsAuth.btnReviewPermissions')}</span>
        </button>
      </div>
    </div>
  );
}

export default TeamsAuth;
