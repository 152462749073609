import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from 'contexts/user';
import OAuthStart from 'containers/oauth/start';
import OAuthEnd from 'containers/oauth/end';
import TeamsAppContainer from 'containers/teams-app-container';
import WebAppContainer from 'containers/web-app-container';

function App() {
  const { inTeams } = useContext(UserContext);

  const urlParams = new URLSearchParams(window.location.search);
  const needsExpandedPermissions = urlParams.get('needsExpandedPermissions') === 'true';

  return inTeams ? (
    <BrowserRouter>
      <Routes>
        <Route path="/auth-start" element={<OAuthStart needsExpandedPermissions={needsExpandedPermissions} />} />
        <Route path="/auth-end" element={<OAuthEnd />} />
        <Route path="*" element={<TeamsAppContainer />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <MemoryRouter>
      <Routes>
        <Route path="*" element={<WebAppContainer />} />
      </Routes>
    </MemoryRouter>
  );
}

export default App;
