import { CardExclusive } from 'components/cards/exclusive';
import { ThemeContext } from 'contexts/theme';
import { useContext } from 'react';

export const ExclusiveSection = () => {
  const { currentTheme, currentEpisode } = useContext(ThemeContext);

  const episode = currentTheme?.episodes[currentEpisode];
  const playbook = episode?.playbook;
  const color = playbook?.font_color ? playbook.font_color : 'black';

  return (
    <section className="w-full box-border" data-testid="exclusive-section">
      {playbook && (
        <CardExclusive
          title={playbook.title}
          titleColor={color}
          backgroundImageDesktop={playbook.background_image_desktop}
          backgroundImageMobile={playbook.background_image_mobile}
          buttonLink={playbook.pdf_url}
          buttonText={playbook.button_call_to_action}
          buttonLabel={playbook.button_call_to_action}
          episodeName={episode?.name}
        />
      )}
    </section>
  );
};
