import { useContext, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { UserContext } from 'contexts/user';
import { LoadingSpinner } from 'components/loaders/LoadingSpinner';
import TeamsHomeContainer from 'containers/teams-home';
import VideoPlayPage from 'containers/video-play-page';
import TeamsAuth from 'containers/teams-auth';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';
import WelcomeContainer from 'containers/welcome-container';

function TeamsAppContainer(): JSX.Element {
  const { loading, user } = useContext(UserContext);
  const { navigate } = useCustomNavigation();
  const location = useLocation();

  const routedOnLoadRef = useRef<boolean>(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    // we only want to re-route them on initial load
    if (routedOnLoadRef.current) {
      return;
    }

    routedOnLoadRef.current = true;
    const hasSeenWelcome = user.welcome_flow_complete;
    if (!hasSeenWelcome) {
      navigate('/welcome');
    }
  }, [user, navigate, location]);

  // prettier-ignore
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : user && Object.keys(user).length ? (
        <Routes>
          <Route path="welcome" element={<WelcomeContainer />} />
          <Route path="home" element={<TeamsHomeContainer />} />
          <Route path="*" element={<VideoPlayPage />} />
        </Routes>
      ) : (
        <TeamsAuth />
      )}
    </>
  );
}

export default TeamsAppContainer;
