import { ScaleGroup, ScaleOption } from 'components/assessment/question-types/input-scale/group';
import { Card } from 'components/cards/basic';
import { Navigation } from 'components/assessment/cards/navigation';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ThemeContext } from 'contexts/theme';

interface ScaleCardProps {
  /**
   * Inverted colors
   */
  inverted?: boolean;
  /**
   * Question text
   */
  heading: string;
  /**
   * Current step number
   */
  currentStep: number;
  /**
   * Total steps number
   */
  totalSteps: number;
  /**
   * Classes list
   */
  className?: string;
  /**
   * Question identifier string
   */
  questionKey: string;
  /**
   * Options list for scale group
   */
  options: ScaleOption[];
  /**
   * user's selected index
   */
  response: number | string;
  /**
   * Can the question be skipped
   */
  is_skippable: boolean;
  /**
   * Callback when value changes within group
   */
  onChange: (questionKey: string, value: string) => void;
  /**
   * Optional back button click handler
   */
  onBackButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optional forward button click handler
   */
  onForwardButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
export const ScaleCard = ({
  inverted,
  heading,
  currentStep,
  totalSteps,
  className = '',
  questionKey,
  onChange,
  options,
  response,
  is_skippable,
  onBackButtonClick,
  onForwardButtonClick,
}: ScaleCardProps) => {
  const { t } = useTranslation();
  const invertedText = inverted ? 'text-inverted' : '';
  const headingStyle = heading.length > 250 ? 'heading-m' : 'heading-l';
  const { currentTheme } = useContext(ThemeContext);
  return (
    <Card testId="scale-card" className={`flex p-0 h-full no-action min-h-[inherit] ${className}`}>
      <div className="flex flex-col justify-between grow">
        <div className="flex flex-col justify-center items-center align-middle text-center my-auto mx-auto p-32 w-4/5 mobile:p-12 tablet:p-18 max-w-896">
          {heading && <h1 className={`${invertedText} ${headingStyle} whitespace-pre-line mb-16`}>{heading}</h1>}
          <ScaleGroup
            response={response}
            inverted={inverted}
            onChange={onChange}
            options={options}
            questionKey={questionKey}
          />
        </div>
        <Navigation
          inverted={inverted}
          title={`${t('assessment.navbarTitle', { theme: currentTheme?.title })}`}
          currentStep={currentStep}
          totalSteps={totalSteps}
          response={response}
          is_skippable={is_skippable}
          onBackButtonClick={onBackButtonClick}
          onForwardButtonClick={onForwardButtonClick}
        />
      </div>
    </Card>
  );
};
