import BetterUpLogo from './betterup.png';
import BetterUpLogoType from './betterup-logotype.svg';
import MicrosoftLogo from './microsoft-logo.png';
import MicrosoftSmallLogo from './microsoft-logo-small.png';

export enum Logos {
  BetterUp = 'BetterUpLogo',
  BetterUpLogoType = 'BetterUpLogoType',
  Microsoft = 'MicrosoftLogo',
  MicrosoftSmall = 'MicrosoftSmallLogo',
}

export const LogoImageComponents = {
  BetterUpLogo: BetterUpLogo,
  BetterUpLogoType: BetterUpLogoType,
  MicrosoftLogo: MicrosoftLogo,
  MicrosoftSmallLogo: MicrosoftSmallLogo,
};
