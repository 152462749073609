import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { SvgIcon } from 'components/svg-icon';
import { Icons } from 'assets/icons';
import { Button } from 'components/button';
import gradientBg from 'assets/images/gradient-bg-pink-blue.jpg';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';
import { WelcomeContentContext } from 'contexts/welcome';
import { updateUserFlags } from 'services/user-service';
import { UserContext } from 'contexts/user';
import { navigateToTab } from 'utils/teams';
import { trackEvent } from 'services/analytics-service';

function WelcomeContainer(): JSX.Element {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSteps = 5;
  const { welcomeContent } = useContext(WelcomeContentContext);
  const { inTeams } = useContext(UserContext);

  const onNext = () => {
    if (currentIndex < totalSteps - 1) {
      const newCurrentIndex = currentIndex + 1;
      setCurrentIndex(newCurrentIndex);
    }
  };

  const onPrevious = () => {
    if (0 < currentIndex) {
      const newCurrentIndex = currentIndex - 1;
      setCurrentIndex(newCurrentIndex);
    }
  };

  const gotoItem = (newCurrentIndex: number) => {
    setCurrentIndex(newCurrentIndex);
  };

  const gotoSelect = async () => {
    await updateUserFlags({ welcome_flow_complete: true });
    await trackEvent({
      eventName: 'Welcome flow completed',
    });
    if (inTeams) {
      navigateToTab('home');
    } else {
      navigate('/home');
    }
  };

  useEffect(() => {
    trackEvent({
      eventName: 'Welcome flow started',
    });
  }, []);

  return (
    <>
      {welcomeContent && (
        <div className="min-h-screen bg-black p-36 tablet-and-up:p-0" data-testid="welcome-container">
          <div className="flex flex-col relative z-1 w-full justify-center items-center">
            <div className="flex flex-col tablet-and-up:flex-row tablet-and-up:p-0 items-center w-full tablet-and-up:w-3/4 tablet-and-up:mt-48 tablet-and-up:mt-[160px]">
              <div className="mobile:hidden min-w-72 min-h-72 mr-36">
                <CSSTransition timeout={250} classNames="fade" in={currentIndex !== 0} mountOnEnter unmountOnExit>
                  <button
                    aria-label={t('buttons.previous')}
                    onClick={onPrevious}
                    className={`${
                      currentIndex !== 1 ? 'visible' : 'visible'
                    } flex justify-center min-w-72 min-h-72 cursor-pointer items-center rounded-full bg-white/65 hover:bg-white/90 active:bg-white`}
                  >
                    <SvgIcon icon={Icons.CaretLeft} size={28} className="text-midnight" />
                  </button>
                </CSSTransition>
              </div>
              <div className="overflow-hidden rounded flex flex-col tablet-and-up:flex-row h-full min-h-512 w-full">
                <div className="w-full min-w-full mobile:min-h-[350px] tablet-and-up:min-w-1/3 relative bg-white">
                  <CSSTransition timeout={250} classNames="fade" in={currentIndex <= 3} mountOnEnter unmountOnExit>
                    <div className="absolute h-full p-36">
                      <h4 className="heading-xl pt-24 tablet-and-up:pt-48 pb-12">{welcomeContent.heading}</h4>
                      <p className="body-m pb-24">{welcomeContent.message}</p>
                    </div>
                  </CSSTransition>
                  <CSSTransition timeout={250} classNames="fade" in={currentIndex === 4} mountOnEnter unmountOnExit>
                    <div className="absolute h-full p-36">
                      <h4 className="heading-xl pt-24 tablet-and-up:pt-48 pb-12">{welcomeContent.start_heading}</h4>
                      <p className="body-m pb-24">{welcomeContent.start_message}</p>
                      <Button
                        label={t('buttons.getStarted')}
                        text={t('buttons.getStarted')}
                        className="hover:cursor-pointer disabled:cursor-auto disabled:opacity-50"
                        onClick={gotoSelect}
                        testId="welcome-getStarted-button"
                      />
                    </div>
                  </CSSTransition>
                </div>
                <div className="w-full min-w-full mobile:min-h-512 tablet-and-up:min-w-2/3 relative">
                  {welcomeContent.steps.map((welcomeSlide, index) => {
                    return (
                      <CSSTransition
                        key={`welcome-${index}`}
                        timeout={250}
                        classNames="fade"
                        in={currentIndex === index}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div className="absolute bg-black/50 w-full h-full">
                          {welcomeSlide.headline && welcomeSlide.body && (
                            <div className="absolute bottom-24 left-24">
                              <h4 className="pa-heading-m text-white">{welcomeSlide.headline}</h4>
                              <p className="pa-heading-s text-white">{welcomeSlide.body}</p>
                            </div>
                          )}
                          {welcomeSlide.media_type === 'video' && (
                            <video
                              key={welcomeSlide.media_url}
                              playsInline
                              autoPlay
                              muted
                              loop
                              className={`object-cover w-full h-full bg-fixed`}
                              poster={welcomeSlide.poster_url}
                            >
                              <source src={welcomeSlide.media_url} type="video/webm" />
                              {t('videoPage.hero.videoNotSupported')}
                            </video>
                          )}
                          {welcomeSlide.media_type === 'image' && (
                            <div
                              style={{ backgroundImage: `url(${welcomeSlide.media_url})` }}
                              className="w-full h-full bg-no-repeat bg-cover bg-center"
                            />
                          )}
                        </div>
                      </CSSTransition>
                    );
                  })}
                </div>
              </div>
              <div className="flex mobile:flex-row mt-36 tablet-and-up:mt-0">
                <div className="tablet-and-up:hidden min-w-72 min-h-72 mr-36">
                  <CSSTransition timeout={250} classNames="fade" in={currentIndex !== 0} mountOnEnter unmountOnExit>
                    <button
                      aria-label={t('buttons.previous')}
                      onClick={onPrevious}
                      className={`${
                        currentIndex !== 1 ? 'visible' : 'visible'
                      } flex justify-center min-w-72 min-h-72 cursor-pointer items-center rounded-full bg-white/65 hover:bg-white/90 active:bg-white`}
                    >
                      <SvgIcon icon={Icons.CaretLeft} size={28} className="text-midnight" />
                    </button>
                  </CSSTransition>
                </div>
                <div className="min-w-72 min-h-72 ml-36">
                  <CSSTransition
                    timeout={250}
                    classNames="fade"
                    in={currentIndex < welcomeContent.steps.length - 1}
                    mountOnEnter
                    unmountOnExit
                  >
                    <button
                      aria-label={t('buttons.continue')}
                      onClick={onNext}
                      className="flex justify-center min-w-72 min-h-72 cursor-pointer items-center rounded-full bg-white/65 hover:bg-white/90 active:bg-white"
                      data-testid={`welcome-continue-button-${currentIndex}`}
                    >
                      <SvgIcon icon={Icons.CaretRight} size={28} className="text-midnight" />
                    </button>
                  </CSSTransition>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-36">
              <div className="flex flex-row ml-8 z-10">
                {welcomeContent.steps.map((step, index) => {
                  return (
                    <div
                      onClick={() => gotoItem(index)}
                      key={`welcome-button-selection-${index}`}
                      className={`cursor-pointer rounded w-8 h-8 mr-8 ${
                        index === currentIndex ? 'bg-white' : 'bg-white/30'
                      }`}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
          <img src={gradientBg} alt="" className="top-0 left-0 w-screen h-screen fixed z-0" />
        </div>
      )}
    </>
  );
}

export default WelcomeContainer;
