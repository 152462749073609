import { useContext } from 'react';
import { ThemeContext } from 'contexts/theme';
import { UserContext } from 'contexts/user';
import { AssessmentInfo } from 'components/assessment/info';
import useCustomNavigation from 'utils/hooks/useCustomNavigation';

/**
 * This screen is displayed on the Practices tab when the user hasn't completed their assessment.
 *
 * the CTA and copy directs them to take the assessment first, navigating the user to the assessments tab
 */
export const AssessmentIntro = () => {
  const { navigate } = useCustomNavigation();
  const { user } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const started = currentTheme && user && user.assessment_statuses[currentTheme?.key] === 'started';

  const ctaButtonHandler = () => {
    navigate('/assessments/questions');
  };

  return (
    <>
      {currentTheme && (
        <AssessmentInfo
          title={currentTheme.assessment_configuration.title}
          description={currentTheme.assessment_configuration.description}
          assessmentStarted={started}
          onClick={ctaButtonHandler}
        />
      )}
    </>
  );
};
