import { Icons, IconComponents } from 'assets/icons';

interface SvgIconProps {
  /**
   * SVG Icon
   */
  icon: Icons;
  /**
   * Size of icon
   */
  size?: number;
  /**
   * Classes list
   */
  className?: string;
}

/**
 * Primary UI component for user interaction
 */
export const SvgIcon = ({ icon, size = 24, className = '' }: SvgIconProps) => {
  const SvgIconComponent = IconComponents[icon];
  return <SvgIconComponent height={`${size}px`} width={`${size}px`} className={className} data-testid="icon" />;
};
