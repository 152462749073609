import { ProgressBarProps } from 'utils/components';

interface StepObj {
  step: number;
  dotBackgroundColor: string;
  dotMargin: string;
}

/**
 * Primary UI component for user interaction
 */
/* eslint react/prop-types: 0 */
export const ProgressDots = ({
  currentStep = 0,
  maxSteps,
  backgroundColor = 'bg-gray-aa',
  currentStepBackgroundColor = 'bg-blue',
  className = '',
}: ProgressBarProps) => {
  const steps = [];
  for (let i = 0; i < maxSteps; i++) {
    steps.push({
      step: i,
      dotBackgroundColor: i === currentStep ? currentStepBackgroundColor : backgroundColor,
      dotMargin: getDotMargin(i, maxSteps),
    });
  }

  function getDotMargin(step: number, maxSteps: number) {
    switch (step) {
      case 0:
        return 'ml-0 mr-4';
      case maxSteps - 1:
        return 'mr-0 ml-4';
      default:
        return 'mx-4';
    }
  }

  return (
    <div className={`flex ${className}`} data-testid="progress-dots">
      {steps.map(({ step, dotBackgroundColor, dotMargin }: StepObj) => (
        <div
          className={`h-8 w-8 rounded-full ${dotBackgroundColor} ${dotMargin}`}
          key={step}
          data-testid="progress-dots-dot"
        ></div>
      ))}
    </div>
  );
};
