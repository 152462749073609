import { convertHexToRGBA } from 'utils/helpers';
import { InvertedProps } from 'utils/components';

export interface RingsProps extends InvertedProps {
  /**
   * @colors array of hex values as strings
   */
  colors: string[];
}

export default function PostAssessmentGlows({ colors }: RingsProps) {
  const diameter = 200;
  return (
    <>
      <div
        className={`absolute top-0`}
        style={{
          width: `${diameter}px`,
          height: `${diameter}px`,
          transform: `scale(3.3)`,
          left: `-100px`,
          background: `radial-gradient(50% 50% at 50% 50%, 
                ${convertHexToRGBA(colors[0], 0.35)} 0%,
                ${convertHexToRGBA(colors[0], 0.35)} 50%,
                ${convertHexToRGBA(colors[0], 0)} 85%)`,
        }}
      />
      <div
        className={`absolute left-0`}
        style={{
          width: `${diameter}px`,
          height: `${diameter}px`,
          transform: `scale(3.3)`,
          top: `-100px`,
          background: `radial-gradient(50% 50% at 50% 50%, 
                ${convertHexToRGBA(colors[1], 0.35)} 0%,
                ${convertHexToRGBA(colors[1], 0.35)} 50%,
                ${convertHexToRGBA(colors[1], 0)} 85%)`,
        }}
      />
      <div
        className={`absolute`}
        style={{
          width: `${diameter}px`,
          height: `${diameter}px`,
          top: `-200px`,
          transform: `scale(3.3)`,
          left: `-100px`,
          background: `radial-gradient(50% 50% at 50% 50%, 
                ${convertHexToRGBA(colors[2], 0.35)} 0%,
                ${convertHexToRGBA(colors[2], 0.35)} 50%,
                ${convertHexToRGBA(colors[2], 0)} 85%)`,
        }}
      />
      <div
        className={`absolute`}
        style={{
          width: `${diameter}px`,
          height: `${diameter}px`,
          top: `-100px`,
          left: `-200px`,
          transform: `scale(3.3)`,
          background: `radial-gradient(50% 50% at 50% 50%, 
                ${convertHexToRGBA(colors[3], 0.35)} 0%,
                ${convertHexToRGBA(colors[3], 0.35)} 50%,
                ${convertHexToRGBA(colors[3], 0)} 85%)`,
        }}
      />
    </>
  );
}
