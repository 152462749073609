import { LogoImageComponents } from 'assets/images/logos';
import { useTranslation } from 'react-i18next';

/**
 * Primary UI component for user interaction
 */
export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="relative w-full bg-midnight">
      <div className="flex w-full max-w-1440 mx-auto items-center align-middle bg-midnight justify-between">
        <img
          src={LogoImageComponents.BetterUpLogo}
          height="24px"
          alt={t('footer.betterupLogoAlt')}
          className="my-24 pl-12 tablet-and-up:pl-24"
        />
        <div className="flex flex-row mr-24 align-middle justify-center">
          <p className="body-s text-white">&copy;{new Date().getFullYear()}</p>
          <span className="body-s text-white mx-4">|</span>
          <a
            href="https://www.betterup.com/supp-privacy-notice-1"
            target="_blank"
            rel="noreferrer"
            className="body-s text-white focus:border-none"
          >
            {t('footer.privacyPolicy')}
          </a>
        </div>
      </div>
    </footer>
  );
};
