import * as microsoftTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { trackEvent } from 'services/analytics-service';
import { getUserByToken } from 'services/user-service';

let teamsInitPromise: Promise<void>;
export function ensureTeamsSdkInitialized() {
  if (!teamsInitPromise) {
    teamsInitPromise = microsoftTeams.app.initialize();
  }
  return teamsInitPromise;
}

// async function returns true if we're running in Teams
export async function isTeams(): Promise<boolean> {
  if (window.location.search.includes('cypress')) {
    return false;
  }

  try {
    await ensureTeamsSdkInitialized();
    const context = await microsoftTeams.app.getContext();
    return (
      context.app.host.name === microsoftTeams.HostName.teams ||
      context.app.host.name === microsoftTeams.HostName.teamsModern
    );
  } catch (e) {
    return false;
  }
}

export async function getTeamsContext(): Promise<microsoftTeams.app.Context | null> {
  if (window.location.search.includes('cypress')) {
    return null;
  }

  try {
    await ensureTeamsSdkInitialized();
    const context = await microsoftTeams.app.getContext();
    return context;
  } catch (e) {
    return null;
  }
}

export async function teamsAuthenticate() {
  let user;

  try {
    user = await exchangeAuthTokenForAccessToken(false);
    return user;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response?.data.error === 'consent_required') {
      return undefined;
    } else {
      microsoftTeams.app.notifyFailure({
        reason: microsoftTeams.app.FailedReason.AuthFailed,
        message: 'Authentication failed.',
      });
      Sentry.captureException(error);
      return undefined;
    }
  }
}

export async function teamsOAuthAuthentication(needsExpandedPermissions: boolean) {
  try {
    trackEvent({ eventName: 'Permissions Requested' });
    await microsoftTeams.authentication.authenticate({
      url: window.location.origin + `/auth-start?needsExpandedPermissions=${needsExpandedPermissions}`,
      width: 600,
      height: 535,
    });
    const user = await exchangeAuthTokenForAccessToken(true);
    trackEvent({ eventName: 'Permissions Granted' });
    return user;
  } catch (error) {
    microsoftTeams.app.notifyFailure({
      reason: microsoftTeams.app.FailedReason.AuthFailed,
      message: 'Authentication failed.',
    });
    Sentry.captureException(error);
    return undefined;
  }
}

async function exchangeAuthTokenForAccessToken(consented: boolean) {
  const authToken = await microsoftTeams.authentication.getAuthToken();
  const user = await getUserByToken(authToken, consented);

  if (user && user.token) {
    window.sessionStorage.setItem('token', user.token);
  }

  return user;
}

export async function navigateToTab(pageId: string, subPageId?: string): Promise<void> {
  if (microsoftTeams.pages.currentApp.isSupported()) {
    microsoftTeams.pages.currentApp.navigateTo({ pageId, subPageId });
  } else {
    const appId = process.env.REACT_APP_MS_MANIFEST_ID as string;
    microsoftTeams.pages.navigateToApp({ appId, pageId, subPageId });
  }
}
