import { ThemeContext } from 'contexts/theme';
import { useContext } from 'react';
import { trackEvent } from 'services/analytics-service';
import { Theme } from 'services/themes-service';

function ThemeNav(): JSX.Element {
  const { themes, currentTheme, changeCurrentTheme } = useContext(ThemeContext);

  const selectTheme = (theme: Theme) => {
    trackEvent({
      eventName: 'Site navigated',
      eventProperties: {
        component: 'theme-navigation',
        element: theme.title,
      },
    });

    changeCurrentTheme(theme);
  };

  return (
    <div className="w-full h-full flex justify-center">
      <div
        className="absolute items-center z-[101] p-1 top-36 px-16 h-36 rounded-100 bg-black/30 border-1 border-white/80 flex flex-row justify-around"
        data-testid="themenav"
      >
        {themes?.map((theme, index) => {
          return (
            <div
              key={`theme-button-${index}`}
              onClick={() => selectTheme(theme)}
              className={`${
                theme === currentTheme ? 'bg-black' : 'bg-transparent'
              } cursor-pointer rounded-100 p-8 tablet-and-up:px-16 tablet-and-up:mx-8 hover:bg-black`}
            >
              <span className="body-m text-white mobile:text-[13px]">{theme.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ThemeNav;
