import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from 'assets/icons';
import { SvgIcon } from 'components/svg-icon';
import { Routine, RoutineCategory } from 'services/routines-service';
import { CSSTransition } from 'react-transition-group';
import FocusSelectButton from 'components/sections/post-assessment/breakdown/FocusSelectButton';

interface CueSelectionProps {
  /**
   * Array of routine categories for display
   */
  routines: RoutineCategory[];
  /**
   * Button click handler for selecting a routine category
   */
  onCategorySelect: (category: RoutineCategory) => void;
  /**
   * Button click handler for selecting a routine option
   */
  onRoutineSelect: (option: Routine) => void;
}
export default function CueSelection({ onCategorySelect, onRoutineSelect, routines }: CueSelectionProps) {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<RoutineCategory | null>(null);

  const categorySelected = (category: RoutineCategory) => {
    setSelectedCategory(category);
    onCategorySelect(category);
  };

  // staging's routine IDs are the same as production
  const routineIdsToFilter = process.env.NODE_ENV === 'development' ? [1, 5, 6, 7, 9, 13] : [4, 6, 7, 2, 8, 9];

  const selectedCategoryOptions =
    selectedCategory && selectedCategory.options.filter((routine) => routineIdsToFilter.includes(routine.id));

  return (
    <div className="absolute top-0 h-full mx-12 tablet-and-up:mx-0 text-white" data-testid="cue-selection">
      <h1 className="pa-heading-xl mb-16">{t('assessment.postAssessment.cue.selection.title')}</h1>
      <p className="pa-body mb-24">{t('assessment.postAssessment.cue.selection.subtitle')}</p>

      <CSSTransition classNames="fade" timeout={600} in={selectedCategory !== null} mountOnEnter unmountOnExit>
        {selectedCategory ? (
          <div className="absolute flex flex-col w-full mb-24">
            <div className="flex flex-col justify-center items-center w-full py-24 desktop:py-0 mx-auto mb-36 h-248 overflow-scroll desktop:h-auto desktop:overflow-auto">
              {selectedCategoryOptions &&
                selectedCategoryOptions.map((option, index) => (
                  <FocusSelectButton
                    key={`routine-option-${option.id}`}
                    title={option.title}
                    onClick={() => onRoutineSelect(option)}
                    showVoc={false}
                  />
                ))}
              <button
                className="flex flex-col justify-center items-center mt-12 h-72 w-72 rounded-100 text-black cursor-pointer bg-white/65 hover:bg-white"
                aria-label={t('buttons.previous')}
                onClick={() => setSelectedCategory(null)}
              >
                <SvgIcon icon={Icons.CaretLeft} size={30} />
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>

      <CSSTransition classNames="fade" timeout={600} in={selectedCategory === null} mountOnEnter unmountOnExit>
        <div className="absolute flex flex-col justify-center items-center w-full mb-24 pb-24">
          {routines.map((category) => (
            <FocusSelectButton
              key={`routine-category-${category.id}`}
              title={category.title}
              onClick={() => categorySelected(category)}
              showVoc={false}
            />
          ))}
        </div>
      </CSSTransition>
    </div>
  );
}
