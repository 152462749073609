import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { baseUrlConfig, getCreateFocusAreaUrl } from 'services/service-urls';

export interface FocusArea {
  userId: number;
  assessmentId: number;
  constructId: number;
  constructKey: number;
}

interface CreateFocusAreaParams {
  assessment_id: number;
  construct_key: string;
}

export const createFocusArea = async (
  params: CreateFocusAreaParams,
  baseUrl: string = baseUrlConfig
): Promise<FocusArea> => {
  const res = await axios.post(getCreateFocusAreaUrl(baseUrl), params, getDefaultAxiosConfig());
  return res.data.focus_area;
};
