import { useTranslation } from 'react-i18next';
import { Button, ButtonVariants } from 'components/button';
import { useContext } from 'react';
import { UserContext } from 'contexts/user';

interface CueConfirmationProps {
  onClick: () => void;
  onAddToCalendarClick: () => void;
}

export default function CueConfirmation({ onClick, onAddToCalendarClick }: CueConfirmationProps) {
  const { t } = useTranslation();
  const { inTeams, needsExpandedPermissions } = useContext(UserContext);

  return (
    <div className="absolute text-white max-w-596" data-testid="cue-confirmation">
      <h1 className="pa-heading-xl mb-16 mx-12 tablet-and-up:mx-0">
        {t('assessment.postAssessment.cue.confirmation.title')}
      </h1>
      <p className="pa-body mb-56 mx-12 tablet-and-up:mx-0">
        {t('assessment.postAssessment.cue.confirmation.subtitle')}
      </p>
      {inTeams && needsExpandedPermissions ? (
        <p className="pa-body mb-24 mx-12 tablet-and-up:mx-0 border px-8 py-4 rounded-lg notice">
          {t('assessment.postAssessment.cue.confirmation.needPermissions')}
        </p>
      ) : null}
      <div className="px-12 tablet-and-up:px-0">
        {inTeams ? (
          <>
            <Button
              variant={ButtonVariants.Secondary}
              onClick={onClick}
              label={t('buttons.seeYourPractices')}
              text={t('buttons.seeYourPractices')}
              className="hover:cursor-pointer"
            />
          </>
        ) : (
          <Button
            onClick={onClick}
            label={t('buttons.continue')}
            text={t('buttons.continue')}
            className="hover:cursor-pointer"
          />
        )}
      </div>
    </div>
  );
}
