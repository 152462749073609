import { Carousel } from 'components/carousel';
import { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { createFocusArea } from 'services/focus-areas-service';
import { JourneyContext } from 'contexts/journey';
import { AssessmentMetrics } from 'utils/assessment';
import { PracticeType } from 'utils/post-assessment-types';

interface CarouselFiltersProps {
  /**
   * @param alluvials AssessmentMetrics[] - array of alluvials, sorted by score
   */
  alluvials: AssessmentMetrics[];
  /**
   * @param practices PracticeType[] - array of practices
   */
  practices: PracticeType[] | undefined;
  /**
   * which alluvial was selected
   */
  selectedAlluvial?: string | undefined;
}

/**
 * Primary UI component for user interaction
 */
export const CarouselFilters = ({ alluvials, practices, selectedAlluvial }: CarouselFiltersProps) => {
  const [activeAlluvialId, setActiveAlluvialId] = useState<string | undefined>(selectedAlluvial);
  const [revealVideo, setRevealVideo] = useState<boolean>(false);
  const { insights } = useContext(JourneyContext);

  const selectPractice = (id: string) => {
    setActiveAlluvialId(id);
    if (insights) {
      createFocusArea({
        assessment_id: insights.id,
        construct_key: id,
      });
    }
  };

  return (
    <>
      <div className="flex flex-row flex-start pl-32 desktop:pl-90 mb-32">
        {alluvials?.map((alluvial, index) => {
          return (
            <button
              key={`${alluvial.title}-${index}`}
              onClick={() => selectPractice(alluvial.key)}
              className={`${
                activeAlluvialId === alluvial.key ? 'bg-white text-black' : 'bg-transparent text-white'
              } cursor-pointer rounded border-1 border-white px-12 py-8 mr-8`}
            >
              {alluvial.content.title}
            </button>
          );
        })}
      </div>
      <div className={`${revealVideo ? 'absolute overflow-hidden' : 'relative'} top-0 left-0 w-full h-full`}>
        {practices &&
          practices.map((practiceType, index) => {
            return (
              <CSSTransition
                key={`carousel-${index}`}
                classNames="fade"
                timeout={600}
                in={activeAlluvialId === practiceType.alluvial_key}
                mountOnEnter
                unmountOnExit
              >
                <div className="absolute top-0 w-full h-full">
                  <Carousel
                    practices={practiceType.practices}
                    revealVideoCb={setRevealVideo}
                    insights={insights}
                    showHints={false}
                  />
                </div>
              </CSSTransition>
            );
          })}
      </div>
    </>
  );
};
