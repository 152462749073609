import { InterstitialCard } from 'components/assessment/cards/interstitial';
import { ScaleCard } from 'components/assessment/cards/scale';
import { ScaleOption } from 'components/assessment/question-types/input-scale/group';
import { useContext, useEffect, useRef } from 'react';
import { trackEvent } from 'services/analytics-service';
import { ThemeContext } from 'contexts/theme';

interface QuestionProps {
  /**
   * Inverted colors
   */
  inverted?: boolean;
  /**
   * Question heading
   */
  heading: string;
  /**
   * Question identifier string
   */
  questionKey: string;
  /**
   * Can the question be skipped
   */
  is_skippable: boolean;
  /**
   * Options list for scale group
   */
  options: ScaleOption[];
  /**
   * Current step number
   */
  currentStep: number;
  /**
   * Total steps number
   */
  totalSteps: number;
  /**
   * user's selected index
   */
  response: number | string;
  /**
   * Callback when value changes within group
   */
  onChange: (questionKey: string, value: string) => void;
  /**
   * Classes list
   */
  className?: string;
  /**
   * Callback for on click handler on back arrow
   */
  onBackButtonClick: () => void;
  /**
   * Callback for on click handler on forward arrow
   */
  onForwardButtonClick: () => void;

  assessmentId: number;
}

function Question(props: QuestionProps): JSX.Element | null {
  const { isVideoPlaying } = useContext(ThemeContext);
  const prevQuestionKey = useRef<string>();

  useEffect(() => {
    const optionsLength = props.options?.length;
    if (optionsLength && prevQuestionKey.current !== props.questionKey) {
      trackEvent({
        eventName: 'Assessment item started',
        eventProperties: {
          questionKey: props.questionKey,
          assessmentId: props.assessmentId,
          isVideoPlaying: isVideoPlaying,
        },
      });
    }
    prevQuestionKey.current = props.questionKey;
  }, [props, isVideoPlaying]);

  if (props.options?.length) {
    return (
      <ScaleCard
        inverted={props.inverted}
        heading={props.heading}
        currentStep={props.currentStep}
        totalSteps={props.totalSteps}
        response={props.response}
        className={props.className}
        questionKey={props.questionKey}
        onChange={props.onChange}
        options={props.options}
        onBackButtonClick={props.onBackButtonClick}
        onForwardButtonClick={props.onForwardButtonClick}
        is_skippable={props.is_skippable}
      />
    );
  }

  return (
    <InterstitialCard
      inverted={props.inverted}
      heading={props.heading}
      currentStep={props.currentStep}
      totalSteps={props.totalSteps}
      className={props.className}
      questionKey={props.questionKey}
      onChange={props.onChange}
      onBackButtonClick={props.onBackButtonClick}
      onForwardButtonClick={props.onForwardButtonClick}
      is_skippable={props.is_skippable}
    />
  );
}

export default Question;
