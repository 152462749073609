import axios from 'axios';
import { getDefaultAxiosConfig } from 'services/service-defaults';
import { ScaleOption } from 'components/assessment/question-types/input-scale/group/index';
import {
  baseUrlConfig,
  getCurrentAssessmentUrl,
  getUpdateAssessmentUrl,
  getAssessmentInsightsUrl,
  getAssessmentConfigUrl,
} from 'services/service-urls';
import { AssessmentInsightsProps } from 'utils/assessment';

export interface AssessmentProps {
  id: number;
  user_id: number;
  submitted_at: string | null;
  responses: AssessmentResponses;
}
interface Questions {
  id: number;
  assessment_configuration_id: number;
  created_at: string;
  updated_at: string;
  question_key: string;
  prompt: string;
  is_skippable: boolean;
  options: ScaleOption[];
}

export interface AssessmentConfiguration {
  id: number;
  theme_key: string;
  title: string;
  subtitle: string;
  description: string;
  questions: Questions[];
}

export interface AssessmentResponses {
  [key: string]: number | string;
}

export const getCurrentAssessment = async (
  themeKey: string,
  baseUrl: string = baseUrlConfig
): Promise<AssessmentProps> => {
  const res = await axios.get(getCurrentAssessmentUrl(themeKey, baseUrl), getDefaultAxiosConfig());
  return res.data.assessment;
};

export const updateAssessmentResponse = async (
  assessmentId: number,
  question_key: string,
  question_response: string,
  submitted?: boolean,
  baseUrl: string = baseUrlConfig
): Promise<AssessmentResponses> => {
  const res = await axios.patch(
    getUpdateAssessmentUrl(baseUrl, assessmentId),
    { question_key, question_response, submitted },
    getDefaultAxiosConfig()
  );
  return res.data.responses;
};

export const getAssessmentInsights = async (
  themeKey: string,
  baseUrl: string = baseUrlConfig
): Promise<AssessmentInsightsProps> => {
  const res = await axios.get(getAssessmentInsightsUrl(themeKey, baseUrl), getDefaultAxiosConfig());
  return res.data.assessment;
};

export const getAssessmentConfig = async (baseUrl: string = baseUrlConfig): Promise<AssessmentConfiguration> => {
  const res = await axios.get(getAssessmentConfigUrl(baseUrl), getDefaultAxiosConfig());
  return res.data.assessment_configuration;
};
