import { useTranslation } from 'react-i18next';

interface ErrorProps {
  /**
   * Heading text (should be the translation string)
   */
  heading: string;
  /**
   * Body text (should be the translation string)
   */
  body: string;
}

/**
 * Component displayed when app encounters an error (not including onClick handlers)
 */
export const ErrorFallback = ({ heading, body }: ErrorProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-screen bg-midnight flex justify-center items-center">
      <div className="bg-white py-16 px-24">
        <h1 className="heading-l mb-16">{t(heading)}</h1>
        <p className="body-m mb-16">{t(body)}</p>
        <p className="body-m">
          <a href="/">{t('errorPage.homeLink')}</a>
        </p>
      </div>
    </div>
  );
};
