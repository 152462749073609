import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { UserContext } from 'contexts/user';
import BackgroundGradientBluePurple from 'assets/images/backgrounds/gradient-blue-purple.png';
import BreakdownElementRings from 'components/sections/post-assessment/breakdown/BreakdownRings';

interface AssessmentInfoProps {
  title: string | undefined;
  description: string | undefined;
  assessmentStarted: boolean | undefined;
  onClick: () => void;
}

export const AssessmentInfo = ({ title, description, assessmentStarted, onClick }: AssessmentInfoProps) => {
  const { t } = useTranslation();
  const { inTeams } = useContext(UserContext);

  return (
    <div
      className={`flex justify-center items-center w-full h-full ${inTeams ? '' : 'min-h-[inherit]'}`}
      data-testid={'assessment-info'}
    >
      <div className="flex flex-col desktop:flex-row desktop:justify-between justify-center desktop:w-3/4 desktop:mx-auto desktop:my-auto">
        <div className="flex flex-col z-100 text-center mb-200 mobile:mb-80 desktop:mb-0 w-3/4 mx-auto justify-center mt-36 tablet-and-up:mt-0 desktop:mr-80 desktop:justify-start desktop:z-1 desktop:text-left desktop:w-3/4 desktop:max-w-640">
          <h1 className="text-white heading-xxl mb-16">{title}</h1>
          <p className="text-white body-l mb-16">{description}</p>
          <div className="flex justify-center desktop:justify-start">
            <Button
              testId="callToActionBtn"
              label={assessmentStarted ? t('buttons.continue') : t('assessment.callToAction.button')}
              text={assessmentStarted ? t('buttons.continue') : t('assessment.callToAction.button')}
              onClick={onClick}
              className="flex mt-24 hover:cursor-pointer"
            />
          </div>
        </div>
        <div className="relative z-50 flex flex-col mt-24 desktop:mt-96 items-center w-full h-1/2 ">
          <BreakdownElementRings
            colorsLight={['#D270D4', '#B28DEA', '#39D0DA', '#77D5FD']}
            colorsDark={['#255F93', '#772B83', '#095099', '#7800BE']}
            currentIndex={0}
          />
        </div>
      </div>
      <div
        className="absolute top-0 left-0 z-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${BackgroundGradientBluePurple})`,
        }}
      ></div>
    </div>
  );
};
