import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as PlayPink } from './play-pink.svg';
import { ReactComponent as PlayOutline } from './play-outline.svg';
import { ReactComponent as Brief } from './res-brief.svg';
import { ReactComponent as Read } from './res-read.svg';
import { ReactComponent as Listen } from './res-listen.svg';
import { ReactComponent as TextLinkArrow } from './text-link-arrow.svg';
import { ReactComponent as TextLinkExternal } from './text-link-external.svg';
import { ReactComponent as CaretDown } from './caret-down.svg';
import { ReactComponent as CaretUp } from './caret-up.svg';
import { ReactComponent as CaretRight } from './caret-right.svg';
import { ReactComponent as CaretLeft } from './caret-left.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as BookOpen } from './book-open.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as VideoCamera } from './video-camera.svg';
import { ReactComponent as Headphones } from './headphones.svg';
import { ReactComponent as PlayAudio } from './play-icon.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as Electric } from './electric.svg';
import { ReactComponent as ElectricWhite } from './electric-white.svg';
import { ReactComponent as X } from './x.svg';
import { ReactComponent as Hamburger } from './hamburger.svg';
import { ReactComponent as PlayFullIcon } from './play-full-icon.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';

// note to future self, when adding new SVGs, confirm that the pattern ids and fill urls are unique and represent the new SVG.
export enum Icons {
  ClockIcon = 'Clock',
  PlayIcon = 'Play',
  PlayPinkIcon = 'PlayPink',
  PlayOutlineIcon = 'PlayOutline',
  BriefIcon = 'Brief',
  ReadIcon = 'Read',
  ListenIcon = 'Listen',
  TextLinkArrowIcon = 'TextLinkArrow',
  TextLinkExternalIcon = 'TextLinkExternal',
  CaretDown = 'CaretDown',
  CaretUp = 'CaretUp',
  CaretRight = 'CaretRight',
  CaretLeft = 'CaretLeft',
  CheckCircle = 'CheckCircle',
  BookOpen = 'BookOpen',
  Edit = 'Edit',
  Calendar = 'Calendar',
  VideoCamera = 'VideoCamera',
  Headphones = 'Headphones',
  PlayAudio = 'PlayAudio',
  Pause = 'Pause',
  Electric = 'Electric',
  ElectricWhite = 'ElectricWhite',
  X = 'X',
  Hamburger = 'Hamburger',
  PlayFullIcon = 'PlayFullIcon',
  ArrowDown = 'ArrowDown',
}

export const IconComponents = {
  Clock: Clock,
  Play: Play,
  PlayPink: PlayPink,
  PlayOutline: PlayOutline,
  Brief: Brief,
  Read: Read,
  Listen: Listen,
  TextLinkArrow: TextLinkArrow,
  TextLinkExternal: TextLinkExternal,
  CaretDown: CaretDown,
  CaretUp: CaretUp,
  CaretRight: CaretRight,
  CaretLeft: CaretLeft,
  CheckCircle: CheckCircle,
  BookOpen: BookOpen,
  Edit: Edit,
  Calendar: Calendar,
  VideoCamera: VideoCamera,
  Headphones: Headphones,
  PlayAudio: PlayAudio,
  Pause: Pause,
  Electric: Electric,
  ElectricWhite: ElectricWhite,
  X: X,
  Hamburger: Hamburger,
  PlayFullIcon: PlayFullIcon,
  ArrowDown: ArrowDown,
};
