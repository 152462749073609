import { Button } from 'components/button';
import { BreakdownIntroductionContent } from 'services/content-service';
import { useTranslation } from 'react-i18next';

interface BreakdownElementIntroProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  breakdownIntroductionContent: BreakdownIntroductionContent;
}

export default function BreakdownElementIntro({ onClick, breakdownIntroductionContent }: BreakdownElementIntroProps) {
  const { t } = useTranslation();

  return (
    <div className={`relative top-0 w-full h-full`}>
      <div className="text-center w-full desktop-large:w-1/2 max-w-736 m-auto mt-384 desktop-large:mt-464">
        <div className="px-24 desktop:px-0">
          <h2 className="pa-heading-xl text-white mb-36">{breakdownIntroductionContent.title}</h2>
          <p className="pa-body text-white mb-80">{breakdownIntroductionContent.description}</p>
        </div>
        <Button onClick={onClick} label={`${t('buttons.continue')}`} text={`${t('buttons.continue')}`} />
      </div>
    </div>
  );
}
