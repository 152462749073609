import * as Sentry from '@sentry/react';
import { ThemeContext } from 'contexts/theme';
import { UserContext } from 'contexts/user';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'services/analytics-service';
import { getAssessmentInsights } from 'services/assessment-service';
import { createFocusArea } from 'services/focus-areas-service';
import { getPractices } from 'services/practice-service';
import { AssessmentInsightsProps, AssessmentMetrics } from 'utils/assessment';
import { PracticeExerciseType, PracticeType, RecommendedType } from 'utils/post-assessment-types';

interface NavigationItem {
  name: string;
  url: string;
}

interface NavState {
  isOpen: boolean; // if the nav overlay should be visible
  isBtnVisible: boolean; // if the button should be visible
}

export interface JourneyContextProps {
  navigationItems: NavigationItem[];
  loading: boolean;
  navState: NavState;
  focusArea: PracticeType | undefined;
  insights: AssessmentInsightsProps | undefined;
  allPractices: RecommendedType | undefined;
  practiceExercise: PracticeExerciseType | undefined;
  alluvialLoaderMessages: Map<string, string>;
  loadInsights: () => void;
  loadPractices: () => void;
  saveFocusArea: (key: string) => void;
  setPracticeExercise: React.Dispatch<React.SetStateAction<PracticeExerciseType | undefined>>;
  setNavState: React.Dispatch<React.SetStateAction<NavState>>;
}

export const JourneyContext = React.createContext<JourneyContextProps>({} as JourneyContextProps);

export function JourneyProvider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const { currentTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { user, reloadUser } = useContext(UserContext);
  const [navState, setNavState] = useState<NavState>({
    isOpen: false,
    isBtnVisible: false,
  });
  const loadedTheme = useRef(currentTheme);
  const [insights, setInsights] = useState<AssessmentInsightsProps | undefined>();
  const [focusArea, setFocusArea] = useState<PracticeType>();
  const [allPractices, setAllPractices] = useState<RecommendedType | undefined>();
  const [practiceExercise, setPracticeExercise] = useState<PracticeExerciseType>();
  const [alluvialLoaderMessages] = useState<Map<string, string>>(new Map<string, string>());
  const [navigationItems] = useState([
    {
      name: t('assessment.postAssessment.navigation.ExploreYourScores'),
      url: '/assessments/overview',
    },
    {
      name: t('assessment.postAssessment.navigation.ChooseAFocusArea'),
      url: '/assessments/focus-area-selection',
    },
    {
      name: t('assessment.postAssessment.navigation.SelectAPractice'),
      url: '/practices/recommend',
    },
    {
      name: t('assessment.postAssessment.navigation.YourPractices'),
      url: '/practices/complete',
    },
  ]);

  const saveFocusArea = useCallback(
    async (key: string) => {
      const selectedPractice = allPractices?.practices.filter((practice) => practice.alluvial_key === key)[0];
      setFocusArea(selectedPractice);

      trackEvent({
        eventName: 'Focus Category Selected',
        eventProperties: {
          alluvialKey: key,
        },
      });

      await createFocusArea({ assessment_id: insights?.id || -1, construct_key: key });
      await reloadUser();
    },
    [allPractices?.practices, insights?.id, reloadUser]
  );

  const loadPractices = useCallback(async () => {
    try {
      if (currentTheme) {
        const practices = await getPractices(currentTheme.key);
        setAllPractices(practices);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [currentTheme]);

  useEffect(() => {
    try {
      if (currentTheme) {
        setFocusArea(undefined);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [currentTheme]);

  const loadInsights = useCallback(async () => {
    try {
      if (currentTheme) {
        const res = await getAssessmentInsights(currentTheme.key);
        res.dimensions = res.dimensions
          .sort((a: AssessmentMetrics, b: AssessmentMetrics) => a.score - b.score)
          .map((dimension: AssessmentMetrics): AssessmentMetrics => {
            alluvialLoaderMessages.set(dimension.content.key, dimension.content.loader_message);
            return dimension;
          });
        setInsights(res);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [currentTheme, alluvialLoaderMessages]);

  useEffect(() => {
    const load = async () => {
      try {
        if (
          currentTheme &&
          user?.assessment_statuses[currentTheme?.key] !== 'unstarted' &&
          user?.assessment_statuses[currentTheme?.key] !== 'started'
        ) {
          setLoading(true);
          await Promise.all([loadInsights(), loadPractices()]);
          setLoading(false);
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    if (user && loadedTheme.current !== currentTheme) {
      loadedTheme.current = currentTheme;
      load();
    }
  }, [currentTheme, loadInsights, loadPractices, user]);

  const contextValue = useMemo(
    () => ({
      navigationItems,
      loading,
      navState,
      insights,
      focusArea,
      allPractices,
      practiceExercise,
      alluvialLoaderMessages,
      loadInsights,
      loadPractices,
      saveFocusArea,
      setPracticeExercise,
      setNavState,
    }),
    [
      navigationItems,
      loading,
      navState,
      insights,
      focusArea,
      allPractices,
      practiceExercise,
      alluvialLoaderMessages,
      loadInsights,
      loadPractices,
      saveFocusArea,
      setPracticeExercise,
      setNavState,
    ]
  );

  return <JourneyContext.Provider value={contextValue}>{children}</JourneyContext.Provider>;
}
