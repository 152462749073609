export const baseUrlConfig = process.env.REACT_APP_API_URL || '';

export const getAnalyticsTrackUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/analytics/track`;
export const getAssessmentConfigUrl = (baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/current/assessment_configuration`;
export const getAssessmentInsightsUrl = (themeKey: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/${themeKey}/assessments/insights`;
export const getAuthenticityTokenUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/frontend/bootstrap`;
export const getCreateFocusAreaUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/focus_areas`;
export const getCreateUserPracticeUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/user_practices`;
export const getUserPracticesUrl = (themeKey: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/${themeKey}/user_practices`;
export const getAddToTeamsCalendarUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/calendar_events`;
export const getPostAssessmentOverviewUrl = (themeKey: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/${themeKey}/post_assessment_overview`;
export const getCurrentAssessmentUrl = (themeKey: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/${themeKey}/assessment`;
export const getDeleteCurrentUserUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/users/sign_out`;
export const getCurrentUserUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/users/me`;
export const getUpdateCurrentUserFlagsUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/users/update_flags`;
export const getUserByTokenUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/users/by_token`;
export const getExposureEventTrackUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/analytics/exposure`;
export const getRoutinesUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/routines`;
export const getUpdateAssessmentUrl = (baseUrl: string = baseUrlConfig, assessmentId: number) =>
  `${baseUrl}/assessments/${assessmentId}/responses`;
export const getVideoUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/video`;
export const getPracticesByAlluvialKeyUrl = (key: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/practices/${key}`;
export const getPracticesUrl = (themeKey: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/${themeKey}/practices`;
export const getPracticesRecommendedUrl = (themeKey: string, baseUrl: string = baseUrlConfig) =>
  `${baseUrl}/themes/${themeKey}/practices/recommended`;
export const getThemesUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/themes`;
export const getPlaybookUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/themes/current/playbook`;
export const getWelcomeContentUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/welcome`;
export const getLoginContentUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/contents/login`;
export const getMagicLinkUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/users/magic`;
export const getNewUserUrl = (baseUrl: string = baseUrlConfig) => `${baseUrl}/users`;
